import React, { useState } from "react";
import CustomTable from "../common/customTable/CustomTable";
import { candidates_invited_table_rows } from "../../utils/data";
import {
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import SortingIcon from "../icons/SortingIcon";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import CommonModal from "../modal/CommonModal";
import DropdownField from "../common/DropdownField";
import { useMessage } from "../snackbar/snackbar";
import { getColorByStatus } from "../../utils";

const columnsWidth = [
  "60px",
  "230px",
  "230px",
  "120px",
  "120px",
  "230px",
  "120px",
];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};
const tableBodyRowStyleProps = {
  height: "48px",
};

const tableHeaderTitles = [
  {
    title: "Sr. no",
    cellStyleProps: {
      width: columnsWidth[0],
    },
  },
  {
    isSortCol: true,
    colId: "candidate",
    title: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Typography
          sx={{ fontSize: "12px", fontWeight: "500", fontFamily: "Roboto" }}
        >
          Candidate
        </Typography>
        <SortingIcon />
      </Box>
    ),
    cellStyleProps: {
      width: columnsWidth[1],
    },
  },
  {
    title: "Email ID",
    cellStyleProps: {
      width: columnsWidth[2],
    },
  },
  {
    title: "Invited On",
    cellStyleProps: {
      width: columnsWidth[3],
    },
  },
  {
    title: "Expiry",
    cellStyleProps: {
      width: columnsWidth[4],
    },
  },
  {
    title: "Invited By",
    cellStyleProps: {
      width: columnsWidth[5],
      textAlign: "center",
    },
  },
  {
    title: "Status",
    cellStyleProps: {
      width: columnsWidth[6],
    },
  },
  {
    title: "",
    cellStyleProps: {},
  },
];

const tableHeaderCells = () => {
  return tableHeaderTitles.map((item) => {
    return {
      isSortCol: item?.isSortCol ? item.isSortCol : false,
      colId: item?.colId ? item.colId : "",
      element: item.title,
      cellStyleProps: item.cellStyleProps,
      isSortable: item?.isSortable ? item.isSortable : false,
      cellId: item?.cellId ? item.cellId : "",
    };
  });
};

const Invited = ({
  loading,
  setLoading,
  setTableParams,
  data,
  totalDocuments,
  totalPages,
  tableParams,
  getPaginationOptions,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const { authUser } = React.useContext(AuthContext);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const message = useMessage();
  const [invitesData, setInvitesData] = useState({
    name: "John Doe",
    email: "john@gmail.com",
  });

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleInvites = (e) => {
    setInvitesData({ ...invitesData, [e.target.name]: e.target.value });
    setOpenUserModal(false);
  };

  const handleInviteSent = () => {
    console.log("invitesData", invitesData);
    setOpenUserModal(false);
    message("Invite sent successfully", "success");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const tableBodyRowCell = (row, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.srNo}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img
              src={
                row?.candidateProfilePicture
                  ? row?.candidateProfilePicture
                  : "/assets/user.png"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/user.png";
              }}
              alt="user"
              style={{ height: "30px", width: "30px", borderRadius: "50%" }}
            />
            <Typography
              sx={{
                ...tableContentCellTextStyleProps,
                cursor:
                  row?.currentStatus !== "Invited" &&
                  row?.currentStatus !== "Invite Expired"
                    ? "pointer"
                    : "default",
                pointerEvents:
                  row?.currentStatus !== "Invited" &&
                  row?.currentStatus !== "Invite Expired"
                    ? "auto"
                    : "none",
              }}
              onClick={() =>
                navigate(`/assessments/candidateDetails`, {
                  state: {
                    candidateAssessmentId: row?.candidateAssessmentId,
                    assessmentId: row?.assessmentId?._id,
                    assessmentDetails: row?.assessmentId,
                  },
                })
              }
            >
              {row.candidateId?.name}
              {row?.assessmentResult?.totalObtainedScore <
                row?.assessmentId?.cutOff &&
                row?.currentStatus !== "invited" &&
                row?.currentStatus !== "invite expired" && (
                  <Tooltip title="Failed" arrow>
                    <img
                      src="/assets/exclamation-circle.svg"
                      alt="Alert Icon"
                      style={{
                        marginRight: "4px",
                        marginLeft: "4px",
                        height: "18px",
                      }}
                    />
                  </Tooltip>
                )}
            </Typography>
          </Box>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.candidateId?.email}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.invitationDetails?.sentAt
              ? formatDate(row?.invitationDetails?.sentAt)
              : "N/A"}
          </Typography>
        ),
      },

      {
        cellStyleProps,
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.invitationDetails?.validUpto
              ? formatDate(row?.invitationDetails?.validUpto)
              : "N/A"}
          </Typography>
        ),
      },
      {
        cellStyleProps,
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
              textAlign: "center",
            }}
          >
            {row?.invitationDetails?.InvitedByDetails?.name || "-"}
          </Typography>
        ),
      },
      {
        cellStyleProps,
        element: getStatus({
          id:
            row?.currentStatus === "Invited" ||
            row?.currentStatus === "Invite Expired"
              ? row?.currentStatus
              : "Appeared",

          tag:
            row?.currentStatus === "Invited" ||
            row?.currentStatus === "Invite Expired"
              ? row?.currentStatus
              : "Appeared",
        }),
      },
      {
        cellStyleProps,
        element: (
          <IconButton onClick={(event) => handleMenuClick(event, row)}>
            <img src="/assets/three_dots_icon.svg" alt="three dots" />
          </IconButton>
        ),
      },
    ];
  };

  const tableBodyContent = () => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };

    return data?.map((row, idx) => {
      return {
        sortable: { score: parseInt(row.score), candidate: row.name },
        rowElement: tableBodyRowCell({ ...row, srNo: idx + 1 }, cellStyleProps),
      };
    });
  };

  const getStatus = (status) => {
    return (
      <Box
        sx={{
          display: "inline-block",
          background: getColorByStatus(status.id).bg,
          padding: "2px 8px",
          color: getColorByStatus(status.id).color,
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "16px",
          fontFamily: "Roboto",
          borderRadius: "0.25rem",
          whiteSpace: "nowrap",
        }}
      >
        {status.tag}
      </Box>
    );
  };

  let timeoutId = null;

  const handleChangePage = (newPage) => {
    setLoading(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          page: newPage,
        },
      });
    }, 500);
  };

  const handleRowsChange = (event) => {
    setLoading(true);
    const value = event?.target?.value;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          limit: value,
        },
      });
    }, 500);
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      <CustomTable
        loading={loading}
        tableHeaderCells={tableHeaderCells()}
        tableHeaderCellStyleProps={tableHeaderCellStyleProps}
        tableBodyContent={tableBodyContent()}
        tableData={data}
        tableBodyRowStyleProps={tableBodyRowStyleProps}
        sortRows={() => {}}
        limit={tableParams.pagination.limit}
        page={tableParams.pagination.page}
        totalPages={totalPages}
        totalDocuments={totalDocuments}
        handleChangePage={handleChangePage}
        handleRowsChange={handleRowsChange}
        rowsPerPageOptions={getPaginationOptions(
          totalDocuments,
          tableParams.pagination.page,
          tableParams.pagination.limit
        )}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>
          <img
            style={{ marginRight: "8px" }}
            src="/assets/Vector-8 copy.svg"
            alt="failed"
          />
          View Invites
        </MenuItem>
        {authUser.role === "hr" && selectedRow?.currentStatus === "Expired" && (
          <>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                setOpenUserModal(true);
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/send.svg"
                alt="failed"
              />
              Resent Invite
            </MenuItem>
          </>
        )}
      </Menu>
      <CommonModal
        icon={"/assets/sendgreen.svg"}
        handleClose={() => setOpenUserModal(false)}
        open={openUserModal}
        title={"Invite Candidate"}
        UserProfile
        sendButtonName={"Send"}
        cancelButtonName={"Cancel"}
        onSubmit={handleInviteSent}
      >
        <Stack spacing={3}>
          <Grid>
            <Grid item xs={12}>
              <DropdownField
                id="name"
                name="name"
                label="Name"
                defaultValue={invitesData.name}
                onChange={handleInvites}
                sx={{ height: "55px" }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <DropdownField
                id="email"
                name="email"
                label="Email"
                defaultValue={invitesData.email}
                onChange={handleInvites}
                sx={{ height: "55px" }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <DropdownField
                select
                data={[
                  { value: "active", label: "Active" },
                  { value: "inactive", label: "Inactive" },
                ]}
                id="validity"
                name="validity"
                label="Validity"
                defaultValue={invitesData.validity}
                onChange={handleInvites}
              />
            </Grid>
          </Grid>
        </Stack>
      </CommonModal>
    </Box>
  );
};

export default Invited;
