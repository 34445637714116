import { Backdrop, CircularProgress, Typography } from "@mui/material";

const LoadingScreen = () => {
  return (
    <Backdrop
      open={true}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color="inherit" />
      <Typography
        sx={{ marginTop: "16px", fontSize: "16px", textAlign: "center" }}
      >
        Please wait...
        <br />
        Loading, this may take a few moments.
      </Typography>
    </Backdrop>
  );
};

export default LoadingScreen;
