import React from "react";
import { Box, Typography, Stack } from "@mui/material";

const Plagiarism = () => {
  return (
    <Stack
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ height: "100vh", textAlign: "center" }}
    >
      <Typography
        variant="h6"
        sx={{ fontFamily: "Roboto", fontWeight: 400, fontSize: "16px" }}
      >
        UI Not Available
      </Typography>
    </Stack>
  );
};

export default Plagiarism;
