import { Grid, Skeleton, TablePagination } from "@mui/material";
import React from "react";
import TestTakenCandidateCard from "../common/TestTakenCandidateCard";
import { TestTakenData } from "../../utils/data";

const TestTakenGrid = ({
  data,
  tableParams,
  loading,
  currentTab,
  totalDocuments,
  handleChangePage,
  handleRowsChange,
  handleGetLink,
  getPaginationOptions,
}) => {
  const handleCheckboxChange = (event) => {
    console.log(event.target.checked);
  };

  return (
    <>
      <Grid>
        {loading
          ? [...Array(tableParams.pagination.limit)].map((_, i) => (
              <Grid item xs={12} key={i} style={{ marginBottom: "-40px" }}>
                <Skeleton animation="wave" variant="text" height={130} />
              </Grid>
            ))
          : data.map((elem, index) => (
              <Grid
                container
                key={index}
                alignItems="center"
                marginBottom={"10px"}
                marginTop={"10px"}
              >
                <Grid item xs>
                  <TestTakenCandidateCard data={elem} currentTab={currentTab} />
                </Grid>
              </Grid>
            ))}
      </Grid>
      <TablePagination
        component="div"
        count={totalDocuments}
        rowsPerPage={tableParams.pagination.limit}
        page={tableParams.pagination.page - 1}
        onPageChange={(e, newPage) => {
          handleChangePage(newPage + 1);
        }}
        onRowsPerPageChange={handleRowsChange}
        className="custom-table-pagination"
        rowsPerPageOptions={getPaginationOptions(
          totalDocuments,
          tableParams.pagination.page,
          tableParams.pagination.limit
        )}
      />
    </>
  );
};

export default TestTakenGrid;
