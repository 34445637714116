import React, { useState, useEffect, useRef } from "react";
import FunnelGraph from "funnel-graph-js"; // Import the funnel graph library

const FunnelChart = React.memo(({ hiringData }) => {
  const [chartRendered, setChartRendered] = useState(false);
  const graphRef = useRef(null);

  useEffect(() => {
    if (!chartRendered) {
      const graph = new FunnelGraph({
        container: ".funnel-chart",
        gradientDirection: "horizontal",
        data: {
          labels: ["Invited", "Test attempted", "Shortlisted"],
          subLabels: ["0% drop w.r.t invited", "100% drop w.r.t attempted"],
          colors: [["#D5F9F1"], ["#D5F9F1"]],
          values: [[0], [0], [0]],
        },
        direction: "Horizontal",
        width: 1100, // Adjust width based on screen size
        height: 126,
        subLabelValue: "values",
      });

      graph.draw();
      graphRef.current = graph;
      setChartRendered(true);
    }
  }, [chartRendered]);

  useEffect(() => {
    const data = {
      labels: ["Invited", "Test attempted", "Shortlisted"],
      subLabels: ["0% drop w.r.t invited", "100% drop w.r.t attempted"],
      colors: [["#D5F9F1"], ["#D5F9F1"]],
      values: [
        [hiringData?.Invited || 0], // Fallback to 0 if undefined
        [hiringData?.Appeared || 0], // Fallback to 0 if undefined
        [hiringData?.Shortlisted || 0], // Fallback to 0 if undefined
      ],
    };
    graphRef.current.updateData(data);
  }, [hiringData]); // Depend on hiringData to trigger updates

  return <div className="funnel-chart"></div>;
});

export default FunnelChart;
