import React, { useEffect, useState } from "react";
import { Box, Divider, Skeleton, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import useResponsive from "../../hooks/useResponsive";
import ChartCard from "../dashboardPage/ChartCard";
import theme from "../../theme/theme";
import { TbTableExport } from "react-icons/tb";
import FunnelChart from "./FunnelChart";
import { useLocation } from "react-router-dom";
import APICall from "../../utils/api";
import ExcelJS from "exceljs";
import { useMessage } from "../snackbar/snackbar";

function TestAnalytics({ assessmentId }) {
  const message = useMessage();
  const isLargeScreen = useResponsive("up", "lg");
  const location = useLocation();
  const [scoreGraphData, setScoreGraphData] = useState();
  const [assessmentData, setAssessmentData] = useState();
  const [questionAnalyticsData, setQuestionAnalyticsData] = useState();
  const [questionBreakdown, setQuestionBreakdown] = useState([]);
  const [hiringFunnelData, setHiringFunnelData] = useState({
    Invited: 0,
    Appeared: 0,
    Shortlisted: 0,
    Hired: 0,
    Total: 0,
  });
  const [funnelDuration, setFunnelDuration] = useState("thisYear");

  const fetchCandidateScoreGraph = async (duration) => {
    try {
      let url = `/stats/assessments/${assessmentId}/score-graph`;
      if (duration) {
        url += `?duration=${duration}`;
      }
      const res = await APICall(url);
      setScoreGraphData(res.data);
    } catch (error) {
      console.error("Failed to fetch assessment details:", error);
    }
  };

  const fetchQuestionAnalytics = async () => {
    try {
      let url = `/stats/assessments/${assessmentId}/question-difficulty-analysis`;
      const res = await APICall(url);
      setAssessmentData(res.data);
      setQuestionAnalyticsData(res.data.difficultyAnalysis);
      setQuestionBreakdown(res.data.typeBreakdown);
    } catch (error) {
      console.error("Failed to fetch question analytics:", error);
    }
  };

  const fetchCandidateHiringFunnel = async (duration) => {
    try {
      let url = `/stats/assessments/${assessmentId}/candidate-hiring-funnel`;
      if (duration) {
        url += `?duration=${duration}`;
      }
      const res = await APICall(url);
      setHiringFunnelData(
        res.data || {
          Invited: 0,
          Appeared: 0,
          Shortlisted: 0,
          Hired: 0,
          Total: 0,
        }
      );
    } catch (error) {
      console.error("Failed to fetch candidate hiring funnel:", error);
      setHiringFunnelData({
        Invited: 0,
        Appeared: 0,
        Shortlisted: 0,
        Hired: 0,
        Total: 0,
      });
    }
  };

  const handleScoreGraphExportData = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Score Graph");
      worksheet.columns = [
        { header: "Duration", key: "duration", width: 20 },
        ...options.xaxis.categories.map((category) => ({
          header: category,
          key: category,
          width: 20,
        })),
      ];

      const res = await APICall(
        "/stats/assessments/667a96d39aa487116d2bbac5/score-graph/export"
      );

      res.data.forEach((item) => {
        const duration = Object.keys(item)[0];
        const values = item[duration];
        let row = { duration: duration };

        values.forEach((value, index) => {
          const category = options.xaxis.categories[index];
          row[category] = value;
        });

        worksheet.addRow(row);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Score_Graph.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    } catch (error) {
      console.error("Failed to export data:", error);
    }
  };

  const handleDifficultyAnalysisExportData = async () => {
    console.log("questionAnalyticsData", questionAnalyticsData);
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Difficulty Analysis");
      worksheet.columns = [
        { header: "Easy", key: "easy", width: 20 },
        { header: "Medium", key: "medium", width: 20 },
        { header: "Hard", key: "hard", width: 20 },
      ];

      worksheet.addRow({
        easy: questionAnalyticsData.Easy,
        medium: questionAnalyticsData.Medium,
        hard: questionAnalyticsData.Hard,
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${assessmentData.assessmentName}_Difficulty_Analysis.xlsx`;
        document.body.appendChild(a);
        message("Export successful!", "success");
        a.click();
        a.remove();
      });
    } catch (error) {
      console.error("Failed to export data:", error);
    }
  };

  const handleQuestionBreakdownExportData = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Question Breakdown");
      worksheet.columns = [
        { header: "MCQ", key: "MCQ", width: 20 },
        { header: "Programming", key: "Programming", width: 20 },
        { header: "SQL", key: "SQL", width: 20 },
      ];

      worksheet.addRow({
        MCQ: questionBreakdown.MCQ,
        Programming: questionBreakdown.Programming,
        SQL: questionBreakdown.SQL,
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${assessmentData.assessmentName}_Question_Breakdown.xlsx`;
        document.body.appendChild(a);
        message("Export successful!", "success");
        a.click();
        a.remove();
      });
    } catch (error) {
      console.error("Failed to export data:", error);
    }
  };

  const handleFunnelGraphExportData = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Funnel Graph");
      worksheet.columns = [
        { header: "Category", key: "category", width: 20 },
        { header: "Invited", key: "Invited", width: 15 },
        { header: "Appeared", key: "Appeared", width: 15 },
        { header: "Shortlisted", key: "Shortlisted", width: 15 },
        { header: "Hired", key: "Hired", width: 15 },
        { header: "Total", key: "Total", width: 15 },
      ];

      const res = await APICall(
        "/stats/assessments/667a96d39aa487116d2bbac5/candidate-hiring-funnel/export"
      );

      res?.data?.forEach((item) => {
        const category = Object.keys(item)[0];
        const values = item[category];
        const row = {
          category: category,
          Invited: values.Invited,
          Appeared: values.Appeared,
          Shortlisted: values.Shortlisted,
          Hired: values.Hired,
          Total: values.Total,
        };
        worksheet.addRow(row);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${assessmentData.assessmentName}_Funnel_Graph.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    } catch (error) {
      console.error("Failed to export data:", error);
    }
  };

  const handleGraphDurationChange = (value) => {
    console.log("value", value);
    fetchCandidateScoreGraph(value);
  };

  const handleFunnelDurationChange = (value) => {
    console.log("value", value);
    setFunnelDuration(value);
  };

  useEffect(() => {
    fetchCandidateHiringFunnel();
  }, [funnelDuration]);

  useEffect(() => {
    fetchCandidateScoreGraph();
    fetchQuestionAnalytics();
    fetchCandidateHiringFunnel();
  }, []);

  const options = {
    chart: {
      height: 330,
      type: "bar",
      stacked: !0,
      toolbar: {
        show: !1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        horizontal: !1,
        columnWidth: "25%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          "<span>" +
          w.globals.labels[dataPointIndex] +
          ": " +
          series[seriesIndex][dataPointIndex] +
          "</span>" +
          "</div>"
        );
      },
    },
    series: [
      {
        name: "Departments",
        data: scoreGraphData,
      },
    ],
    xaxis: {
      categories: [
        "0",
        "1-9",
        "10-19",
        "20-29",
        "30-39",
        "40-49",
        "50-59",
        "60-69",
        "70-79",
        "80-89",
        "90-99",
        "100",
      ],
      labels: {
        formatter: function (value) {
          if (value.length > 5) {
            return value.slice(0, 5) + "..."; // Display first five characters followed by ellipsis for long names
          }
          return value;
        },
      },
    },
    colors: ["#00C49A"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };

  const menuItemsCandidate = [
    {
      value: "thisYear",
      label: "This Year",
    },
    {
      value: "thisMonth",
      label: "This Month",
    },
    {
      value: "thisWeek",
      label: "This Week",
    },
  ];

  var questionBreakdownOptions = {
    series: [
      questionBreakdown?.MCQ || 0,
      questionBreakdown?.Programming || 0,
      questionBreakdown?.SQL || 0,
    ],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 320,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: ["#DA38FA", "#328DF6", "#8962F3"],
      dataLabels: {
        enabled: false,
      },
      labels: ["MCQ", "Programming", "SQL"],
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
                label: "Questions",
                color: "#535A5F",
                fontSize: "12px",
                formatter: function (val) {
                  const total = val.config.series.reduce((a, b) => a + b, 0);
                  return total;
                },
              },
            },
          },
        },
        tooltip: {
          fillSeriesColor: false,
        },
        legend: {
          width: isLargeScreen ? 200 : 150,
          show: true,
          labels: {
            colors: "#363E45",
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            radius: 2,
          },
          itemMargin: {
            vertical: 6,
          },
        },
      },
      legend: {
        width: isLargeScreen ? 150 : 120,
        show: true,
        labels: {
          colors: "#363E45",
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          radius: 2,
        },
        itemMargin: {
          vertical: 6,
        },
      },
    },
  };

  var questionsAnalysisOptions = {
    series: [
      questionAnalyticsData?.Easy || 0,
      questionAnalyticsData?.Medium || 0,
      questionAnalyticsData?.Hard || 0,
    ],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 320,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: ["#439D62", "#FF9736", "#F94948"],
      dataLabels: {
        enabled: false,
      },
      labels: ["Easy", "Medium", "Hard"],
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
                label: "Questions",
                color: "#535A5F",
                fontSize: "12px",
                formatter: function (val) {
                  const total = val.config.series.reduce((a, b) => a + b, 0);
                  return total;
                },
              },
            },
          },
        },
      },
      tooltip: {
        fillSeriesColor: false,
      },
      legend: {
        width: isLargeScreen ? 150 : 120,
        show: true,
        labels: {
          colors: "#363E45",
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          radius: 2,
          horizontal: 12,
        },
        itemMargin: {
          vertical: 6,
        },
      },
    },
  };

  return (
    <Box>
      <Box
        sx={{
          display: { base: "flex" },
          flexDirection: "column",
          border: "1px solid",
          borderColor: theme.palette.primary.gray,
          marginTop: "16px",
        }}
      >
        <ChartCard
          title="Candidate Score Graph"
          subTitle="No. of Candidates who score greater than or equal to median score (55%)"
          menuItems={menuItemsCandidate}
          handleOnClickExport={handleScoreGraphExportData}
          handleChange={handleGraphDurationChange}
        >
          <Box
            sx={{
              width: { base: "100%", sm: "50%" },
              gap: "12px",
              display: "flex",
              flexDirection: "row",
            }}
          ></Box>
        </ChartCard>
        <Box
          sx={{
            backgroundColor: "#fff",
            overflowY: "hidden",
            minHeight: "300px",
          }}
          className="bar-chart"
        >
          <Chart
            options={options}
            type="bar"
            series={options.series}
            width={isLargeScreen ? "100%" : "200%"}
            height="345px"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { base: "repeat(1,1fr)", sm: "repeat(2,1fr)" },
          gap: "16px",
          marginTop: "16px",
        }}
      >
        <Box
          sx={{
            display: { base: "flex" },
            flexDirection: "column",
            marginTop: "16px",
            border: "1px solid",
            borderColor: theme.palette.primary.gray,
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "4px",
              borderBottom: "1px solid",
              borderColor: theme.palette.primary.gray,
              width: "100%",
              padding: "16px",
              display: "flex",
              flexDirection: { base: "column", lg: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: {
                  base: "row",
                  sm: "row",
                },
                alignItems: "center",
                justifyContent: "space-between",
                gap: "12px",
              }}
            >
              <Box
                sx={{
                  width: { base: "100%", md: "100%" },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: theme.palette.primary.black,
                  }}
                >
                  Question Difficulty Analysis
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: theme.palette.text.gray300,
                  }}
                >
                  Difficulty wise distribution
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "24px",
                  width: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <TbTableExport
                  size={22}
                  color={theme.palette.secondary.main}
                  fontWeight={400}
                  onClick={handleDifficultyAnalysisExportData}
                />
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ backgroundColor: "#fff" }}>
            <Chart
              options={questionsAnalysisOptions.options}
              series={questionsAnalysisOptions.series}
              type="donut"
              width={isLargeScreen ? "440px" : "320px"}
              height="300px"
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "16px 0 16px 0",
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: { base: "flex" },
            flexDirection: "column",
            marginTop: "16px",
            border: "1px solid",
            borderColor: theme.palette.primary.gray,
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "4px",
              borderBottom: "1px solid",
              borderColor: theme.palette.primary.gray,
              width: "100%",
              padding: "16px",
              display: "flex",
              flexDirection: { base: "column", lg: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: {
                  base: "row",
                  sm: "row",
                },
                alignItems: "center",
                justifyContent: "space-between",
                gap: "12px",
              }}
            >
              <Box
                sx={{
                  width: { base: "100%", md: "100%" },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: theme.palette.primary.black,
                  }}
                >
                  Question Type Breakdown
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: theme.palette.text.gray300,
                  }}
                >
                  Question Type distribution
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "24px",
                  width: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <TbTableExport
                  size={22}
                  color={theme.palette.secondary.main}
                  fontWeight={400}
                  onClick={handleQuestionBreakdownExportData}
                />
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ backgroundColor: "#fff" }}>
            <Chart
              options={questionBreakdownOptions.options}
              series={questionBreakdownOptions.series}
              type="donut"
              width={isLargeScreen ? "440px" : "320px"}
              height="300px"
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "16px 0 16px 0",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { base: "flex" },
          flexDirection: "column",
          border: "1px solid",
          borderColor: theme.palette.primary.gray,
          marginTop: "16px",
        }}
      >
        <ChartCard
          title="Candidate Hiring Funnel"
          subTitle="50% Attempted"
          menuItems={menuItemsCandidate}
          isDefaultColor={false}
          handleOnClickExport={handleFunnelGraphExportData}
          handleChange={handleFunnelDurationChange}
        >
          <Box
            sx={{
              width: { base: "100%", sm: "50%" },
              gap: "12px",
              display: "flex",
              flexDirection: "row",
            }}
          ></Box>
        </ChartCard>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            padding: "16px 16px 40px 16px",
            overflowY: "hidden",
          }}
          id="test-analytics-overview-root"
        >
          <FunnelChart hiringData={hiringFunnelData} />
        </Box>
      </Box>
    </Box>
  );
}

export default TestAnalytics;
