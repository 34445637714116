export const questionsData = {
    "total": 112,
    "page": 1,
    "limit": 10,
    "documents": [
        {
            "_id": "66b0d6e281d59e527c4b9853",
            "multipleAnswers": false,
            "answers": [
                "Each node has exactly zero or two children",
                "A binary tree, which is completely filled, with the possible exception of the bottom level, which is filled from right to left",
                "A binary tree, which is completely filled, with the possible exception of the bottom level, which is filled from left to right",
                "A tree In which all nodes have degree 2"
            ],
            "correctAnswers": [
                "A binary tree, which is completely filled, with the possible exception of the bottom level, which is filled from left to right"
            ],
            "topics": [
                {
                    "_id": "66791a8dd8b486d0b84c245e",
                    "name": "heap",
                    "createdAt": "2024-06-24T07:04:45.506Z",
                    "updatedAt": "2024-06-24T07:04:45.506Z",
                    "__v": 0
                },
                {
                    "_id": "66791a7ed8b486d0b84c2459",
                    "name": "trees",
                    "createdAt": "2024-06-24T07:04:30.421Z",
                    "updatedAt": "2024-06-24T07:04:30.421Z",
                    "__v": 0
                },
                {
                    "_id": "667914c8d8b486d0b84c1fc8",
                    "name": "stack",
                    "createdAt": "2024-06-24T06:40:08.957Z",
                    "updatedAt": "2024-06-24T06:40:08.957Z",
                    "__v": 0
                },
                {
                    "_id": "66791a68d8b486d0b84c244f",
                    "name": "linked lists",
                    "createdAt": "2024-06-24T07:04:08.737Z",
                    "updatedAt": "2024-06-24T07:04:08.737Z",
                    "__v": 0
                },
                {
                    "_id": "66791a77d8b486d0b84c2454",
                    "name": "hash tables",
                    "createdAt": "2024-06-24T07:04:24.000Z",
                    "updatedAt": "2024-06-24T07:04:24.000Z",
                    "__v": 0
                },
                {
                    "_id": "66791a5ed8b486d0b84c244a",
                    "name": "queues",
                    "createdAt": "2024-06-24T07:03:58.090Z",
                    "updatedAt": "2024-06-24T07:03:58.090Z",
                    "__v": 0
                },
                {
                    "_id": "66791a92d8b486d0b84c2463",
                    "name": "graph",
                    "createdAt": "2024-06-24T07:04:50.829Z",
                    "updatedAt": "2024-06-24T07:04:50.829Z",
                    "__v": 0
                }
            ],
            "skills": [
                {
                    "_id": "66791abbd8b486d0b84c2495",
                    "topics": [
                        "667914c8d8b486d0b84c1fc8",
                        "66791a92d8b486d0b84c2463",
                        "66791a8dd8b486d0b84c245e",
                        "66791a7ed8b486d0b84c2459",
                        "66791a77d8b486d0b84c2454",
                        "66791a68d8b486d0b84c244f",
                        "66791a5ed8b486d0b84c244a"
                    ],
                    "name": "data structure",
                    "createdBy": "662a4366018b2027bc26a34c",
                    "createdAt": "2024-06-24T07:05:31.485Z",
                    "updatedAt": "2024-06-24T11:46:40.919Z",
                    "__v": 0
                }
            ],
            "images": [],
            "isEditorEnable": false,
            "createdBy": {
                "_id": "667908ccd8b486d0b84c14fd",
                "status": "active",
                "channelId": "665f0c43160dd56ee0d98884",
                "role": "recruiter",
                "email": "recruiter@gmail.com",
                "name": "Danny Vighne",
                "userType": "client",
                "clientId": "665f0b99160dd56ee0d9878d",
                "createdAt": "2024-06-24T05:49:00.285Z",
                "updatedAt": "2024-08-02T07:36:06.447Z",
                "__v": 0,
                "password": "$2a$10$pc9EprFXeg6bnbz8dq9INOslmXEJYqMXbAj18VReeGsQR1XQ/8uS2"
            },
            "type": "MCQ",
            "health": 10,
            "question": "What is a complete binary tree?",
            "score": 3,
            "difficultyLevelId": "664f2c36dc36f7d80e2a4651",
            "questionTitle": "Datastructure Questions (Copy)",
            "status": "completed",
            "html": "www.example.com",
            "__v": 0,
            "createdAt": "2024-08-05T13:42:58.188Z",
            "updatedAt": "2024-08-05T13:42:58.188Z",
            "difficultyLevel": {
                "_id": "664f2c36dc36f7d80e2a4651",
                "level": "hard",
                "experience": ">6 Years",
                "programmingTime": 50,
                "mcqTime": 20,
                "createdAt": "2024-05-23T11:44:54.815Z",
                "updatedAt": "2024-06-24T09:53:12.339Z",
                "__v": 0
            },
            "isHire360Question": false
        },
        {
            "_id": "66a0a5d5efdc880bcc7a6597",
            "multipleAnswers": false,
            "answers": [
                "One",
                "Two",
                "Three",
                "Four"
            ],
            "correctAnswers": [
                "Four"
            ],
            "topics": [
                {
                    "_id": "66791a2cd8b486d0b84c2417",
                    "name": "sequences",
                    "createdAt": "2024-06-24T07:03:08.209Z",
                    "updatedAt": "2024-06-24T07:03:08.209Z",
                    "__v": 0
                },
                {
                    "_id": "66791a13d8b486d0b84c240d",
                    "name": "contraints",
                    "createdAt": "2024-06-24T07:02:43.145Z",
                    "updatedAt": "2024-06-24T07:02:43.145Z",
                    "__v": 0
                },
                {
                    "_id": "667919f8d8b486d0b84c2403",
                    "name": "subqueries",
                    "createdAt": "2024-06-24T07:02:16.285Z",
                    "updatedAt": "2024-06-24T07:02:16.285Z",
                    "__v": 0
                },
                {
                    "_id": "66791a05d8b486d0b84c2408",
                    "name": "injections",
                    "createdAt": "2024-06-24T07:02:29.325Z",
                    "updatedAt": "2024-06-24T07:02:29.325Z",
                    "__v": 0
                },
                {
                    "_id": "66791a25d8b486d0b84c2412",
                    "name": "indexes",
                    "createdAt": "2024-06-24T07:03:01.048Z",
                    "updatedAt": "2024-06-24T07:03:01.048Z",
                    "__v": 0
                }
            ],
            "skills": [
                {
                    "_id": "66791a3cd8b486d0b84c243b",
                    "topics": [
                        "66791a2cd8b486d0b84c2417",
                        "66791a25d8b486d0b84c2412",
                        "66791a13d8b486d0b84c240d",
                        "66791a05d8b486d0b84c2408",
                        "667919f8d8b486d0b84c2403"
                    ],
                    "name": "sql",
                    "createdBy": "662a4366018b2027bc26a34c",
                    "createdAt": "2024-06-24T07:03:24.848Z",
                    "updatedAt": "2024-06-24T07:03:24.848Z",
                    "__v": 0
                }
            ],
            "images": [],
            "isEditorEnable": false,
            "createdBy": {
                "_id": "66850e33494ec3744c37b528",
                "status": "active",
                "email": "recruiter@hire360.com",
                "name": "Hire360 Recruiter",
                "role": "recruiter",
                "userType": "client",
                "clientId": "66850b1f494ec3744c37af40",
                "channelId": "66850d83494ec3744c37b460",
                "password": "$2a$12$vgEJ/vOCSQ6f5nPfN1.nR.SD9RR7zYocRrbhs.T6FlQaQPVRirMp2",
                "createdAt": "2024-07-03T08:39:15.867Z",
                "updatedAt": "2024-07-03T08:39:15.867Z",
                "__v": 0
            },
            "questionTitle": "SQL Questions",
            "type": "MCQ",
            "health": 10,
            "question": "How many JDBC driver types does Sun define?",
            "score": 3,
            "difficultyLevelId": "664f2c36dc36f7d80e2a4651",
            "status": "completed",
            "html": "www.example.com",
            "createdAt": "2024-07-04T10:17:56.437Z",
            "updatedAt": "2024-07-04T10:17:56.437Z",
            "__v": 0,
            "difficultyLevel": {
                "_id": "664f2c36dc36f7d80e2a4651",
                "level": "hard",
                "experience": ">6 Years",
                "programmingTime": 50,
                "mcqTime": 20,
                "createdAt": "2024-05-23T11:44:54.815Z",
                "updatedAt": "2024-06-24T09:53:12.339Z",
                "__v": 0
            },
            "isHire360Question": true
        },
        {
            "_id": "668676d4494ec3744c37f21b",
            "multipleAnswers": false,
            "answers": [
                "One",
                "Two",
                "Three",
                "Four"
            ],
            "correctAnswers": [
                "Four"
            ],
            "topics": [
                {
                    "_id": "66791a2cd8b486d0b84c2417",
                    "name": "sequences",
                    "createdAt": "2024-06-24T07:03:08.209Z",
                    "updatedAt": "2024-06-24T07:03:08.209Z",
                    "__v": 0
                },
                {
                    "_id": "66791a13d8b486d0b84c240d",
                    "name": "contraints",
                    "createdAt": "2024-06-24T07:02:43.145Z",
                    "updatedAt": "2024-06-24T07:02:43.145Z",
                    "__v": 0
                },
                {
                    "_id": "667919f8d8b486d0b84c2403",
                    "name": "subqueries",
                    "createdAt": "2024-06-24T07:02:16.285Z",
                    "updatedAt": "2024-06-24T07:02:16.285Z",
                    "__v": 0
                },
                {
                    "_id": "66791a05d8b486d0b84c2408",
                    "name": "injections",
                    "createdAt": "2024-06-24T07:02:29.325Z",
                    "updatedAt": "2024-06-24T07:02:29.325Z",
                    "__v": 0
                },
                {
                    "_id": "66791a25d8b486d0b84c2412",
                    "name": "indexes",
                    "createdAt": "2024-06-24T07:03:01.048Z",
                    "updatedAt": "2024-06-24T07:03:01.048Z",
                    "__v": 0
                }
            ],
            "skills": [
                {
                    "_id": "66791a3cd8b486d0b84c243b",
                    "topics": [
                        "66791a2cd8b486d0b84c2417",
                        "66791a25d8b486d0b84c2412",
                        "66791a13d8b486d0b84c240d",
                        "66791a05d8b486d0b84c2408",
                        "667919f8d8b486d0b84c2403"
                    ],
                    "name": "sql",
                    "createdBy": "662a4366018b2027bc26a34c",
                    "createdAt": "2024-06-24T07:03:24.848Z",
                    "updatedAt": "2024-06-24T07:03:24.848Z",
                    "__v": 0
                }
            ],
            "images": [],
            "isEditorEnable": false,
            "createdBy": {
                "_id": "66850e33494ec3744c37b528",
                "status": "active",
                "email": "recruiter@hire360.com",
                "name": "Hire360 Recruiter",
                "role": "recruiter",
                "userType": "client",
                "clientId": "66850b1f494ec3744c37af40",
                "channelId": "66850d83494ec3744c37b460",
                "password": "$2a$12$vgEJ/vOCSQ6f5nPfN1.nR.SD9RR7zYocRrbhs.T6FlQaQPVRirMp2",
                "createdAt": "2024-07-03T08:39:15.867Z",
                "updatedAt": "2024-07-03T08:39:15.867Z",
                "__v": 0
            },
            "type": "MCQ",
            "health": 10,
            "question": "How many JDBC driver types does Sun define?",
            "score": 3,
            "difficultyLevelId": "664f2c36dc36f7d80e2a4651",
            "questionTitle": "SQL Questions",
            "status": "completed",
            "html": "www.example.com",
            "createdAt": "2024-07-04T10:17:56.437Z",
            "updatedAt": "2024-07-04T10:17:56.437Z",
            "__v": 0,
            "difficultyLevel": {
                "_id": "664f2c36dc36f7d80e2a4651",
                "level": "hard",
                "experience": ">6 Years",
                "programmingTime": 50,
                "mcqTime": 20,
                "createdAt": "2024-05-23T11:44:54.815Z",
                "updatedAt": "2024-06-24T09:53:12.339Z",
                "__v": 0
            },
            "isHire360Question": true
        },
        {
            "_id": "66b0d49f698e653210a66d17",
            "multipleAnswers": false,
            "answers": [
                "One",
                "Two",
                "Three",
                "Four"
            ],
            "correctAnswers": [
                "Four"
            ],
            "topics": [
                {
                    "_id": "66791a2cd8b486d0b84c2417",
                    "name": "sequences",
                    "createdAt": "2024-06-24T07:03:08.209Z",
                    "updatedAt": "2024-06-24T07:03:08.209Z",
                    "__v": 0
                },
                {
                    "_id": "66791a13d8b486d0b84c240d",
                    "name": "contraints",
                    "createdAt": "2024-06-24T07:02:43.145Z",
                    "updatedAt": "2024-06-24T07:02:43.145Z",
                    "__v": 0
                },
                {
                    "_id": "667919f8d8b486d0b84c2403",
                    "name": "subqueries",
                    "createdAt": "2024-06-24T07:02:16.285Z",
                    "updatedAt": "2024-06-24T07:02:16.285Z",
                    "__v": 0
                },
                {
                    "_id": "66791a05d8b486d0b84c2408",
                    "name": "injections",
                    "createdAt": "2024-06-24T07:02:29.325Z",
                    "updatedAt": "2024-06-24T07:02:29.325Z",
                    "__v": 0
                },
                {
                    "_id": "66791a25d8b486d0b84c2412",
                    "name": "indexes",
                    "createdAt": "2024-06-24T07:03:01.048Z",
                    "updatedAt": "2024-06-24T07:03:01.048Z",
                    "__v": 0
                }
            ],
            "skills": [
                {
                    "_id": "66791a3cd8b486d0b84c243b",
                    "topics": [
                        "66791a2cd8b486d0b84c2417",
                        "66791a25d8b486d0b84c2412",
                        "66791a13d8b486d0b84c240d",
                        "66791a05d8b486d0b84c2408",
                        "667919f8d8b486d0b84c2403"
                    ],
                    "name": "sql",
                    "createdBy": "662a4366018b2027bc26a34c",
                    "createdAt": "2024-06-24T07:03:24.848Z",
                    "updatedAt": "2024-06-24T07:03:24.848Z",
                    "__v": 0
                }
            ],
            "images": [],
            "isEditorEnable": false,
            "createdBy": {
                "_id": "66850e33494ec3744c37b528",
                "status": "active",
                "email": "recruiter@hire360.com",
                "name": "Hire360 Recruiter",
                "role": "recruiter",
                "userType": "client",
                "clientId": "66850b1f494ec3744c37af40",
                "channelId": "66850d83494ec3744c37b460",
                "password": "$2a$12$vgEJ/vOCSQ6f5nPfN1.nR.SD9RR7zYocRrbhs.T6FlQaQPVRirMp2",
                "createdAt": "2024-07-03T08:39:15.867Z",
                "updatedAt": "2024-07-03T08:39:15.867Z",
                "__v": 0
            },
            "questionTitle": "SQL Questions (Copy) 789",
            "type": "MCQ",
            "health": 10,
            "question": "How many JDBC driver types does Sun define?",
            "score": 3,
            "difficultyLevelId": "664f2c36dc36f7d80e2a4651",
            "status": "completed",
            "html": "www.example.com",
            "createdAt": "2024-07-04T10:17:56.437Z",
            "updatedAt": "2024-07-04T10:17:56.437Z",
            "__v": 0,
            "difficultyLevel": {
                "_id": "664f2c36dc36f7d80e2a4651",
                "level": "hard",
                "experience": ">6 Years",
                "programmingTime": 50,
                "mcqTime": 20,
                "createdAt": "2024-05-23T11:44:54.815Z",
                "updatedAt": "2024-06-24T09:53:12.339Z",
                "__v": 0
            },
            "isHire360Question": true
        },
        {
            "_id": "6686767e494ec3744c37f216",
            "multipleAnswers": false,
            "answers": [
                "Э t ε r (Q(t))",
                "∀ t ε r (Q(t))",
                "¬ t ε r (Q(t))",
                "~ t ε r (Q(t))"
            ],
            "correctAnswers": [
                "∀ t ε r (Q(t))"
            ],
            "topics": [
                {
                    "_id": "66791a2cd8b486d0b84c2417",
                    "name": "sequences",
                    "createdAt": "2024-06-24T07:03:08.209Z",
                    "updatedAt": "2024-06-24T07:03:08.209Z",
                    "__v": 0
                },
                {
                    "_id": "66791a13d8b486d0b84c240d",
                    "name": "contraints",
                    "createdAt": "2024-06-24T07:02:43.145Z",
                    "updatedAt": "2024-06-24T07:02:43.145Z",
                    "__v": 0
                },
                {
                    "_id": "667919f8d8b486d0b84c2403",
                    "name": "subqueries",
                    "createdAt": "2024-06-24T07:02:16.285Z",
                    "updatedAt": "2024-06-24T07:02:16.285Z",
                    "__v": 0
                },
                {
                    "_id": "66791a05d8b486d0b84c2408",
                    "name": "injections",
                    "createdAt": "2024-06-24T07:02:29.325Z",
                    "updatedAt": "2024-06-24T07:02:29.325Z",
                    "__v": 0
                },
                {
                    "_id": "66791a25d8b486d0b84c2412",
                    "name": "indexes",
                    "createdAt": "2024-06-24T07:03:01.048Z",
                    "updatedAt": "2024-06-24T07:03:01.048Z",
                    "__v": 0
                }
            ],
            "skills": [
                {
                    "_id": "66791a3cd8b486d0b84c243b",
                    "topics": [
                        "66791a2cd8b486d0b84c2417",
                        "66791a25d8b486d0b84c2412",
                        "66791a13d8b486d0b84c240d",
                        "66791a05d8b486d0b84c2408",
                        "667919f8d8b486d0b84c2403"
                    ],
                    "name": "sql",
                    "createdBy": "662a4366018b2027bc26a34c",
                    "createdAt": "2024-06-24T07:03:24.848Z",
                    "updatedAt": "2024-06-24T07:03:24.848Z",
                    "__v": 0
                }
            ],
            "images": [],
            "isEditorEnable": false,
            "createdBy": {
                "_id": "66850e33494ec3744c37b528",
                "status": "active",
                "email": "recruiter@hire360.com",
                "name": "Hire360 Recruiter",
                "role": "recruiter",
                "userType": "client",
                "clientId": "66850b1f494ec3744c37af40",
                "channelId": "66850d83494ec3744c37b460",
                "password": "$2a$12$vgEJ/vOCSQ6f5nPfN1.nR.SD9RR7zYocRrbhs.T6FlQaQPVRirMp2",
                "createdAt": "2024-07-03T08:39:15.867Z",
                "updatedAt": "2024-07-03T08:39:15.867Z",
                "__v": 0
            },
            "type": "MCQ",
            "health": 10,
            "question": "Find all students who have taken all courses offered in the Biology department.” The expressions that matches this sentence is :",
            "score": 3,
            "difficultyLevelId": "664f2c36dc36f7d80e2a4651",
            "questionTitle": "SQL Questions",
            "status": "completed",
            "html": "www.example.com",
            "createdAt": "2024-07-04T10:16:30.170Z",
            "updatedAt": "2024-07-04T10:16:30.170Z",
            "__v": 0,
            "difficultyLevel": {
                "_id": "664f2c36dc36f7d80e2a4651",
                "level": "hard",
                "experience": ">6 Years",
                "programmingTime": 50,
                "mcqTime": 20,
                "createdAt": "2024-05-23T11:44:54.815Z",
                "updatedAt": "2024-06-24T09:53:12.339Z",
                "__v": 0
            },
            "isHire360Question": true
        },
        {
            "_id": "66867628494ec3744c37f211",
            "multipleAnswers": false,
            "answers": [
                "EXEC SQL update c;",
                "EXEC SQL update c into :si, :sn;",
                "EXEC SQL UPDATE instructor SET salary = salary + 100 WHERE CURRENT OF c;",
                "EXEC SQL update END-SQL"
            ],
            "correctAnswers": [
                "EXEC SQL UPDATE instructor SET salary = salary + 100 WHERE CURRENT OF c;"
            ],
            "topics": [
                {
                    "_id": "66791a2cd8b486d0b84c2417",
                    "name": "sequences",
                    "createdAt": "2024-06-24T07:03:08.209Z",
                    "updatedAt": "2024-06-24T07:03:08.209Z",
                    "__v": 0
                },
                {
                    "_id": "66791a13d8b486d0b84c240d",
                    "name": "contraints",
                    "createdAt": "2024-06-24T07:02:43.145Z",
                    "updatedAt": "2024-06-24T07:02:43.145Z",
                    "__v": 0
                },
                {
                    "_id": "667919f8d8b486d0b84c2403",
                    "name": "subqueries",
                    "createdAt": "2024-06-24T07:02:16.285Z",
                    "updatedAt": "2024-06-24T07:02:16.285Z",
                    "__v": 0
                },
                {
                    "_id": "66791a05d8b486d0b84c2408",
                    "name": "injections",
                    "createdAt": "2024-06-24T07:02:29.325Z",
                    "updatedAt": "2024-06-24T07:02:29.325Z",
                    "__v": 0
                },
                {
                    "_id": "66791a25d8b486d0b84c2412",
                    "name": "indexes",
                    "createdAt": "2024-06-24T07:03:01.048Z",
                    "updatedAt": "2024-06-24T07:03:01.048Z",
                    "__v": 0
                }
            ],
            "skills": [
                {
                    "_id": "66791a3cd8b486d0b84c243b",
                    "topics": [
                        "66791a2cd8b486d0b84c2417",
                        "66791a25d8b486d0b84c2412",
                        "66791a13d8b486d0b84c240d",
                        "66791a05d8b486d0b84c2408",
                        "667919f8d8b486d0b84c2403"
                    ],
                    "name": "sql",
                    "createdBy": "662a4366018b2027bc26a34c",
                    "createdAt": "2024-06-24T07:03:24.848Z",
                    "updatedAt": "2024-06-24T07:03:24.848Z",
                    "__v": 0
                }
            ],
            "images": [],
            "isEditorEnable": false,
            "createdBy": {
                "_id": "66850e33494ec3744c37b528",
                "status": "active",
                "email": "recruiter@hire360.com",
                "name": "Hire360 Recruiter",
                "role": "recruiter",
                "userType": "client",
                "clientId": "66850b1f494ec3744c37af40",
                "channelId": "66850d83494ec3744c37b460",
                "password": "$2a$12$vgEJ/vOCSQ6f5nPfN1.nR.SD9RR7zYocRrbhs.T6FlQaQPVRirMp2",
                "createdAt": "2024-07-03T08:39:15.867Z",
                "updatedAt": "2024-07-03T08:39:15.867Z",
                "__v": 0
            },
            "type": "MCQ",
            "health": 10,
            "question": "The update statement can be executed in host language using",
            "score": 3,
            "difficultyLevelId": "664f2c36dc36f7d80e2a4651",
            "questionTitle": "SQL Questions",
            "status": "completed",
            "html": "www.example.com",
            "createdAt": "2024-07-04T10:15:04.567Z",
            "updatedAt": "2024-07-04T10:15:04.567Z",
            "__v": 0,
            "difficultyLevel": {
                "_id": "664f2c36dc36f7d80e2a4651",
                "level": "hard",
                "experience": ">6 Years",
                "programmingTime": 50,
                "mcqTime": 20,
                "createdAt": "2024-05-23T11:44:54.815Z",
                "updatedAt": "2024-06-24T09:53:12.339Z",
                "__v": 0
            },
            "isHire360Question": true
        },
        {
            "_id": "668675b5494ec3744c37f20c",
            "multipleAnswers": false,
            "answers": [
                "Embedded SQL",
                "Dynamic SQL",
                "SQL declarations",
                " SQL data analysis"
            ],
            "correctAnswers": [
                "Dynamic SQL"
            ],
            "topics": [
                {
                    "_id": "66791a2cd8b486d0b84c2417",
                    "name": "sequences",
                    "createdAt": "2024-06-24T07:03:08.209Z",
                    "updatedAt": "2024-06-24T07:03:08.209Z",
                    "__v": 0
                },
                {
                    "_id": "66791a13d8b486d0b84c240d",
                    "name": "contraints",
                    "createdAt": "2024-06-24T07:02:43.145Z",
                    "updatedAt": "2024-06-24T07:02:43.145Z",
                    "__v": 0
                },
                {
                    "_id": "667919f8d8b486d0b84c2403",
                    "name": "subqueries",
                    "createdAt": "2024-06-24T07:02:16.285Z",
                    "updatedAt": "2024-06-24T07:02:16.285Z",
                    "__v": 0
                },
                {
                    "_id": "66791a05d8b486d0b84c2408",
                    "name": "injections",
                    "createdAt": "2024-06-24T07:02:29.325Z",
                    "updatedAt": "2024-06-24T07:02:29.325Z",
                    "__v": 0
                },
                {
                    "_id": "66791a25d8b486d0b84c2412",
                    "name": "indexes",
                    "createdAt": "2024-06-24T07:03:01.048Z",
                    "updatedAt": "2024-06-24T07:03:01.048Z",
                    "__v": 0
                }
            ],
            "skills": [
                {
                    "_id": "66791a3cd8b486d0b84c243b",
                    "topics": [
                        "66791a2cd8b486d0b84c2417",
                        "66791a25d8b486d0b84c2412",
                        "66791a13d8b486d0b84c240d",
                        "66791a05d8b486d0b84c2408",
                        "667919f8d8b486d0b84c2403"
                    ],
                    "name": "sql",
                    "createdBy": "662a4366018b2027bc26a34c",
                    "createdAt": "2024-06-24T07:03:24.848Z",
                    "updatedAt": "2024-06-24T07:03:24.848Z",
                    "__v": 0
                }
            ],
            "images": [],
            "isEditorEnable": false,
            "createdBy": {
                "_id": "66850e33494ec3744c37b528",
                "status": "active",
                "email": "recruiter@hire360.com",
                "name": "Hire360 Recruiter",
                "role": "recruiter",
                "userType": "client",
                "clientId": "66850b1f494ec3744c37af40",
                "channelId": "66850d83494ec3744c37b460",
                "password": "$2a$12$vgEJ/vOCSQ6f5nPfN1.nR.SD9RR7zYocRrbhs.T6FlQaQPVRirMp2",
                "createdAt": "2024-07-03T08:39:15.867Z",
                "updatedAt": "2024-07-03T08:39:15.867Z",
                "__v": 0
            },
            "type": "MCQ",
            "health": 10,
            "question": "Which of the following is used to access the database server at the time of executing the program and get the data from the server accordingly?",
            "score": 3,
            "difficultyLevelId": "664f2c36dc36f7d80e2a4651",
            "questionTitle": "SQL Questions",
            "status": "completed",
            "html": "www.example.com",
            "createdAt": "2024-07-04T10:13:09.677Z",
            "updatedAt": "2024-07-04T10:13:09.677Z",
            "__v": 0,
            "difficultyLevel": {
                "_id": "664f2c36dc36f7d80e2a4651",
                "level": "hard",
                "experience": ">6 Years",
                "programmingTime": 50,
                "mcqTime": 20,
                "createdAt": "2024-05-23T11:44:54.815Z",
                "updatedAt": "2024-06-24T09:53:12.339Z",
                "__v": 0
            },
            "isHire360Question": true
        },
        {
            "_id": "6686756a494ec3744c37f207",
            "multipleAnswers": false,
            "answers": [
                "Grant select on employee to Amit",
                "Grant update(budget) on department to Raj",
                "Grant update(budget,salary,Rate) on department to Raj",
                "Grant delete to Amit"
            ],
            "correctAnswers": [
                "Grant update(budget) on department to Raj"
            ],
            "topics": [
                {
                    "_id": "66791a2cd8b486d0b84c2417",
                    "name": "sequences",
                    "createdAt": "2024-06-24T07:03:08.209Z",
                    "updatedAt": "2024-06-24T07:03:08.209Z",
                    "__v": 0
                },
                {
                    "_id": "66791a13d8b486d0b84c240d",
                    "name": "contraints",
                    "createdAt": "2024-06-24T07:02:43.145Z",
                    "updatedAt": "2024-06-24T07:02:43.145Z",
                    "__v": 0
                },
                {
                    "_id": "667919f8d8b486d0b84c2403",
                    "name": "subqueries",
                    "createdAt": "2024-06-24T07:02:16.285Z",
                    "updatedAt": "2024-06-24T07:02:16.285Z",
                    "__v": 0
                },
                {
                    "_id": "66791a05d8b486d0b84c2408",
                    "name": "injections",
                    "createdAt": "2024-06-24T07:02:29.325Z",
                    "updatedAt": "2024-06-24T07:02:29.325Z",
                    "__v": 0
                },
                {
                    "_id": "66791a25d8b486d0b84c2412",
                    "name": "indexes",
                    "createdAt": "2024-06-24T07:03:01.048Z",
                    "updatedAt": "2024-06-24T07:03:01.048Z",
                    "__v": 0
                }
            ],
            "skills": [
                {
                    "_id": "66791a3cd8b486d0b84c243b",
                    "topics": [
                        "66791a2cd8b486d0b84c2417",
                        "66791a25d8b486d0b84c2412",
                        "66791a13d8b486d0b84c240d",
                        "66791a05d8b486d0b84c2408",
                        "667919f8d8b486d0b84c2403"
                    ],
                    "name": "sql",
                    "createdBy": "662a4366018b2027bc26a34c",
                    "createdAt": "2024-06-24T07:03:24.848Z",
                    "updatedAt": "2024-06-24T07:03:24.848Z",
                    "__v": 0
                }
            ],
            "images": [],
            "isEditorEnable": false,
            "createdBy": {
                "_id": "66850e33494ec3744c37b528",
                "status": "active",
                "email": "recruiter@hire360.com",
                "name": "Hire360 Recruiter",
                "role": "recruiter",
                "userType": "client",
                "clientId": "66850b1f494ec3744c37af40",
                "channelId": "66850d83494ec3744c37b460",
                "password": "$2a$12$vgEJ/vOCSQ6f5nPfN1.nR.SD9RR7zYocRrbhs.T6FlQaQPVRirMp2",
                "createdAt": "2024-07-03T08:39:15.867Z",
                "updatedAt": "2024-07-03T08:39:15.867Z",
                "__v": 0
            },
            "type": "MCQ",
            "health": 10,
            "question": "Which of the following is used to provide privilege to only a particular attribute?",
            "score": 3,
            "difficultyLevelId": "664f2c36dc36f7d80e2a4651",
            "questionTitle": "SQL Questions",
            "status": "completed",
            "html": "www.example.com",
            "createdAt": "2024-07-04T10:11:54.524Z",
            "updatedAt": "2024-07-04T10:11:54.524Z",
            "__v": 0,
            "difficultyLevel": {
                "_id": "664f2c36dc36f7d80e2a4651",
                "level": "hard",
                "experience": ">6 Years",
                "programmingTime": 50,
                "mcqTime": 20,
                "createdAt": "2024-05-23T11:44:54.815Z",
                "updatedAt": "2024-06-24T09:53:12.339Z",
                "__v": 0
            },
            "isHire360Question": true
        },
        {
            "_id": "66867505494ec3744c37f202",
            "multipleAnswers": false,
            "answers": [
                "Left outer join",
                "Right outer join",
                "Full outer join",
                "Half outer join;"
            ],
            "correctAnswers": [
                "Right outer join"
            ],
            "topics": [
                {
                    "_id": "66791a2cd8b486d0b84c2417",
                    "name": "sequences",
                    "createdAt": "2024-06-24T07:03:08.209Z",
                    "updatedAt": "2024-06-24T07:03:08.209Z",
                    "__v": 0
                },
                {
                    "_id": "66791a13d8b486d0b84c240d",
                    "name": "contraints",
                    "createdAt": "2024-06-24T07:02:43.145Z",
                    "updatedAt": "2024-06-24T07:02:43.145Z",
                    "__v": 0
                },
                {
                    "_id": "667919f8d8b486d0b84c2403",
                    "name": "subqueries",
                    "createdAt": "2024-06-24T07:02:16.285Z",
                    "updatedAt": "2024-06-24T07:02:16.285Z",
                    "__v": 0
                },
                {
                    "_id": "66791a05d8b486d0b84c2408",
                    "name": "injections",
                    "createdAt": "2024-06-24T07:02:29.325Z",
                    "updatedAt": "2024-06-24T07:02:29.325Z",
                    "__v": 0
                },
                {
                    "_id": "66791a25d8b486d0b84c2412",
                    "name": "indexes",
                    "createdAt": "2024-06-24T07:03:01.048Z",
                    "updatedAt": "2024-06-24T07:03:01.048Z",
                    "__v": 0
                }
            ],
            "skills": [
                {
                    "_id": "66791a3cd8b486d0b84c243b",
                    "topics": [
                        "66791a2cd8b486d0b84c2417",
                        "66791a25d8b486d0b84c2412",
                        "66791a13d8b486d0b84c240d",
                        "66791a05d8b486d0b84c2408",
                        "667919f8d8b486d0b84c2403"
                    ],
                    "name": "sql",
                    "createdBy": "662a4366018b2027bc26a34c",
                    "createdAt": "2024-06-24T07:03:24.848Z",
                    "updatedAt": "2024-06-24T07:03:24.848Z",
                    "__v": 0
                }
            ],
            "images": [],
            "isEditorEnable": false,
            "createdBy": {
                "_id": "66850e33494ec3744c37b528",
                "status": "active",
                "email": "recruiter@hire360.com",
                "name": "Hire360 Recruiter",
                "role": "recruiter",
                "userType": "client",
                "clientId": "66850b1f494ec3744c37af40",
                "channelId": "66850d83494ec3744c37b460",
                "password": "$2a$12$vgEJ/vOCSQ6f5nPfN1.nR.SD9RR7zYocRrbhs.T6FlQaQPVRirMp2",
                "createdAt": "2024-07-03T08:39:15.867Z",
                "updatedAt": "2024-07-03T08:39:15.867Z",
                "__v": 0
            },
            "type": "MCQ",
            "health": 10,
            "question": "Which join refers to join records from the right table that have no matching key in the left table are include in the result set:",
            "score": 2,
            "difficultyLevelId": "6674193e2034414ec4383b7f",
            "questionTitle": "SQL Questions",
            "status": "completed",
            "html": "www.example.com",
            "createdAt": "2024-07-04T10:10:13.939Z",
            "updatedAt": "2024-07-04T10:10:13.939Z",
            "__v": 0,
            "difficultyLevel": {
                "_id": "6674193e2034414ec4383b7f",
                "level": "medium",
                "experience": "4 - 6 years",
                "programmingTime": 30,
                "mcqTime": 20,
                "createdAt": "2024-06-20T11:57:50.740Z",
                "updatedAt": "2024-06-20T11:57:50.740Z",
                "__v": 0
            },
            "isHire360Question": true
        },
        {
            "_id": "668674c2494ec3744c37f1fd",
            "multipleAnswers": false,
            "answers": [
                "Multiple queries",
                "Subqueries",
                "Update",
                "Scalar subqueries;"
            ],
            "correctAnswers": [
                "Scalar subqueries;"
            ],
            "topics": [
                {
                    "_id": "66791a2cd8b486d0b84c2417",
                    "name": "sequences",
                    "createdAt": "2024-06-24T07:03:08.209Z",
                    "updatedAt": "2024-06-24T07:03:08.209Z",
                    "__v": 0
                },
                {
                    "_id": "66791a13d8b486d0b84c240d",
                    "name": "contraints",
                    "createdAt": "2024-06-24T07:02:43.145Z",
                    "updatedAt": "2024-06-24T07:02:43.145Z",
                    "__v": 0
                },
                {
                    "_id": "667919f8d8b486d0b84c2403",
                    "name": "subqueries",
                    "createdAt": "2024-06-24T07:02:16.285Z",
                    "updatedAt": "2024-06-24T07:02:16.285Z",
                    "__v": 0
                },
                {
                    "_id": "66791a05d8b486d0b84c2408",
                    "name": "injections",
                    "createdAt": "2024-06-24T07:02:29.325Z",
                    "updatedAt": "2024-06-24T07:02:29.325Z",
                    "__v": 0
                },
                {
                    "_id": "66791a25d8b486d0b84c2412",
                    "name": "indexes",
                    "createdAt": "2024-06-24T07:03:01.048Z",
                    "updatedAt": "2024-06-24T07:03:01.048Z",
                    "__v": 0
                }
            ],
            "skills": [
                {
                    "_id": "66791a3cd8b486d0b84c243b",
                    "topics": [
                        "66791a2cd8b486d0b84c2417",
                        "66791a25d8b486d0b84c2412",
                        "66791a13d8b486d0b84c240d",
                        "66791a05d8b486d0b84c2408",
                        "667919f8d8b486d0b84c2403"
                    ],
                    "name": "sql",
                    "createdBy": "662a4366018b2027bc26a34c",
                    "createdAt": "2024-06-24T07:03:24.848Z",
                    "updatedAt": "2024-06-24T07:03:24.848Z",
                    "__v": 0
                }
            ],
            "images": [],
            "isEditorEnable": false,
            "createdBy": {
                "_id": "66850e33494ec3744c37b528",
                "status": "active",
                "email": "recruiter@hire360.com",
                "name": "Hire360 Recruiter",
                "role": "recruiter",
                "userType": "client",
                "clientId": "66850b1f494ec3744c37af40",
                "channelId": "66850d83494ec3744c37b460",
                "password": "$2a$12$vgEJ/vOCSQ6f5nPfN1.nR.SD9RR7zYocRrbhs.T6FlQaQPVRirMp2",
                "createdAt": "2024-07-03T08:39:15.867Z",
                "updatedAt": "2024-07-03T08:39:15.867Z",
                "__v": 0
            },
            "type": "MCQ",
            "health": 10,
            "question": "_________ are useful in SQL update statements, where they can be used in the set clause.",
            "score": 2,
            "difficultyLevelId": "6674193e2034414ec4383b7f",
            "questionTitle": "SQL Questions",
            "status": "completed",
            "html": "www.example.com",
            "createdAt": "2024-07-04T10:09:06.500Z",
            "updatedAt": "2024-07-04T10:09:06.500Z",
            "__v": 0,
            "difficultyLevel": {
                "_id": "6674193e2034414ec4383b7f",
                "level": "medium",
                "experience": "4 - 6 years",
                "programmingTime": 30,
                "mcqTime": 20,
                "createdAt": "2024-06-20T11:57:50.740Z",
                "updatedAt": "2024-06-20T11:57:50.740Z",
                "__v": 0
            },
            "isHire360Question": true
        }
    ]
}