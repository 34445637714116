import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, IconButton } from "@mui/material";

const useStyles = makeStyles((theme, fileName) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: fileName ? " center" : "flex-start",
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    padding: "20px",
    border: `2px dashed ${theme.palette.secondary.main}`,
    borderImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FF6812' stroke-width='4' stroke-dasharray='6%2c10' stroke-dashoffset='0' rx='8' ry='8'  stroke-linejoin='round' stroke-linecap='square'/%3e%3c/svg%3e") 2`,
  },
  fileInput: {
    display: "none",
  },
  fileName: {
    fontSize: "14px",
    padding: "20px",
  },
  selectedFile: {
    display: "flex",
    flexDirection: "column",
    alignItems: fileName ? "flex-start" : "center",
    padding: "0px",
    gap: "4px",
    flexGrow: "1",
  },
  selectedFileWithAlignStart: {
    alignItems: "flex-start", // Override alignItems if fileName is present
  },
}));
const BorderedFile = ({
  fileName,
  children,
  handleRemoveFile,
  handleReuploadFile,
}) => {
  const classes = useStyles({ fileName });

  return (
    <Box className={classes.root}>
      <Box
        className={`${classes.selectedFile} ${
          fileName && classes.fileName && classes.selectedFileWithAlignStart
        }`}
      >
        {children}
      </Box>
      {fileName && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            justifyContent: "center",
            padding: "0  ",
          }}
        >
          <IconButton
            sx={{
              padding: 0,
            }}
            onClick={handleReuploadFile}
          >
            <Box
              component={"img"}
              src={`/assets/icons/Replace.svg`}
              sx={{ width: "19.4px", height: "19.4px" }}
            />
          </IconButton>
          <IconButton
            sx={{
              padding: 0,
            }}
            onClick={handleRemoveFile}
          >
            <Box
              component={"img"}
              src={`/assets/icons/Trash.svg`}
              sx={{ width: "19.4px", height: "19.4px" }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default BorderedFile;
