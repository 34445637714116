import React, { createContext, useContext, useState } from "react";
import { useMessage } from "../components/snackbar/snackbar";

// Create the PingContext
export const PingContext = createContext();

// Custom hook for consuming the context
export const usePingContext = () => {
  return useContext(PingContext);
};

// Provider Component
export const PingProvider = ({ children }) => {
  const message = useMessage();

  const [openMemberModal, setOpenMemberModal] = useState(false);

  const handlePingModalOpen = () => setOpenMemberModal(true);

  // Close modal function
  const handlePingModalCloseAddOrEdit = () => {
    setOpenMemberModal(false);
  };

  // Handle ping action
  const handlePingClick = () => {
    handlePingModalCloseAddOrEdit();
    message("Message sent successfully !!", "success");
  };

  return (
    <PingContext.Provider
      value={{
        openMemberModal,
        handlePingModalOpen,
        handlePingModalCloseAddOrEdit,
        handlePingClick,
      }}
    >
      {children}
    </PingContext.Provider>
  );
};
