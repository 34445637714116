export function getDifficultSettingsByLevel(level) {
  let difficultySettings;
  switch (level) {
    case "easy":
      difficultySettings = {
        bgcolor: "rgba(67, 157, 98, 0.1)",
        color: "rgba(67, 157, 98, 1)",
        path: "/assets/greenfire.svg",
        difficulty: "Easy",
      };
      break;
    case "medium":
      difficultySettings = {
        bgcolor: "rgba(255, 151, 54, 0.1)",
        color: "rgba(255, 151, 54, 1)",
        path: "/assets/orangefire.svg",
        difficulty: "Medium",
      };
      break;
    case "hard":
      difficultySettings = {
        bgcolor: "rgba(249, 73, 72, 0.1)",
        color: "rgba(249, 73, 72, 1)",
        path: "/assets/libraryFire.svg",
        difficulty: "Hard",
      };
      break;
    default:
      difficultySettings = {
        bgcolor: "rgba(0, 0, 0, 0.1)",
        color: "rgba(0, 0, 0, 1)",
        path: "/assets/default.svg",
        difficulty: "Unknown",
      };
  }
  return difficultySettings;
}

function capitalizeStatus(status) {
  return status
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function getColorByStatus(status) {
  if (status === "Invited") {
    return { color: "#522258", bg: "#E9CDF7" };
  } else if (status === "Appeared") {
    return { color: "#0D7C66", bg: "#CCE0AC" };
  } else if (
    status === "Passed" ||
    status === "R1 Passed" ||
    status === "R2 Passed" ||
    status === "HR Passed" ||
    status === "Completed"
  ) {
    return { color: "#285837", bg: "#e2f4c5" };
  } else if (status === "Reviewed") {
    return { color: "#FABC3F", bg: "#f3feb8" };
  } else if (status === "Shortlisted") {
    return { color: "#003285", bg: "#eef7ff" };
  } else if (
    status === "Scheduled" ||
    status === "R1 Scheduled" ||
    status === "R2 Scheduled" ||
    status === "HR Scheduled"
  ) {
    return { color: "#ff6812", bg: "#fff0e7" };
  } else if (
    status === "Failed" ||
    status === "Cancelled Hire" ||
    status === "R1 Failed" ||
    status === "R2 Failed" ||
    status === "HR Failed" ||
    status === "Cancelled"
  ) {
    return { color: "#733430", bg: "#fadcda" };
  } else if (status === "Hired") {
    return { color: "#004e3e", bg: "#d5f9f1" };
  } else if (
    status === "Pending" ||
    status === "R1 Pending" ||
    status === "R2 Pending" ||
    status === "HR Pending"
  ) {
    return { color: "#914F1E", bg: "#ECCEAE" };
  } else if (
    status === "Rescheduled" ||
    status === "rescheduled" ||
    status === "Request Reschedule"
  ) {
    return { color: "#382f15", bg: "#ffe88a" };
  } else if (status === "In Progress") {
    return { color: "#004e3e", bg: "#d5f9f1" };
  }

  return { color: "#363E45", bg: "#E9EAEB" };
}

export function capitalizeWords(str) {
  if (!str) return "";
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
