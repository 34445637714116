import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAssessment } from "./assessmentSlice";

/**
 * Initial state for the ATFMetaData slice
 * @type {Object}
 */
const initialState = {
  totalMcqQuestions: 0,
  totalEasyMCQQuestions: 0,
  totalMediumMCQQuestions: 0,
  totalHardMCQQuestions: 0,

  totalProgrammingQuestions: 0,
  totalEasyProgrammingQuestions: 0,
  totalMediumProgrammingQuestions: 0,
  totalHardProgrammingQuestions: 0,

  totalNoOfQuestions: 0,
  totalEasyQuestions: 0,
  totalMediumQuestions: 0,
  totalHardQuestions: 0,

  totalSkills: 0,
  totalMcqScore: 0,
  totalProgrammingScore: 0,
  skillWiseMetaData: [],
  questionIds: [],
};

/**
 * Thunk to generate and set metadata
 * @function
 * @param {Object} payload - The payload containing skillWiseQuestions and difficultyLevels
 * @param {Array} payload.skillWiseQuestions - Array of skill-wise questions
 * @param {Array} payload.difficultyLevels - Array of difficulty levels with timings
 * @returns {Object} - The generated metadata
 */
export const generateAndSetMetaData = createAsyncThunk(
  "ATFMetaData/generateAndSetMetaData",
  async (payload, { dispatch }) => {
    try {
      const { skillWiseQuestions, difficultyLevels } = payload;

      // Extract timings for each difficulty level and question type
      const timings = {
        easyMCQ: difficultyLevels.find((item) => item.level === "easy").mcqTime,
        mediumMCQ: difficultyLevels.find((item) => item.level === "medium")
          .mcqTime,
        hardMCQ: difficultyLevels.find((item) => item.level === "hard").mcqTime,
        easyProgramming: difficultyLevels.find((item) => item.level === "easy")
          .programmingTime,
        mediumProgramming: difficultyLevels.find(
          (item) => item.level === "medium"
        ).programmingTime,
        hardProgramming: difficultyLevels.find((item) => item.level === "hard")
          .programmingTime,
      };

      let testDuration = 0;
      let testScore = 0;

      // Calculate skill-wise metadata
      const skillWiseMetaData = skillWiseQuestions.map((item) => {
        const mcqQuestions = item.mcqQuestions;
        const programmingQuestions = item.programmingQuestions;

        // Calculate durations for each type of question
        const easyMCQDuration = calculateTiming(
          mcqQuestions.easyQuestions,
          timings.easyMCQ
        );
        const mediumMCQDuration = calculateTiming(
          mcqQuestions.mediumQuestions,
          timings.mediumMCQ
        );
        const hardMCQDuration = calculateTiming(
          mcqQuestions.hardQuestions,
          timings.hardMCQ
        );

        const easyProgrammingDuration = calculateTiming(
          programmingQuestions.easyQuestions,
          timings.easyProgramming
        );
        const mediumProgrammingDuration = calculateTiming(
          programmingQuestions.mediumQuestions,
          timings.mediumProgramming
        );
        const hardProgrammingDuration = calculateTiming(
          programmingQuestions.hardQuestions,
          timings.hardProgramming
        );

        // Sum up the durations to get the total test duration
        testDuration +=
          easyMCQDuration +
          mediumMCQDuration +
          hardMCQDuration +
          easyProgrammingDuration +
          mediumProgrammingDuration +
          hardProgrammingDuration;

        // Calculate scores for each type of question
        const easyMCQScore = calculateScore(mcqQuestions.easyQuestions);
        const mediumMCQScore = calculateScore(mcqQuestions.mediumQuestions);
        const hardMCQScore = calculateScore(mcqQuestions.hardQuestions);

        const easyProgrammingScore = calculateScore(
          programmingQuestions.easyQuestions
        );
        const mediumProgrammingScore = calculateScore(
          programmingQuestions.mediumQuestions
        );
        const hardProgrammingScore = calculateScore(
          programmingQuestions.hardQuestions
        );

        const mcqScore = easyMCQScore + mediumMCQScore + hardMCQScore;
        const programmingScore =
          easyProgrammingScore + mediumProgrammingScore + hardProgrammingScore;

        // Sum up the scores to get the total test score
        testScore += mcqScore + programmingScore;

        const totalMcqQuestions =
          mcqQuestions.easyQuestions.length +
          mcqQuestions.mediumQuestions.length +
          mcqQuestions.hardQuestions.length;
        const totalProgrammingQuestions =
          programmingQuestions.easyQuestions.length +
          programmingQuestions.mediumQuestions.length +
          programmingQuestions.hardQuestions.length;
        const totalEasyQuestions =
          mcqQuestions.easyQuestions.length +
          programmingQuestions.easyQuestions.length;
        const totalMediumQuestions =
          mcqQuestions.mediumQuestions.length +
          programmingQuestions.mediumQuestions.length;
        const totalHardQuestions =
          mcqQuestions.hardQuestions.length +
          programmingQuestions.hardQuestions.length;
        const totalQuestions = totalMcqQuestions + totalProgrammingQuestions;

        return {
          skill: item.skill,
          totalMcqQuestions,
          totalEasyMCQQuestions: mcqQuestions.easyQuestions.length,
          totalMediumMCQQuestions: mcqQuestions.mediumQuestions.length,
          totalHardMCQQuestions: mcqQuestions.hardQuestions.length,

          totalProgrammingQuestions,
          totalEasyProgrammingQuestions:
            programmingQuestions.easyQuestions.length,
          totalMediumProgrammingQuestions:
            programmingQuestions.mediumQuestions.length,
          totalHardProgrammingQuestions:
            programmingQuestions.hardQuestions.length,

          totalEasyQuestions,
          totalMediumQuestions,
          totalHardQuestions,
          totalQuestions,
          totalMcqScore: mcqScore,
          totalProgrammingScore: programmingScore,
          totalScore: mcqScore + programmingScore,
        };
      });

      // Calculate total values for all questions
      const totalMcqQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalMcqQuestions,
        0
      );
      const totalEasyMCQQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalEasyMCQQuestions,
        0
      );
      const totalMediumMCQQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalMediumMCQQuestions,
        0
      );
      const totalHardMCQQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalHardMCQQuestions,
        0
      );

      const totalMcqScore = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalMcqScore,
        0
      );

      const totalProgrammingQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalProgrammingQuestions,
        0
      );
      const totalEasyProgrammingQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalEasyProgrammingQuestions,
        0
      );
      const totalMediumProgrammingQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalMediumProgrammingQuestions,
        0
      );
      const totalHardProgrammingQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalHardProgrammingQuestions,
        0
      );

      const totalProgrammingScore = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalProgrammingScore,
        0
      );

      const totalNoOfQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalQuestions,
        0
      );

      const totalEasyQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalEasyQuestions,
        0
      );
      const totalMediumQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalMediumQuestions,
        0
      );
      const totalHardQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalHardQuestions,
        0
      );

      // Extract all question IDs
      const allQuestions = skillWiseQuestions.reduce(
        (acc, item) => [
          ...acc,
          ...item.mcqQuestions.easyQuestions,
          ...item.mcqQuestions.mediumQuestions,
          ...item.mcqQuestions.hardQuestions,
          ...item.programmingQuestions.easyQuestions,
          ...item.programmingQuestions.mediumQuestions,
          ...item.programmingQuestions.hardQuestions,
        ],
        []
      );
      const questionIds = allQuestions.map((item) => item._id);
      const totalSkills = skillWiseMetaData.length;

      // Dispatch setAssessment action
      dispatch(
        setAssessment({
          totalScore: testScore,
          cutOff: Math.floor(testScore * 0.6),
          testDuration: testDuration,
        })
      );

      const result = {
        questionIds,
        totalMcqQuestions,
        totalEasyMCQQuestions,
        totalMediumMCQQuestions,
        totalHardMCQQuestions,
        totalMcqScore,
        totalProgrammingQuestions,
        totalEasyProgrammingQuestions,
        totalMediumProgrammingQuestions,
        totalHardProgrammingQuestions,
        totalProgrammingScore,
        totalNoOfQuestions,
        totalEasyQuestions,
        totalMediumQuestions,
        totalHardQuestions,
        totalSkills,
        skillWiseMetaData,
      };

      return result;
    } catch (error) {
      console.error("Error in generateAndSetMetaData thunk:", error);
      throw error;
    }
  }
);

/**
 * Calculate the total timing for a set of questions
 * @param {Array} questions - Array of questions
 * @param {number} timing - Time per question
 * @returns {number} - Total timing
 */
const calculateTiming = (questions, timing) => questions.length * timing;

/**
 * Calculate the total score for a set of questions
 * @param {Array} questions - Array of questions
 * @returns {number} - Total score
 */
const calculateScore = (questions) => {
  const totalScore = questions.reduce(
    (acc, question) => acc + question.score,
    0
  );
  return totalScore;
};

/**
 * ATFMetaData slice
 */
const ATFMetaDataSlice = createSlice({
  name: "ATFMetaData",
  initialState,
  reducers: {
    /**
     * Reset the metadata to the initial state
     * @param {Object} state - The current state
     */
    resetMetaData: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateAndSetMetaData.fulfilled, (state, action) => {
      Object.assign(state, action.payload);
    });
  },
});

export const { resetMetaData } = ATFMetaDataSlice.actions;
export default ATFMetaDataSlice.reducer;
