import { useEffect, useRef } from "react";
import Cookies from "js-cookie";

const useCookieChangeListener = (cookieName, callback, interval = 1000) => {
  const previousValue = useRef(Cookies.get(cookieName));

  useEffect(() => {
    const checkCookieChange = () => {
      const cookie = Cookies.get(cookieName);
      if (cookie) {
        const { value, expirationTime } = JSON.parse(cookie);
        const isExpired = new Date() > new Date(expirationTime);

        if (isExpired) {
          if (previousValue.current !== null) {
            previousValue.current = null;
            callback(null); // Cookie is expired
          }
        } else if (cookie !== previousValue.current) {
          previousValue.current = cookie;
          callback(value);
        }
      } else {
        if (previousValue.current !== null) {
          previousValue.current = null;
          callback(null); // Cookie does not exist
        }
      }
    };

    const intervalId = setInterval(checkCookieChange, interval);

    return () => clearInterval(intervalId);
  }, [cookieName, callback, interval]);
};

export default useCookieChangeListener;
