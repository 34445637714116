import { initializeApp } from "firebase/app";
import { onMessage, getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCY_20aW1fCAOSb73lW6yjmcZ-SvACZ0JQ",
  authDomain: "hire360-1afad.firebaseapp.com",
  projectId: "hire360-1afad",
  storageBucket: "hire360-1afad.appspot.com",
  messagingSenderId: "923610545949",
  appId: "1:923610545949:web:3b261fb4091c5f03e6abf4",
  measurementId: "G-3099CQF8HF",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
  const { title, body, click_action } = payload.data;
console.log('Foreground message...')
  if (Notification.permission === "granted") {
    const notificationOptions = {
      body,
      icon: "./Favicon_.png",
      data: {
        url: click_action || "https://staging.app.hire360.ai/dashboard",
      },
    };

    const notification = new Notification(title, notificationOptions);

    notification.onclick = (event) => {
      event.preventDefault();
      const notificationUrl = notificationOptions.data.url;
      window.open(notificationUrl, "_blank", "noopener,noreferrer");
    };
  } else {
    console.log("Notification permission not granted.");
  }
});
