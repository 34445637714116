import React, { useState, useEffect, useRef } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  selectBox: {
    width: "100%",
    color: `${theme.palette.secondary.main} !important`,
    fontSize: "14px",
  },
  selectBoxDefault: {
    width: "100%",
    color: "#000",
    fontSize: "14px",
  },
  centeredLabel: {
    textAlign: "center",
  },
}));

const DashboardSelect = ({ title, menuItems, handleChange }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [menuProps, setMenuProps] = useState({});
  const selectRef = useRef(null);

  useEffect(() => {
    if (selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;

      setMenuProps({
        PaperProps: {
          style: {
            maxHeight: 48 * 6,
            overflowY: "auto",
          },
        },
        anchorOrigin: {
          vertical:
            spaceBelow < 240 && spaceAbove > spaceBelow ? "top" : "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical:
            spaceBelow < 240 && spaceAbove > spaceBelow ? "bottom" : "top",
          horizontal: "left",
        },
      });
    }
  }, [open]);

  return (
    <Box ref={selectRef}>
      <FormControl fullWidth size="small">
        <InputLabel
          id="demo-simple-select-label"
          className={classes.centeredLabel}
        >
          {title}
        </InputLabel>
        <Select
          size="small"
          defaultValue={menuItems?.[0].value}
          labelId="demo-simple-select-label"
          className={classes.selectBox}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          label={title}
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={({ onClick }) => (
            <Box
              component={"img"}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
                if (onClick) onClick(e);
              }}
              sx={{
                rotate: open ? "180deg" : "0deg",
                marginRight: "12px",
                cursor: "pointer",
              }}
              src={"/assets/icons/chevronDown.svg"}
            />
          )}
          onChange={(e) => handleChange(e.target.value)}
          MenuProps={menuProps}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DashboardSelect;
