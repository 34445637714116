import { Box, Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import Iconify from "../iconify/Iconify";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import APICall from "../../utils/api";

const Divider = styled.div`
  width: 2px;
  height: 0px;
  border: 2px solid #8591a2;
  transform: rotate(-90deg);
`;
const ReadymadeTestCard = ({ template }) => {
  const navigate = useNavigate();

  const getDifficultySettings = (difficultyLevel) => {
    switch (difficultyLevel.toLowerCase()) {
      case "easy":
        return {
          bgcolor: "rgba(67, 157, 98, 0.1)",
          color: "rgba(67, 157, 98, 1)",
          path: "/assets/greenfire.svg",
          difficulty: "Easy",
        };
      case "medium":
        return {
          bgcolor: "rgba(255, 151, 54, 0.1)",
          color: "rgba(255, 151, 54, 1)",
          path: "/assets/orangefire.svg",
          difficulty: "Medium",
        };
      case "hard":
        return {
          bgcolor: "rgba(249, 73, 72, 0.1)",
          color: "rgba(249, 73, 72, 1)",
          path: "/assets/libraryFire.svg",
          difficulty: "Hard",
        };
      default:
        return {
          bgcolor: "rgba(0, 0, 0, 0.1)",
          color: "rgba(0, 0, 0, 1)",
          path: "/assets/default.svg",
          difficulty: "Unknown",
        };
    }
  };

  const handleCardClick = (assessmentId) => {
    navigate(`/template/overview`, {
      state: { assessmentId: assessmentId, template: true },
    });
  };

  const difficultySettings = getDifficultySettings(template?.difficultyLevels);

  const handleUseTemplate = async (templateId) => {
    try {
      const useTemplateRes = await APICall(
        `/assessment/usetemplate/${templateId}`,
        templateId,
        "post"
      );
      if (useTemplateRes && useTemplateRes.data.assessmentId) {
        navigate(`/assessments/autotest`, {
          state: {
            assessmentId: useTemplateRes.data.assessmentId,
            template: true,
            useTemplateEdit: true,
          },
        });
      } else {
        console.log("Required data not available to navigate.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={6} key={template._id}>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          handleCardClick(template._id);
        }}
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "12px", // Reduced padding from 16px to 12px
          gap: "8px", // Reduced gap from 12px to 8px
          backgroundColor: "#FFFFFF",
          border: "1px solid #D0D0D0", // Adjusted border to be lighter
          borderRadius: "4px",
          width: "100%", // Adjust width to be more compact
          cursor: "pointer",
          "&:hover": {
            borderColor: { md: "#FFB088", base: "#FF4500" }, // Adjusted hover border color
            borderWidth: { md: "2px", base: "1px" },
            ".use-template-button": {
              visibility: "visible",
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "18px",
              fontWeight: "600",
              color: "#313131",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              // gap: "10px",
              cursor: "pointer",
              padding: "0px 0px 6px 0px",
              // lineHeight: "24px",
            }}
          >
            {template.name}
            <Tooltip title="Test Preview" arrow>
              <img
                src="/assets/Visit.svg"
                alt="Test Preview"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`https://www.assessment.hire360.ai`, "_blank");
                }}
              />
            </Tooltip>
          </Typography>
          <Button
            variant="outlined"
            color="success"
            className="use-template-button"
            sx={{
              visibility: "hidden",
              borderColor: "#00C49A",
              color: "#00C49A",
              padding: "4px 8px", // Reduced padding for a smaller button
              fontSize: "12px", // Smaller font size
              height: "32px", // Reduced height
              justifyContent: "center",
              gap: "4px", // Reduced gap between icon and text
              "&:hover": {
                backgroundColor: "rgba(76, 175, 80, 0.1)",
                borderColor: "#4CAF50",
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleUseTemplate(template._id);
            }}
          >
            <img src="/assets/template.svg" alt="View Candidates" />
            Use Template
          </Button>
        </Box>

        <Stack
          direction={"row"}
          gap={"8px"}
          alignItems={"flex-start"}
          sx={{ flexWrap: "wrap" }}
        >
          <Tooltip title="Skills" arrow>
            <img
              src="/assets/topic.svg"
              alt="topic"
              style={{ marginTop: "7px" }}
            />
          </Tooltip>
          {template.skillsId.length === 0 ? (
            <Typography
              sx={{
                padding: "4px 8px",
                height: "28px",
                background: "#F2F4F8",
                borderRadius: "4px",
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#69778A",
              }}
            >
              N/A
            </Typography>
          ) : (
            template.skillsId.map((skills, index) => (
              <Typography
                key={index}
                sx={{
                  padding: "4px 8px",
                  height: "28px",
                  background: "#F2F4F8",
                  borderRadius: "4px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#69778A",
                }}
              >
                {skills.name}
              </Typography>
            ))
          )}
        </Stack>

        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: "#535A5F",
          }}
        >
          {template?.description ? (
            <span dangerouslySetInnerHTML={{ __html: template.description }} />
          ) : null}
        </Typography>

        <Stack
          divider={<Divider />}
          direction={"row"}
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "8px 0px 8px 0px",
            boxSizing: "border-box",
            gap: "12px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              padding: "4px 8px",
              backgroundColor: difficultySettings.bgcolor,
              borderRadius: "4px",
            }}
          >
            <img src={difficultySettings.path} alt="difficulty" />
            <Typography
              sx={{
                color: difficultySettings.color,
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: "Roboto",
                lineHeight: "20px",
              }}
            >
              {template?.difficultyLevels.charAt(0).toUpperCase() +
                template?.difficultyLevels.slice(1)}
            </Typography>
          </Box>
          <Stack direction={"row"} gap={"4px"}>
            <Iconify
              icon="tabler:clock"
              width={"20px"}
              color={"background.darkGray"}
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Roboto",
                lineHeight: "20px",
                color: "#8591A2",
              }}
            >
              {template?.testDuration} mins
            </Typography>
          </Stack>
          <Stack direction={"row"} gap={"4px"}>
            <Iconify
              icon="octicon:question-24"
              width={"20px"}
              color={"background.darkGray"}
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Roboto",
                lineHeight: "20px",
                color: "#8591A2",
              }}
            >
              {template?.totalNoOfQuestions} Ques.
            </Typography>
          </Stack>
          <Stack direction={"row"} gap={"4px"}>
            <Iconify
              icon="simple-line-icons:badge"
              width={"20px"}
              color={"background.darkGray"}
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Roboto",
                lineHeight: "20px",
                color: "#8591A2",
              }}
            >
              Score : {template?.totalScore}
            </Typography>
          </Stack>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              flexDirection: "row",
            }}
          >
            <img
              src="/assets/icons/clipboardGray.svg"
              alt="remark"
              width={20}
              height={20}
            />

            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Roboto",
                lineHeight: "20px",
                color: "#8591A2",
              }}
            >
              Cut-off : {template?.cutOff}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
};

export default ReadymadeTestCard;
