import {
  Box,
  Typography,
  IconButton,
  Stack,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  MenuItem,
  Menu,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SectionTable from "../../components/questions/SectionTable";
import SkillsTable from "../../components/questions/SkillsTable";
import { useLocation, useNavigate } from "react-router-dom";
import APICall from "../../utils/api";
import { AuthContext } from "../../context/authContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import theme from "../../theme/theme";
import MyLibraryCard from "../../components/libraryPage/MyLibraryCard";
import CommonModal from "../../components/modal/CommonModal";
import { useMessage } from "../../components/snackbar/snackbar";
import SearchBar from "../../components/common/SearchBar";
import Iconify from "../../components/iconify/Iconify";
import { getDifficultSettingsByLevel } from "../../utils";
import { questionsData } from "../../utils/questionsData";
import AddQuestionDrawer from "../../components/libraryPage/AddQuestionDrawer";
import AssesmentFilter from "../../components/assesmentPage/AssesmentFilter";
import { handleApplyFilter } from "../../utils/filter";
import LoadingScreen from "../../layouts/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  main: {
    borderRadius: "4px",
    padding: "16px",
    background: theme.palette.background.default,
    width: "100%",
  },
  tableHeader: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    alignItems: "center",
    gap: "16px",
  },
  tableName: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "24px",
    textWrap: "nowrap",
    // marginRight: "16px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "16px",
    },
  },
  tableHeaderWrapper: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  centerHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const SectionHeader = ({ title, showHideQuestionButtons, onClickViewQues }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <Typography
      sx={{
        color: "#010101",
        fontSize: "16px",
        height: "20px",
        lineHeight: "24px",
        fontWeight: "600",
        fontFamily: "Nunito",
      }}
    >
      {title}
    </Typography>
    {!showHideQuestionButtons ? (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            cursor: "pointer",
          }}
          onClick={onClickViewQues}
        >
          <img src="/assets/redirect_icon.svg" alt="redirect icon" />
          <Typography
            sx={{
              color: "#FF6812",
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: "400",
              fontFamily: "Roboto",
              padding: "0",
              minWidth: "auto",
            }}
          >
            View Questions
          </Typography>
        </Box>
      </>
    ) : (
      <> </>
    )}
  </Box>
);

const Questions = ({
  assessmentId,
  isTemplate,
  section,
  loading,
  selectedQuestionsforshowInTmeplates,
  topic,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { authUser } = useContext(AuthContext);
  const [showTable, setShowTable] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [questions, setQuestions] = useState(questionsData.documents);
  const [assessmentQuestions, setAssessmentQuestions] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [addQuestionToggle, setAddQuestionToggle] = useState(false);
  const [isEditQuestion, SetIsEditQuestion] = useState(false);
  const [editQuestionData, setEditQuestionData] = useState({});
  const [questionOpen, setQuestionOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchedSkill, setSearchedSkill] = useState("");
  const [searchedTopic, setSearchedTopic] = useState("");

  const [filters, setFilters] = useState({
    query: "",
    source: [],
    skills: [],
    topics: [],
    difficultyLevels: [],
    type: [],
  });

  const [filterLoading, setFilterLoading] = useState(false);

  const filterOptions = [
    {
      label: "Skills",
      value: "skills",
      isSearchBar: true,
      searchValue: "",
      total: 0,
      onSearch: function (query) {
        setSearchedSkill(query);
      },
      checkBox: [
        {
          label: "Select All",
          value: "Select All",
          isChecked: false,
        },
        {
          label: "JavaScript",
          value: "JavaScript",
          isChecked: false,
        },
        {
          label: "Python",
          value: "Python",
          isChecked: false,
        },
        {
          label: "Java",
          value: "Java",
          isChecked: false,
        },
      ],
    },
    {
      label: "Difficulty",
      value: "difficultyLevels",
      isSearchBar: false,
      total: 0,
      checkBox: [
        {
          label: "Select All",
          value: "Select All",
          isChecked: false,
        },
        {
          label: "Easy",
          value: "Easy",
          isChecked: false,
        },
        {
          label: "Medium",
          value: "Medium",
          isChecked: false,
        },
        {
          label: "Hard",
          value: "Hard",
          isChecked: false,
        },
      ],
    },
    {
      label: "Question Type",
      value: "type",
      isSearchBar: false,
      checkBox: [
        {
          label: "Select All",
          value: "Select All",
          isChecked: false,
        },
        {
          label: "MCQ",
          value: "MCQ",
          isChecked: false,
        },
        {
          label: "Programming",
          value: "Programming",
          isChecked: false,
        },
        {
          label: "SQL",
          value: "SQL",
          isChecked: false,
        },
      ],
    },
    {
      label: "Library",
      value: "library",
      isSearchBar: false,
      checkBox: [
        {
          label: "Select All",
          value: "Select All",
          isChecked: false,
        },
        {
          label: "Hire360 Library",
          value: "hire360",
          isChecked: false,
        },
        {
          label: "My Library",
          value: "myLibrary",
          isChecked: false,
        },
      ],
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMCQClick = () => {
    SetIsEditQuestion(false);
    handleClose();
    setAddQuestionToggle((prev) => !prev);
  };

  const handleQuestionClose = () => {
    setAddQuestionToggle(false);
  };

  const handleQuestionOpen = () => {
    setQuestionOpen(true);
    setAddQuestionToggle(false);
  };

  const handleFilterOpen = () => {
    setIsFilterOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterOpen(false);
  };

  const handleFilterOptions = () => {
    setFilters([...filterOptions]);
    setSearchValue("");
    setTableParams({
      filters: tableFilters,
      pagination: {
        page: 1,
        limit: 10,
      },
    });
  };

  useEffect(() => {
    handleFilterOptions();
  }, []);

  const onHandleApplyFilter = async (newFilter) => {
    let appliedFilters = newFilter ? newFilter : filters;
    const conditionFn = (filter) => {
      if (filter.label === "Source" || filter.label === "Question Type") {
        if (
          filter.checkBox.some(
            (cb) => cb.label === "Select All" && cb.isChecked
          )
        ) {
          return true;
        }
      }
      return false;
    };
    handleApplyFilter(
      appliedFilters,
      tableParams,
      setTableParams,
      setIsFilterOpen,
      conditionFn
    );
  };

  const tableFilters = {
    query: "",
    source: [],
    skills: [],
    topics: [],
    difficultyLevels: [],
    type: [],
  };

  const [tableParams, setTableParams] = useState({
    filters: tableFilters,
    pagination: {
      page: 1,
      limit: 10,
    },
  });

  const difficultyColors = {
    easy: "#439D62",
    medium: "#FF9736",
    hard: "#F94948",
  };

  const difficultyImages = {
    easy: "/assets/greenfire.svg",
    medium: "/assets/orangefire.svg",
    hard: "/assets/libraryFire.svg",
  };

  const columns = [
    { field: "id", headerName: "Sr. no", width: 60 },
    {
      field: "section",
      headerName: "Section Name",
      width: 244.67,
      sortable: false,
    },
    {
      field: "questions",
      headerName: "Questions",
      width: 244.67,
      sortable: false,
    },
    {
      field: "difficulty",
      headerName: "Difficulty",
      width: 320,
      cellStyle: { textAlign: "center" },
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {Object.keys(params.value).map((difficulty, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: "4px",
                padding: "2px 8px",
                borderRadius: "4px",
                backgroundColor: `${difficultyColors[difficulty]}1A`,
              }}
            >
              <img src={difficultyImages[difficulty]} alt={difficulty} />
              <Typography
                sx={{
                  color: difficultyColors[difficulty],
                  fontSize: "12px",
                  fontWeight: 400,
                  fontFamily: "Roboto",
                  lineHeight: "16px",
                }}
              >
                {difficulty.charAt(0).toUpperCase() + difficulty.slice(1)} (
                {params.value[difficulty]})
              </Typography>
            </Box>
          ))}
        </Box>
      ),
    },
    {
      field: "score",
      headerName: "Score",
      width: 244.67,
      // align: "left",
      sortable: false,
    },
  ];

  const rows = [
    {
      id: 1,
      section: "Java",
      questions: 15,
      difficulty: { easy: 5, medium: 5, hard: 5 },
      score: 45,
    },
    {
      id: 2,
      section: "Python",
      questions: 5,
      difficulty: { easy: 1, medium: 2, hard: 2 },
      score: 25,
    },
    {
      id: 3,
      section: "PHP",
      questions: 10,
      difficulty: { easy: 2, medium: 4, hard: 4 },
      score: 30,
    },
    {
      id: 4,
      section: "C++",
      questions: 20,
      difficulty: { easy: 6, medium: 8, hard: 6 },
      score: 60,
    },
  ];

  useEffect(() => {
    setSelectAll(selectedQuestions.length === questions.length);
  }, [selectedQuestions, questions]);

  let timeoutId = null;

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setTableParams((prev) => ({
        ...prev,
        filters: {
          ...prev.filters,
          query: value,
        },
        pagination: {
          ...prev.pagination,
          page: 1,
          limit: prev.pagination.limit,
        },
      }));
    }, 500);
  };

  const handleSelectAllChange = (event) => {
    setSelectedQuestions(
      event.target.checked ? questions.map((q) => q._id) : []
    );
  };

  const handleSelectQuestion = (questionId) => {
    setSelectedQuestions((prev) =>
      prev.includes(questionId)
        ? prev.filter((id) => id !== questionId)
        : [...prev, questionId]
    );
  };

  const handleConfirmDelete = async (question) => {};

  const fetchAssessmentDetails = async () => {
    try {
      const res = await APICall(
        `/assessmentQuestion/getQuestionByAssessment/?assessmentId=${assessmentId}`
      );
      setAssessmentQuestions(res.data);
    } catch (error) {
      console.error("Failed to fetch assessment details:", error);
    }
  };

  useEffect(() => {
    fetchAssessmentDetails();
  }, [assessmentId]);

  const isRecruiter = authUser.role === "recruiter";
  const isAdmin = authUser.role === "super admin";
  const showSectionWise = section === "section_wise" || !section;
  const showSkillWise = section === "skill_wise" || !section;

  return (
    <Box
      sx={{
        width: "100%",
        background: "#fff",
      }}
    >
      {/* section wise */}
      {(authUser.role == "recruiter" && isTemplate) ||
      authUser.role == "super admin" ? (
        <Box
          sx={{
            mt: "0.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.2rem",
          }}
        >
          {(section === "section_wise" || !section) && (
            <>
              <SectionHeader
                title="Section Wise"
                onClickViewQues={() =>
                  navigate(`/assessments/view-questions/section`, {
                    state: { assessmentId },
                  })
                }
                showHideQuestionButtons={isTemplate}
              />

              <SectionTable
                data={assessmentQuestions?.questionStats?.sectionWise}
                topic={topic}
              />
              {authUser.role == "recruiter" && isTemplate && (
                <>
                  {selectedQuestionsforshowInTmeplates.map(
                    (question, index) => (
                      <MyLibraryCard
                        isSelected={false}
                        question={question}
                        difficultySettings={getDifficultSettingsByLevel(
                          question.difficultyLevel?.level
                        )}
                        key={index}
                        showHideQuestionButtons={isTemplate}
                      />
                    )
                  )}
                </>
              )}
            </>
          )}

          {(section === "skill_wise" || !section) && (
            <>
              {loading && <LoadingScreen />}
              <SectionHeader
                title="Skill Wise"
                onClickViewQues={() =>
                  navigate(`/assessments/view-questions/skill`, {
                    state: { assessmentId },
                  })
                }
                showHideQuestionButtons={isTemplate}
              />
              <SkillsTable
                data={assessmentQuestions?.questionStats?.skillWise}
                topic={topic}
              />
              {authUser.role == "recruiter" && isTemplate && (
                <>
                  {selectedQuestionsforshowInTmeplates.map(
                    (question, index) => (
                      <MyLibraryCard
                        isSelected={false}
                        question={question}
                        difficultySettings={getDifficultSettingsByLevel(
                          question.difficultyLevel?.level
                        )}
                        key={index}
                        showHideQuestionButtons={isTemplate}
                      />
                    )
                  )}
                </>
              )}
            </>
          )}
        </Box>
      ) : (
        <Stack
          direction="column"
          gap="12px"
          sx={{ padding: "0px 2px" }}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <Box
            sx={{
              mt: "1rem",
              border: "1px solid #E8E9EE",
              borderRadius: "0.25rem",
              display: "flex",
              gap: "8px",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #E8E9EE",
                padding: "10px 16px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#363E45",
                }}
              >
                Questions at a glance
              </Typography>
              <IconButton
                onClick={() => setShowTable((prev) => !prev)}
                sx={{ padding: 0 }}
              >
                {showTable ? (
                  <ExpandLessIcon sx={{ width: 24, padding: 0 }} />
                ) : (
                  <ExpandMoreIcon sx={{ width: 24, padding: 0 }} />
                )}
              </IconButton>
            </Box>
            {showTable && (
              <Box sx={{ padding: "0px 12px 12px 12px" }}>
                <DataGrid
                  className={classes.table}
                  rows={rows}
                  columns={columns}
                  hideFooter
                  disableColumnMenu
                  disableSelectionOnClick
                  disableRowSelectionOnClick
                  disableColumnFilter
                  rowHeight={40}
                  columnHeaderHeight={36}
                  sx={{
                    "& .MuiDataGrid-cell": { outline: "none !important" },
                    "& .MuiDataGrid-columnHeader": {
                      outline: "none !important",
                    },
                    "& [aria-rowindex='1']": {
                      background: `${theme.palette.background.gray} !important`,
                    },
                    "& [data-field='id']": {
                      borderRight: `1px solid ${theme.palette.primary.gray}`,
                    },
                  }}
                />
              </Box>
            )}
          </Box>
          <Box>
            {selectedQuestions.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingLeft: "16px",
                }}
              >
                <Box
                  sx={{
                    display: { base: "block", md: "flex" },
                    alignItems: "center",
                    width: { base: "100%", md: "auto" },
                  }}
                >
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    sx={{
                      color: selectAll ? "#ff6812" : "default",
                      "&.Mui-checked": { color: "#ff6812" },
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      fontFamily: "Nunito, sans-serif",
                    }}
                  >
                    {selectedQuestions.length} selected
                  </Typography>
                </Box>
                <Box sx={{ cursor: "pointer" }}>
                  <Tooltip title="Delete" arrow>
                    <IconButton
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid",
                        borderRadius: "4px",
                        bgcolor: "#FF6812",
                        borderColor: "#FF6812",
                        "&:hover": {
                          bgcolor: "#f85a00",
                          borderColor: "#f85a00",
                        },
                        height: "32px",
                      }}
                      onClick={() => setDeleteConfirmOpen(true)}
                    >
                      <Typography variant="body2" sx={{ color: "white" }}>
                        Delete ({selectedQuestions.length})
                      </Typography>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            ) : (
              <Box className={classes.tableHeader}>
                <Typography
                  className={classes.tableName}
                  sx={{ marginLeft: "25px" }}
                >
                  All (10)
                </Typography>
                <Box className={classes.tableHeaderWrapper}>
                  <SearchBar
                    placeholder="Search Candidate"
                    value={searchValue}
                    onChange={handleSearchChange}
                  />
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <IconButton
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid",
                        borderRadius: "4px",
                        bgcolor: "#FF6812",
                        borderColor: "#FF6812",
                        "&:hover": {
                          bgcolor: "#f85a00",
                          borderColor: "#f85a00",
                        },
                      }}
                    >
                      <Box
                        component={"img"}
                        src="/assets/icons/listSearch.svg"
                        alt="codepush"
                        sx={{ marginRight: "8px" }}
                      />
                      <Typography variant="body2" sx={{ color: "white" }}>
                        Choose From Library
                      </Typography>
                    </IconButton>
                    <IconButton
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid",
                        borderRadius: "4px",
                        bgcolor: "#00c49a",
                        borderColor: "#00c49a",
                        "&:hover": {
                          bgcolor: "#35ba96",
                          borderColor: "#35ba96",
                        },
                      }}
                      onClick={handleClick}
                    >
                      <Box
                        component={"img"}
                        src="/assets/icons/codePlus.svg"
                        alt="codepush"
                        sx={{ marginRight: "8px" }}
                      />
                      <Typography variant="body2" sx={{ color: "white" }}>
                        Add Question
                      </Typography>
                    </IconButton>

                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                      <MenuItem onClick={handleMCQClick}>
                        <img
                          src="/assets/mcq.svg"
                          style={{ marginRight: "8px" }}
                        />
                        MCQ
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <img
                          src="/assets/programming.svg"
                          style={{ marginRight: "8px" }}
                        />
                        Programming
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <img
                          src="/assets/sql.svg"
                          style={{ marginRight: "8px" }}
                        />
                        SQL
                      </MenuItem>
                    </Menu>
                    <Tooltip title="Filter" placement="bottom" arrow>
                      <IconButton
                        sx={{
                          width: "36px",
                          height: "36px",
                          border: "1px solid",
                          borderRadius: "4px",
                          padding: 0,
                          borderColor: "secondary.main",
                        }}
                        onClick={handleFilterOpen}
                      >
                        <Iconify
                          icon="mage:filter"
                          width={"16px"}
                          color={"secondary.main"}
                        />
                      </IconButton>
                    </Tooltip>
                    {isFilterOpen && (
                      <AssesmentFilter
                        open={isFilterOpen}
                        onClose={handleFilterClose}
                        filters={filters}
                        setFilters={setFilters}
                        filterLoading={filterLoading}
                        handleApplyFilter={onHandleApplyFilter}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            {assessmentQuestions?.assessmentQuestions?.skills?.map((skill) => (
              <Box key={skill._id}>
                {/* Flatten and combine all questions from mcqQuestions and programmingQuestions within each skill */}
                {[
                  ...(skill.mcqQuestions?.easyQuestions || []),
                  ...(skill.mcqQuestions?.mediumQuestions || []),
                  ...(skill.mcqQuestions?.hardQuestions || []),
                  ...(skill.programmingQuestions?.easyQuestions || []),
                  ...(skill.programmingQuestions?.mediumQuestions || []),
                  ...(skill.programmingQuestions?.hardQuestions || []),
                ].map((question) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    display="flex"
                    gap={1.5}
                    key={question._id}
                  >
                    <FormGroup
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "10px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            title="test"
                            icon={
                              <Box
                                width={"20px"}
                                height={"20px"}
                                sx={{
                                  border: "1px solid",
                                  borderRadius: "4px",
                                  borderColor: theme.palette.secondary.main,
                                }}
                              />
                            }
                            checkedIcon={
                              <Box
                                component={"img"}
                                src={"/assets/icons/check.svg"}
                              />
                            }
                            onChange={() => handleSelectQuestion(question._id)}
                            sx={{ paddingY: "0" }}
                            checked={selectedQuestions.includes(question._id)}
                          />
                        }
                      />
                    </FormGroup>
                    <MyLibraryCard
                      isSelected={false}
                      question={question}
                      difficultySettings={getDifficultSettingsByLevel(
                        question.difficultyLevel?.level
                      )}
                    />
                    <CommonModal
                      icon={"/assets/delete_icon.svg"}
                      handleClose={() => setDeleteConfirmOpen(false)}
                      open={deleteConfirmOpen}
                      title={"Confirm Delete"}
                      onSubmit={() => handleConfirmDelete(question)}
                      sendButtonName={"Yes"}
                      cancelButtonName={"No"}
                      onCancel={() => setDeleteConfirmOpen(false)}
                      isDeleting={true}
                    >
                      <Typography
                        style={{
                          backgroundColor: "#fff0e7",
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                      >
                        <img
                          src="/assets/alert-1.svg"
                          alt="Alert"
                          style={{
                            verticalAlign: "middle",
                            marginRight: "10px",
                            marginTop: "10px",
                            marginBottom: "15px",
                          }}
                        />
                        <strong>
                          Are you certain you wish to delete this question?
                        </strong>
                        <br />
                        <div style={{ verticalAlign: "middle" }}>
                          This action will permanently delete the question and
                          all related data.
                        </div>
                      </Typography>
                    </CommonModal>
                  </Stack>
                ))}
              </Box>
            ))}
          </Box>
        </Stack>
      )}
      {addQuestionToggle && (
        <AddQuestionDrawer
          open={addQuestionToggle}
          onClose={handleQuestionClose}
          drawerHeading="Create Question"
          handleQuestionOpen={handleQuestionOpen}
          question={editQuestionData}
          isEditQuestion={isEditQuestion}
        />
      )}
    </Box>
  );
};

export default Questions;
