import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import CheatingCheckbox from "./CheatingCheckbox";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const CandidateSnapshots = ({ data }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedRadio, setSelectedRadio] = React.useState("All");
  const [displaySnapShots, setDisplaySnapShots] = React.useState([[]]);
  const webCamSnapShots = data?.assessmentResult?.webCamSnapShots || [];
  const maxSteps = displaySnapShots.length;

  const handlePrevPage = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1
    );
  };

  const handleNextPage = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep + 1 >= maxSteps ? 0 : prevActiveStep + 1
    );
  };

  React.useEffect(() => {
    setDisplaySnapShots(data?.assessmentResult?.webCamSnapShots || []);
  }, [data]);

  React.useEffect(() => {
    if (selectedRadio === "All") {
      setDisplaySnapShots(data?.assessmentResult?.webCamSnapShots || []);
    } else if (selectedRadio === "Found missing candidate") {
      setDisplaySnapShots(
        data?.assessmentResult?.missingCandidateSnapShotIds || []
      );
    } else if (selectedRadio === "Found duplicate candidate") {
      setDisplaySnapShots(
        data?.assessmentResult?.duplicateCandidateSnapShotIds || []
      );
    } else if (selectedRadio === "Found multiple candidates") {
      setDisplaySnapShots(
        data?.assessmentResult?.multipleCandidateSnapShotIds || []
      );
    }

    setActiveStep(0);
  }, [selectedRadio]);

  return (
    <Stack direction={"column"} alignItems={"flex-start"} gap={"16px"}>
      {data?.assessmentResult?.isCheatingDetected && (
        <CheatingCheckbox
          data={data}
          setSelectedRadio={setSelectedRadio}
          selectedRadio={selectedRadio}
        />
      )}
      <Stack direction={"column"} alignItems={"flex-start"} gap={"8px"}>
        {displaySnapShots.length > 0 && (
          <Box
            component="img"
            sx={{
              height: "auto",
              borderRadius: "4px",
              width: "100%",
              maxHeight: "350px",
              objectFit: "contain",
              // marginLeft: 0,
              // objectPosition: "left",

              display: "flex",
              alignItems: "flex-start",
            }}
            src={displaySnapShots[activeStep]}
            alt={`Snapshot ${activeStep + 1}`}
          />
        )}

        {displaySnapShots.length > 0 && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            width={"100%"}
            justifyContent={"center"}
          >
            <Button
              disabled={activeStep === 0}
              onClick={handlePrevPage}
              sx={{
                padding: "6px",
                borderRadius: "4px",
                border: "1px solid #D3D9E2",
                minWidth: "28px",
                boxSizing: "border-box",
                fontSize: "14px",
              }}
            >
              <KeyboardArrowLeft color="secondary" fontSize="1rem" />
            </Button>
            <Typography
              variant="body1"
              sx={{
                padding: "4px",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#71777B",
              }}
            >{`${activeStep + 1} of ${maxSteps}`}</Typography>
            <Button
              disabled={activeStep === maxSteps - 1}
              variant="outlined"
              onClick={handleNextPage}
              color="inherit"
              sx={{
                padding: "6px",
                borderRadius: "4px",
                border: "1px solid #D3D9E2",
                minWidth: "28px",
                boxSizing: "border-box",
                fontSize: "14px",
              }}
            >
              <KeyboardArrowRight color="secondary" fontSize="1rem" />
            </Button>
          </Stack>
        )}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "16px",
          }}
        >
          {displaySnapShots.map((url, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                sx={{
                  boxSizing: "border-box",
                  border: "1px solid #E8E9EE",
                  borderRadius: "4px",
                  height: { base: "64px", xs: "64px", md: "120px" },
                  width: { base: "60px", xs: "60px", md: "160px" },
                  boxShadow:
                    index === activeStep ? "0px 0px 0px 2px #0077ff" : "none", // Highlight active step
                }}
                src={url}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => setActiveStep(index)} // Set activeStep on thumbnail click
              />
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
              >
                {`Snapshot ${index + 1}`}
              </Typography>
            </Box>
          ))}
        </Box>
      </Stack>
    </Stack>
  );
};

export default CandidateSnapshots;
