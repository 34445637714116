import React, { useCallback, useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  IconButton,
  Box,
  Stack,
  Button,
  FormGroup,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import DropdownField from "../common/DropdownField";
import { styled } from "@mui/material/styles";
import TextEditor from "../common/TextEditor";

import "suneditor/dist/css/suneditor.min.css";
import katex from "katex";
import "katex/dist/katex.min.css";
import { useMessage } from "../snackbar/snackbar";
import { v4 as uuidv4 } from "uuid";
import SunTextEditor from "../common/SunTextEditor";

const StyledFormControlLabel = styled(FormControlLabel)({
  margin: 0,
  flexGrow: 1,
  "&:not(:last-child)": {
    marginBottom: "24px",
  },
  "& .MuiFormControlLabel-label": {
    flexGrow: 1,
  },
});

const AddOptions = ({
  multipleChoice,
  required,
  richtextBox,
  formik,
  selectedOptions,
  setSelectedOptions,
  selectedOption,
  setSelectedOption,
  options,
  setOptions,
  updateCorrectAnswers,
}) => {
  const message = useMessage();

  const handleAddOption = useCallback(() => {
    if (options.length < 6) {
      const newId = options.length + 1;
      setOptions([...options, { id: newId, text: "" }]);
    }
  }, [options, setOptions]);

  const handleOptionChange = useCallback(
    (event, id) => {
      const value = parseInt(event.target.value);
      if (multipleChoice) {
        setSelectedOptions((prevSelectedOptions) =>
          prevSelectedOptions.includes(id)
            ? prevSelectedOptions.filter((optId) => optId !== id)
            : [...prevSelectedOptions, id]
        );
      } else {
        setSelectedOption(value);
      }
    },
    [multipleChoice]
  );

  const handleOptionTextChange = useCallback((id, text) => {
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === id ? { ...option, text } : option
      )
    );
  }, []);

  const handleOptionDelete = useCallback(
    (id) => {
      if (options.length > 2) {
        const deletedOptionIndex = options.findIndex(
          (option) => option.id === id
        );

        const updatedOptions = options.filter((option) => option.id !== id);

        const reassignedOptions = updatedOptions.map((option, index) => ({
          ...option,
          id: index + 1,
        }));

        setSelectedOptions((prevSelectedOptions) =>
          prevSelectedOptions
            .filter((optionId) => optionId !== id)
            .map((optionId) => {
              const previousIndex = options.findIndex(
                (option) => option.id === optionId
              );
              if (previousIndex > deletedOptionIndex) {
                return reassignedOptions[previousIndex - 1]?.id ?? null;
              } else if (previousIndex === deletedOptionIndex) {
                return null;
              }
              return reassignedOptions[previousIndex]?.id ?? null;
            })
            .filter((optionId) => optionId !== null)
        );

        if (selectedOption === id) {
          setSelectedOption(null);
        } else if (
          selectedOption !== null &&
          selectedOption > deletedOptionIndex
        ) {
          setSelectedOption(selectedOption - 1);
        }

        setOptions(reassignedOptions);

        console.log(reassignedOptions, "Updated Options");
      } else {
        message("At least two options are required", "error");
      }
    },
    [options, selectedOption]
  );

  const convertHtmlToText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  React.useEffect(() => {
    if (selectedOption || selectedOptions.length > 0) {
      updateCorrectAnswers();
    }
  }, [selectedOption, selectedOptions, formik.values.answers]);

  React.useEffect(() => {
    formik.setFieldValue(
      "answers",
      options.map((option) => ({
        optionId: uuidv4(),
        option: option.text,
        images: [],
      }))
    );
  }, [options]);

  React.useEffect(() => {}, [
    selectedOption,
    selectedOptions,
    formik.values.answers,
  ]);

  const handleRadioChange = (event) => {
    const selectedOption = parseInt(event.target.value);
    let correctAnswers = [];

    correctAnswers.push(formik.values.answers[selectedOption - 1]);
    console.log(selectedOption);
    setSelectedOption(selectedOption);
    formik.setFieldValue("correctAnswers", correctAnswers);
  };
  return (
    <Stack spacing={0}>
      <FormControl sx={{ flexGrow: 1 }}>
        {multipleChoice ? (
          <FormGroup>
            {options.map((option) => (
              <Box
                key={option.id}
                sx={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                <Checkbox
                  id={`option-checkbox-${option.id}`}
                  checked={selectedOptions.includes(option.id)}
                  onChange={(e) => handleOptionChange(e, option.id)}
                  value={option.id}
                  sx={{
                    padding: 0,
                    width: "20px",
                    height: "20px",
                    margin: 0,
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    flexGrow: 1,
                  }}
                >
                  {richtextBox ? (
                    <SunTextEditor
                      id={`option-texteditor-${option.id}`}
                      error={
                        formik.touched.correctAnswers &&
                        Boolean(formik.errors.correctAnswers)
                      }
                      value={option.text}
                      setValue={(text) =>
                        handleOptionTextChange(option.id, text)
                      }
                      width="900px"
                    />
                  ) : (
                    <DropdownField
                      id={`option-dropdown-${option.id}`}
                      value={convertHtmlToText(option.text)}
                      error={
                        formik.touched.correctAnswers &&
                        Boolean(formik.errors.correctAnswers)
                      }
                      fullWidth
                      onChange={(e) =>
                        handleOptionTextChange(option.id, e.target.value)
                      }
                      placeholder={`Option ${option.id}`}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          color: "grey",
                        },
                      }}
                    />
                  )}
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleOptionDelete(option.id)}
                    size="small"
                    sx={{ padding: 0 }}
                  >
                    <Box
                      component={"img"}
                      src="/assets/delete.svg"
                      width={20}
                      height={20}
                    />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </FormGroup>
        ) : (
          <RadioGroup
            aria-label="options"
            name="options"
            value={selectedOption}
            onChange={(e) => handleRadioChange(e)}
          >
            {options.map((option) => (
              <Box
                key={option.id}
                sx={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                <Radio
                  id={`option-radio-${option.id}`}
                  value={option.id}
                  sx={{ width: "20px", height: "20px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    flexGrow: 1,
                  }}
                >
                  {richtextBox ? (
                    <SunTextEditor
                      id={`option-texteditor-${option.id}`}
                      value={option.text}
                      error={
                        formik.touched.correctAnswers &&
                        Boolean(formik.errors.correctAnswers)
                      }
                      setValue={(text) =>
                        handleOptionTextChange(option.id, text)
                      }
                      width="900px"
                    />
                  ) : (
                    <DropdownField
                      id={`option-dropdown-${option.id}`}
                      value={convertHtmlToText(option.text)}
                      error={
                        formik.touched.correctAnswers &&
                        Boolean(formik.errors.correctAnswers)
                      }
                      fullWidth
                      onChange={(e) =>
                        handleOptionTextChange(option.id, e.target.value)
                      }
                      placeholder={`Option ${option.id}`}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          color: "grey",
                        },
                      }}
                    />
                  )}
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleOptionDelete(option.id)}
                    size="small"
                    sx={{ padding: 0 }}
                  >
                    <Box
                      component={"img"}
                      src="/assets/delete.svg"
                      width={20}
                      height={20}
                    />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </RadioGroup>
        )}
      </FormControl>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          {formik.errors.correctAnswers && formik.touched.correctAnswers && (
            <FormHelperText error sx={{ alignSelf: "flex-start" }}>
              {formik.errors.correctAnswers}
            </FormHelperText>
          )}

          {formik.errors.answers &&
            formik.touched.answers &&
            formik.errors.answers.length > 0 && (
              <FormHelperText error sx={{ alignSelf: "flex-start" }}>
                Options cannot be blank
              </FormHelperText>
            )}
        </Box>
        <Button
          variant="text"
          onClick={handleAddOption}
          sx={{
            color: "#FF6812",
            fontSize: "14px",
            fontWeight: 400,
            padding: "6px 30px 6px 0px",
            lineHeight: "20px",
            fontFamily: "Roboto",
            width: "fit-content",

            marginLeft: "auto",
          }}
          disabled={options.length >= 6}
        >
          + Add Option
        </Button>
      </Box>
    </Stack>
  );
};

export default AddOptions;
