import * as React from "react";
import { Typography, capitalize, Tooltip, Button } from "@mui/material";
import { Box } from "@mui/system";
import CustomTable from "../common/customTable/CustomTable";
import SortingIcon from "../icons/SortingIcon";
import { useNavigate } from "react-router-dom";
import { getColorByStatus, capitalizeWords } from "../../utils";
import { getPaginationOptions } from "../../utils/pagination";
import { AuthContext } from "../../context/authContext";

const columnsWidth = ["60px", "240px", "90px", "240px", "240px", "240px"];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const tableBodyRowStyleProps = {
  height: "48px",
};

const tableHeaderTitles = [
  {
    title: "Sr. no",
    cellStyleProps: {
      borderRight: "1px solid #E8E9EE",
      width: columnsWidth[0],
    },
  },
  {
    isSortCol: true,
    colId: "candidate",
    title: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Typography
          sx={{ fontSize: "12px", fontWeight: "500", fontFamily: "Roboto" }}
        >
          Candidate
        </Typography>
        <SortingIcon />
      </Box>
    ),
    cellStyleProps: {
      width: columnsWidth[1],
    },
  },
  {
    title: "Channel",
    cellStyleProps: {
      width: columnsWidth[3],
    },
  },
  {
    title: "Interviewer",
    cellStyleProps: {
      width: columnsWidth[3],
    },
  },
  {
    title: "Scheduler",
    cellStyleProps: {
      width: columnsWidth[4],
    },
  },
  {
    title: "Job",
    cellStyleProps: {
      width: columnsWidth[5],
    },
  },
  {
    title: "Round",
    cellStyleProps: {
      width: columnsWidth[6],
    },
  },
];
const tableHeaderCells = () => {
  return tableHeaderTitles.map((item) => {
    return {
      isSortCol: item?.isSortCol ? item.isSortCol : false,
      colId: item?.colId ? item.colId : "",
      element: item.title,
      cellStyleProps: item.cellStyleProps,
      isSortable: item?.isSortable ? item.isSortable : false,
      cellId: item?.cellId ? item.cellId : "",
    };
  });
};

export default function Shortlisted({
  loading,
  setLoading,
  setTableParams,
  data,
  totalDocuments,
  totalPages,
  tableParams,
  handleChangePage,
}) {
  const navigate = useNavigate();
  const { authUser } = React.useContext(AuthContext);

  const tableBodyRowCell = (row, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          borderRight: "1px solid #E8E9EE",
          width: columnsWidth[0],
          maxWidth: columnsWidth[0],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.rowId}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[1],
          maxWidth: columnsWidth[1],
          ...cellStyleProps,
        },
        element: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img
              src={
                row?.candidateProfilePicture
                  ? row?.candidateProfilePicture
                  : "/assets/user.png"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/user.png";
              }}
              alt="user"
              style={{ height: "20px", width: "20px" }}
            />
            <Typography
              sx={{
                ...tableContentCellTextStyleProps,
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(`/assessments/candidateDetails`, {
                  state: {
                    candidateAssessmentId: row?.candidateAssessmentId?._id,
                    assessmentId: row?.assessmentId?._id,
                    assessmentDetails: row?.assessmentId,
                  },
                })
              }
            >
              {row?.candidateId?.name}
            </Typography>
          </Box>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[2],
          maxWidth: columnsWidth[2],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.channelDetails?.name}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[3],
          maxWidth: columnsWidth[3],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
              alignItems: "center",
            }}
          >
            {row?.InterviewDetails?.round2?.Interviewer?.name
              ? row.InterviewDetails.round2.Interviewer.name
              : row?.InterviewDetails?.round1?.Interviewer?.name
              ? row.InterviewDetails.round1.Interviewer.name
              : "N/A"}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[4],
          maxWidth: columnsWidth[4],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",

                display: "flex",

                cursor: "pointer",
                borderRight: "1px solid #E8E9EE",
                paddingRight: "8px",
                borderRadius: "4px", // Add border radius
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {" "}
              <Typography variant="body2">
                {row?.InterviewDetails?.round2?.Scheduler?.name
                  ? row.InterviewDetails.round2.Scheduler.name
                  : row?.InterviewDetails?.round1?.Scheduler?.name
                  ? row.InterviewDetails.round1.Scheduler.name
                  : "N/A"}
              </Typography>
              <Tooltip title="Ping Scheduler" arrow>
                <Box
                  sx={{
                    display: "flex",
                    gap: "4px",
                    paddingLeft: "4px",
                  }}
                >
                  {row?.InterviewDetails?.round1?.Scheduler?.name && (
                    <img
                      src="/assets/Vector-5.svg"
                      alt="Publisher"
                      sx={{ color: "#FF6812", marginLeft: "4px" }}
                    />
                  )}
                </Box>
              </Tooltip>
            </Typography>
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[5],
          maxWidth: columnsWidth[5],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {capitalizeWords(row?.jobRoleDetails?.jobRole)}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[6],
          maxWidth: columnsWidth[6],
          ...cellStyleProps,
        },
        element: getStatus(row?.currentStatus),
      },
    ];
  };

  const tableBodyContent = () => {
    return data?.map((row, idx) => {
      const cellStyleProps = {
        borderBottom: "1px solid #E8E9EE",
        padding: "10px 14px",
        whiteSpace: "nowrap",
      };
      return {
        sortable: {
          score: parseInt(row?.totalScore),
          candidate: row.data?.candidateId?.name,
        },
        rowId: idx + 1,
        rowElement: tableBodyRowCell(
          { ...row, rowId: idx + 1 },
          cellStyleProps
        ),
      };
    });
  };
  let timeoutId = null;

  const getStatus = (status) => {
    return authUser?.role === "hr" && status === "Shortlisted" ? (
      <Button
        variant="contained"
        sx={{
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#fff",
          // backgroundColor: getColorByStatus(status).color,
          textWrap: "nowrap",
        }}
      >
        Schedule Interview for R1
      </Button>
    ) : (
      <Box
        sx={{
          display: "inline-block",
          background: getColorByStatus(status)?.bg,
          padding: "4px 12px",
          color: getColorByStatus(status)?.color,
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "16px",
          fontFamily: "Roboto",
          borderRadius: "0.25rem",
          whiteSpace: "nowrap",
        }}
      >
        {status}
      </Box>
    );
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      <CustomTable
        loading={loading}
        tableHeaderCells={tableHeaderCells()}
        tableBodyContent={tableBodyContent()}
        tableData={data}
        sortRows={() => {}}
        tableBodyRowStyleProps={tableBodyRowStyleProps}
        tableHeaderCellStyleProps={tableHeaderCellStyleProps}
        limit={tableParams?.pagination?.limit}
        page={tableParams?.pagination?.page}
        totalPages={totalPages}
        totalDocuments={totalDocuments}
        setTableParams={setTableParams}
        handleChangePage={handleChangePage}
        // handleRowsChange={handleRowsChange}
        rowsPerPageOptions={getPaginationOptions(
          totalDocuments,
          tableParams?.pagination?.page,
          tableParams?.pagination?.limit
        )}
      />
    </Box>
  );
}
