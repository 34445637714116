import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assessmentId: null,
  skills: [
    {
      skill: {},
      mcqQuestions: {
        easyQuestions: [],
        mediumQuestions: [],
        hardQuestions: [],
      },
      programmingQuestions: {
        easyQuestions: [],
        mediumQuestions: [],
        hardQuestions: [],
      },
    },
  ],
};

const assessmentQuestionSlice = createSlice({
  name: "assessmentQuestion",
  initialState,
  reducers: {
    setAssessmentQuestions: (state, action) => {
      console.log("action.payload", action.payload);

      Object.assign(state, action.payload);
    },
    setAssessmentId: (state, action) => {
      state.assessmentId = action.payload;
    },
    addSkill: (state, action) => {
      state.skills.push({
        skill: action.payload.skill,
        mcqQuestions: {
          easyQuestions: [],
          mediumQuestions: [],
          hardQuestions: [],
        },
        programmingQuestions: {
          easyQuestions: [],
          mediumQuestions: [],
          hardQuestions: [],
        },
      });
    },
    updateSkill: (state, action) => {
      const { index, skill } = action.payload;
      if (state.skills[index]) {
        state.skills[index].skill = skill;
      }
    },
    addMcqQuestion: (state, action) => {
      const { skillIndex, difficulty, question } = action.payload;
      if (state.skills[skillIndex]) {
        state.skills[skillIndex].mcqQuestions[`${difficulty}Questions`].push(
          question
        );
      }
    },
    addProgrammingQuestion: (state, action) => {
      const { skillIndex, difficulty, question } = action.payload;
      if (state.skills[skillIndex]) {
        state.skills[skillIndex].programmingQuestions[
          `${difficulty}Questions`
        ].push(question);
      }
    },
    resetAssessmentQuestions: () => initialState,
  },
});

export const {
  setAssessmentQuestions,
  setAssessmentId,
  addSkill,
  updateSkill,
  addMcqQuestion,
  addProgrammingQuestion,
  resetAssessmentQuestions,
} = assessmentQuestionSlice.actions;

export default assessmentQuestionSlice.reducer;
