// utils.js

/**
 * Timeout ID for debouncing the search input.
 * @type {number | null}
 */
let timeoutId = null;

/**
 * Handles the change event for a table search input with debouncing.
 * Updates the search value and table parameters after a delay.
 *
 * @param {Event} event - The change event from the search input.
 * @param {Function} setSearchValue - Function to update the search value state.
 * @param {Function} setTableParams - Function to update the table parameters state.
 * @param {Object} tableParams - Current table parameters.
 * @param {Object} tableParams.filters - Current filters applied to the table.
 * @param {Object} tableParams.pagination - Current pagination settings of the table.
 */
export const handleTableSearchChange = (
  event,
  setSearchValue,
  setTableParams,
  tableParams
) => {
  const value = event.target.value;
  if (setSearchValue) setSearchValue(value);

  // Clear the timeout if it's already set.
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  // Set the timeout.
  timeoutId = setTimeout(() => {
    setTableParams({
      filters: {
        ...tableParams.filters,
        query: value,
      },
      pagination: {
        ...tableParams.pagination,
        page: 1,
      },
    });
  }, 500);
};

/**
 * Handles the change event for a search input with debouncing.
 * Updates the search value after a delay.
 *
 * @param {Event} event - The change event from the search input.
 * @param {Function} setSearchValue - Function to update the search value state.
 */
export const handleSearchChange = (event, setSearchValue, fetchData) => {
  const value = event.target.value;

  if (setSearchValue) setSearchValue(value);

  // Clear the timeout if it's already set.
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  // Set the timeout.
  timeoutId = setTimeout(() => {
    if (value !== "") {
      fetchData(value);
    }
  }, 500);
};
