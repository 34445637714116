import React, { useRef, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    marginBottom: "16px",
  },
  selectBox: {
    width: "100%",
    "& span": {
      color: "red",
    },
  },
  centeredLabel: {
    textAlign: "center",
  },
}));

const CustomSelect = ({
  title,
  options,
  onChange,
  name,
  helperText,
  error,
  value,
  multiple,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const inputLabelRef = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  const optionsLength = options.length;

  return (
    <Box className={classes.main}>
      <FormControl fullWidth size="small" error={error} disabled={disabled}>
        <InputLabel
          id="demo-simple-select-label"
          className={classes.centeredLabel}
        >
          {title}
        </InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          className={classes.selectBox}
          name={name}
          open={open && !disabled}
          value={value}
          onClose={() => setOpen(false)}
          onOpen={() => !disabled && setOpen(true)}
          onChange={(e) => onChange(e.target.value)}
          label={title}
          inputProps={{ "aria-label": "Without label" }}
          multiple={multiple}
          IconComponent={({ onClick }) =>
            !disabled ? (
              <Box
                component={"img"}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                  if (onClick) onClick(e);
                }}
                sx={{
                  rotate: open ? "180deg" : "0deg",
                  marginRight: "12px",
                  cursor: "pointer",
                }}
                src={"/assets/icons/chevronDown.svg"}
              />
            ) : null
          }
          {...props}
          MenuProps={{
            PaperProps: {
              style: {
                width: labelWidth,
                maxHeight: optionsLength > 6 ? 48 * 6 : "auto",
                overflowY: optionsLength > 6 ? "auto" : "visible",
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{ width: labelWidth }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default CustomSelect;
