import { Box, Typography } from "@mui/material";
import React from "react";
import CustomTable from "../common/customTable/CustomTable";
import DifficultyIcon from "./DifficultyIcon";
import { question_table_two } from "../../utils/data";
import { object } from "prop-types";
const columnsWidth = ["60px", "244px", "244px", "320px", "244px"];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const tableBodyRowStyleProps = {
  height: "48px",
};

const SkillsTable = ({ data ,  topic}) => {
  const tableHeaderTitles = [
    {
      title: "Sr. no",
      cellStyleProps: {
        borderRight: "1px solid #E8E9EE",
        width: columnsWidth[0],
      },
    },
    {
      title: "Skill Name",
      cellStyleProps: {
        width: columnsWidth[1],
      },
    },
    {
      title: `Questions (${data
        ?.map((total) => total.mcq.total + total.programming.total)
        .reduce((acc, total) => acc + total, 0)})`,
      cellStyleProps: {
        width: columnsWidth[2],
      },
    },
    {
      title: "Section",
      cellStyleProps: {
        width: columnsWidth[3],
      },
    },
    {
      title: "Score",
      cellStyleProps: {
        width: columnsWidth[4],
      },
    },
  ];

  const tableHeaderCells = () => {
    return tableHeaderTitles.map((item) => {
      return {
        element: item.title,
        cellStyleProps: item.cellStyleProps,
        isSortable: item?.isSortable ? item.isSortable : false,
        cellId: item?.cellId ? item.cellId : "",
      };
    });
  };

  const tableBodyRowCell = (row, idx, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          borderRight: "1px solid #E8E9EE",
          ...cellStyleProps,
        },
        element: (
          <Typography sx={tableContentCellTextStyleProps}>
            {idx + 1}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Typography sx={{ ...tableContentCellTextStyleProps, textTransform: "capitalize" }}>
            {row?.skill?.name}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Typography sx={tableContentCellTextStyleProps}>
            {row?.mcq?.total + row?.programming?.total}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px", textTransform: "capitalize" }}>
            {Object?.keys(row)?.map((key) => {
              if (key === "mcq" || key === "programming") {
                return (
                  <Typography
                    key={key}
                    sx={{
                      display: "inline-block",
                      background: "#D5F9F1",
                      padding: "2px 8px",
                      textAlign: "center",
                      color: "#004E3E",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "16px",
                      fontFamily: "Roboto",
                      borderRadius: "0.25rem",
                      whiteSpace: "nowrap",
                      height: "20px",
                    }}
                  >
                    {`${key} (${row[key]?.total})`}
                  </Typography>
                );
              }
            })}
          </Box>
        ),
      },
      {
        cellStyleProps: {
          ...cellStyleProps,
        },
        element: (
          <Typography sx={tableContentCellTextStyleProps}>
            {row?.mcq?.totalScore + row?.programming?.totalScore}
          </Typography>
        ),
      },
    ];
  };
console.log(data, topic, 163)
  const tableBodyContent = () => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };

    // Filter out rows where the skill name matches the topic
    const filteredData = data?.filter(row => {
      const totalQuestions = row?.mcq?.total + row?.programming?.total;
      return totalQuestions > 0 && (topic === "all" || row?.skill?.name === topic);
    });

    return filteredData?.map((row, idx) => {
      return {
        rowElement: tableBodyRowCell(row, idx, cellStyleProps),
      };
    });
  };

  const getDifficultyTag = (color, bg, text) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: bg,
          padding: "2px 8px",
          color: color,
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "16px",
          fontFamily: "Roboto",
          borderRadius: "0.25rem",
          whiteSpace: "nowrap",
          height: "20px",
        }}
      >
        <DifficultyIcon color={color} />
        {text}
      </Box>
    );
  };

  return (
    <CustomTable
      tableHeaderCells={tableHeaderCells()}
      tableBodyContent={tableBodyContent()}
      tableData={data}
      sortRows={() => {}}
      tableBodyRowStyleProps={tableBodyRowStyleProps}
      tableHeaderCellStyleProps={tableHeaderCellStyleProps}
      isPaginationVisible={false}
    />
  );
};


export default SkillsTable;
