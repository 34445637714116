import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";

const CustomTable = ({
  tableContainerStyleProps,
  tableStyleProps,
  tableHeaderStyleProps,
  tableHeaderCells,
  tableBodyStyleProps,
  tableBodyContent,
  tableBodyRowStyleProps,
  tableHeaderAlign = "left",
  isPaginationVisible = true,
  tableHeaderCellStyleProps = {},
  totalDocuments,
  limit,
  page,
  handleChangePage,
  handleRowsChange,
  rowsPerPageOptions,
  hideFooter,
  loading,
}) => {
  const [renderedComponents, setRenderedComponents] = useState([]);

  const sortData = (key) => {
    if (renderedComponents?.length > 0 && renderedComponents[0]?.sortable) {
      let sortedData = [...tableBodyContent];

      if (key === "candidate") {
        sortedData.sort((a, b) => {
          let nameA = a.sortable[key].toUpperCase();
          let nameB = b.sortable[key].toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } else {
        sortedData.sort((a, b) => a.sortable[key] - b.sortable[key]);
      }
      setRenderedComponents(sortedData);
    }
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      {/* table */}
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          "&::-webkit-scrollbar": {
            height: "4px",
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#E8E9EE",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#FF6812",
            borderRadius: "8px",
            width: "1px",
          },
          paddingY: "1rem",
          ...tableContainerStyleProps,
        }}
      >
        <Table
          sx={{
            // minWidth: 650,
            borderRadius: "0.25rem",
            border: "1px solid #E8E9EE",
            scrollbarColor: "#FF6812",
            ...tableStyleProps,
          }}
          size="small"
        >
          {/* table header */}
          <TableHead
            sx={{
              background: "#F2F4F8",
              color: "#363E45",
              lineHeight: "16px",
              fontWeight: "600",
              fontSize: "12px",
              padding: "8px 16px",
              borderBottom: "1px solid #E8E9EE",
              ...tableHeaderStyleProps,
            }}
          >
            <TableRow>
              {tableHeaderCells.map((cell, idx) => {
                return (
                  <TableCell
                    key={idx}
                    align={tableHeaderAlign}
                    sx={{
                      ...tableHeaderCellStyleProps,
                      ...cell?.cellStyleProps,
                      cursor: cell.isSortCol ? "pointer" : "default",
                    }}
                    onClick={() => {
                      if (cell.isSortCol) {
                        sortData(cell.colId);
                      }
                    }}
                  >
                    {cell.element}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {/* table body */}
          <TableBody sx={{ ...tableBodyStyleProps }}>
            {loading ? (
              [...Array(limit)].map((_, i) => (
                <TableRow key={i}>
                  {tableHeaderCells.map((cell, idx) => (
                    <TableCell key={idx}>
                      <Skeleton animation="wave" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : tableBodyContent?.length > 0 ? (
              tableBodyContent?.map((tableBodyRow, idx) => (
                <TableRow
                  key={idx}
                  sx={{
                    borderBottom: "1px solid #E8E9EE",
                    ...tableBodyRowStyleProps,
                  }}
                >
                  {tableBodyRow?.rowElement?.map((tableBodyRowCell, idx) => (
                    <TableCell
                      key={idx}
                      sx={{ ...tableBodyRowCell?.cellStyleProps }}
                    >
                      {tableBodyRowCell.element}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell size="large" colSpan={8} align="center">
                  <Typography
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <img
                      src="/assets/NoData.svg"
                      alt="No data found"
                      style={{
                        height: "60px",
                        width: "auto",
                      }}
                    />
                    <p> No data found</p>
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* table pagination  */}
      {!hideFooter && isPaginationVisible && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalDocuments}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={(e, newPage) => {
            handleChangePage(newPage + 1);
          }}
          onRowsPerPageChange={handleRowsChange}
          className="custom-table-pagination"
        />
      )}
    </Box>
  );
};

export default CustomTable;
