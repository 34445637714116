import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: null,
  description: null,
  startsAt: null,
  endsAt: null,
  experienceId: null,
  jobRoleId: null,
  skillsId: [],
  testCreationType: "Auto Test",
  testInviteOnly: false,
  librarySelection: [],
  cutOff: null,
  testDuration: null,
  totalScore: null,
  totalNoOfQuestions: null,
  link: null,
  instruction: [],
  testType: "public",
  status: "Draft",
  testAdminsId: [],
  proctoringSettingsId: [],
  candidateFieldsId: [],
  html: null,
  difficultyLevels: [],
};

const assessmentSlice = createSlice({
  name: "Assessment",

  initialState,

  reducers: {
    setAssessment: (state, action) => {
      Object.assign(state, action.payload);
    },
    resetAssessment: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setAssessment, resetAssessment } = assessmentSlice.actions;
export default assessmentSlice.reducer;
