import { Box, capitalize, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TabsList from "../../components/candidateShortlisted/Tabs";
import { candidates_tabs_one, candidates_tabs_two } from "../../utils/data";

import TestTaken from "../../components/candidates/TestTaken";
import TestTakenGrid from "../../components/candidates/TestTakenGrid";

import Shortlisted from "../../components/candidates/Shortlisted";
import ShortlistedGrid from "../../components/candidates/ShortlistedGrid";
import CommonModal from "../../components/modal/CommonModal";

import Passed from "../../components/candidates/Passed";
import PassedGrid from "../../components/candidates/PassedGrid";

import Invited from "../../components/candidates/Invited";
import InvitedGrid from "../../components/candidates/InvitedGrid";

import Reviewed from "../../components/candidates/Reviewed";
import ReviewedGrid from "../../components/candidates/ReviewedGrid";

import TestTakenSearchHeader from "../../components/candidates/TestTakenSearchHeader";
import SearchHeader from "../../components/candidates/SearchHeader";
import { breadcrumbsContext } from "../../context/breadcrumbsContext";
import APICall from "../../utils/api";
import { AuthContext } from "../../context/authContext";
import { buildUrlWithFilters } from "../../utils/filter";
import { useMessage } from "../../components/snackbar/snackbar";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../../layouts/LoadingScreen";

const Candidates = ({ assessmentId, candidateStats, fetchAssessment }) => {
  const [loading, setLoading] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState("");
  const [openMultipleStatusModal, setOpenMultipleStatusModal] = useState(false);

  const [isStatusSuccess, setIsStatusSuccess] = useState(false);
  const [isMultipleStatusSuccess, setIsMultipleStatusSuccess] = useState(false);

  const [status, setStatus] = useState("");
  const [testScore, setTestScore] = useState("All");

  const message = useMessage();

  const { setBreadcrumbsData } = useContext(breadcrumbsContext);

  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [updatedStatusCount, setUpdatedStatusCount] = useState(0);

  const [totalPages, setTotalPages] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const location = useLocation();

  const [tableParams, setTableParams] = useState({
    filters: {
      query: "",
      currentStatus: "Appeared",
    },
    pagination: {
      page: 1,
      limit: 100,
    },
  });

  const handleStatusClick = (status, data = "") => {
    setStatus(status);
    updateStatus(status, data);
  };

  const updateStatus = async (status, data = "") => {
    setOpenStatusModal(false);
    const rowToUpdate = data || selectedRow;
    const updateData = {
      currentStatus: status,
    };
    try {
      const res = await APICall(
        `/candidateAssessment/${rowToUpdate?.candidateAssessmentId}`,
        updateData,
        "patch"
      );

      // setIsStatusSuccess(true);
      message("Status updated successfully !!", "success");
      fetchAssessment();
      fetchAssessmentDetails();
      setSelectAll(false);
      setSelectedCheckboxes([]);
    } catch (error) {
      message(error, "error");
    }
  };

  const updateMultipleStatus = async (selectedCheckboxes, status) => {
    setOpenMultipleStatusModal(false);
    const updateData = {
      candidateAssessmentIds: selectedCheckboxes,
      currentStatus: status,
    };
    console.log(updateData);
    try {
      const res = await APICall(
        `/candidateAssessment/UpdateManyStatus`,
        updateData,
        "post"
      );
      setUpdatedStatusCount(updateData.candidateAssessmentIds.length);
      fetchAssessment();
      message("Status updated successfully !!", "success");
      fetchAssessmentDetails();
      setSelectAll(false);
      setIsMultipleStatusSuccess(true);
      setSelectedCheckboxes([]);
    } catch (error) {
      message(error, "error");
    }
  };

  const updateManyStatus = (selectedCheckboxes, status) => {
    setSelectedCheckboxes(selectedCheckboxes);
    setStatus(status);
    setOpenMultipleStatusModal(true);
  };

  const [candidateAssessmentDetails, setCandidateAssessmentDetails] = useState(
    []
  );

  const { authUser } = useContext(AuthContext);

  const [gridView, setGridView] = useState(true);

  const fetchAssessmentDetails = async () => {
    try {
      setLoading(true);
      let url = `/candidateAssessment?assessmentId=${assessmentId}&page=${tableParams.pagination.page}&limit=${tableParams.pagination.limit}`;
      url += buildUrlWithFilters(tableParams);
      if (testScore && testScore !== "All") {
        url += `&scoreFilter=${testScore}`;
      }
      const res = await APICall(url);
      const filteredPending = res.data.documents.filter(
        (item) => item.currentStatus === "Appeared"
      );
      setTotalPending(filteredPending.length || 0);
      setCandidateAssessmentDetails(res.data.documents);
      setTotalDocuments(res.data.total);
      setTotalPages(Math.ceil(res.data.total / tableParams.pagination.limit));
    } catch (error) {
      console.error("Failed to fetch assessment details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectAll && currentTab) {
      const allIds = candidateAssessmentDetails.map(
        (row) => row.candidateAssessmentId
      );
      setSelectedCheckboxes(allIds);
    }
  }, [selectAll, candidateAssessmentDetails, setSelectedCheckboxes]);

  const handleCheckboxChange = (rowId) => {
    setSelectedCheckboxes((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(rowId);
      let updatedSelected;

      if (isAlreadySelected) {
        // If unchecking an already selected checkbox
        updatedSelected = prevSelected.filter((id) => id !== rowId);

        // Uncheck "Select All" since not all checkboxes are selected
        if (currentTab !== "passed") setSelectAll(false);
      } else {
        // If selecting a new checkbox
        updatedSelected = [...prevSelected, rowId];
      }

      // If all checkboxes are selected, "Select All" should be checked
      if (updatedSelected.length === candidateAssessmentDetails.length) {
        setSelectAll(true);
      }

      return updatedSelected;
    });
  };

  let timeoutId = null;
  const handleChangePage = (newPage) => {
    setLoading(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          page: newPage,
        },
      });
    }, 500);
  };
  const handleRowsChange = (event) => {
    setLoading(true);
    const value = event?.target?.value;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          limit: value,
        },
      });
    }, 500);
  };

  const getPaginationOptions = (
    totalDocuments,
    currentPage,
    currentRowsPerPage
  ) => {
    const documentsViewed = (currentPage - 1) * currentRowsPerPage;
    const remainingDocuments = totalDocuments - documentsViewed;

    let baseOptions = [100];

    if (remainingDocuments >= 125) {
      baseOptions.push(125);
    }
    if (remainingDocuments >= 150) {
      baseOptions.push(150);
    }
    if (remainingDocuments >= 200) {
      baseOptions.push(200);
    }

    return baseOptions;
  };

  const candidateTabs = [
    { label: `Invited (${candidateStats?.invited})`, value: "invited" },
    { label: `Test Taken (${candidateStats?.appeared})`, value: "appeared" },
    { label: `Passed (${candidateStats?.passed})`, value: "passed" },

    { label: `Reviewed (${candidateStats?.reviewed})`, value: "reviewed" },

    {
      label: `Shortlisted (${candidateStats?.shortlisted})`,
      value: "shortlisted",
    },
  ];

  const [currentTab, setCurrentTab] = useState(candidateTabs[1].value);
  useEffect(() => {
    if (authUser?.role !== "super admin") {
      setGridView(true);
    } else {
      setGridView(false);
    }
  }, [authUser]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const shortlistQeryParams = queryParams.get("shortlist");
    if (shortlistQeryParams) {
      setCurrentTab("shortlisted");
    }
  }, [location]);

  useEffect(() => {
    const activeTab = candidateTabs.find((tab) => tab.value === currentTab);
    const tabLabelWithoutNumbersOrParentheses = activeTab.label.replace(
      /[0-9]|\(.*?\)/g,
      ""
    );

    setBreadcrumbsData((breadcrumbs) => [
      ...breadcrumbs.slice(0, 3),
      { name: tabLabelWithoutNumbersOrParentheses.trim() },
    ]);
  }, [currentTab, setBreadcrumbsData]);

  useEffect(() => {
    fetchAssessmentDetails();
  }, [assessmentId, tableParams]);

  useEffect(() => {
    fetchAssessmentDetails();
  }, [testScore]);

  const handleTabChange = (tabValue) => {
    const { isPending, ...restFilters } = tableParams.filters;
    setTableParams({
      ...tableParams,
      filters: {
        ...restFilters,
        currentStatus: tabValue !== "invited" && capitalize(tabValue),
        query: "",
      },
      pagination: {
        ...tableParams.pagination,
        page: 1,
      },
    });
    setCurrentTab(tabValue);
    setSelectedCheckboxes([]);
    setSelectAll(false);
    setTestScore("All");
  };
  useEffect(() => {
    setTestScore("All");
  }, [gridView]);

  return (
    <>
      <Box sx={{ width: "100%", background: "#fff" }}>
        {/* Tabs */}
        <TabsList
          handleTabChange={(newValue) => handleTabChange(newValue)}
          value={currentTab}
          tabs={candidateTabs}
          type={"secondary"}
        />
        {(authUser?.role === "super admin" || authUser?.role === "hr") &&
          (currentTab === "appeared" ? (
            <TestTakenSearchHeader
              tableParams={tableParams}
              setTableParams={setTableParams}
              totalDocuments={totalDocuments}
              totalPending={totalPending}
              assessmentId={assessmentId}
              fetchAssessment={fetchAssessment}
              fetchAssessmentDetails={fetchAssessmentDetails}
              data={candidateAssessmentDetails}
              updateManyStatus={updateManyStatus}
              setSelectedCheckboxes={setSelectedCheckboxes}
              selectedCheckboxes={selectedCheckboxes}
              currentTab={currentTab}
            />
          ) : (
            <SearchHeader
              tableParams={tableParams}
              setTableParams={setTableParams}
              totalDocuments={totalDocuments}
              assessmentId={assessmentId}
              fetchAssessment={fetchAssessment}
              setSelectedCheckboxes={setSelectedCheckboxes}
              selectedCheckboxes={selectedCheckboxes}
              data={candidateAssessmentDetails}
              updateManyStatus={updateManyStatus}
              totalPending={totalPending}
              fetchAssessmentDetails={fetchAssessmentDetails}
              setTestScore={setTestScore}
              loading={loading}
              setCandidateAssessmentDetails={setCandidateAssessmentDetails}
              setLoading={setLoading}
              testScore={testScore}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              currentTab={currentTab}
            />
          ))}

        {/* Search Header */}
        {authUser?.role === "recruiter" &&
          (currentTab === "appeared" ? (
            <TestTakenSearchHeader
              setGridView={setGridView}
              gridView={gridView}
              tableParams={tableParams}
              setTableParams={setTableParams}
              assessmentId={assessmentId}
              totalDocuments={totalDocuments}
              fetchAssessment={fetchAssessment}
              fetchAssessmentDetails={fetchAssessmentDetails}
              totalPending={totalPending}
              setSelectedCheckboxes={setSelectedCheckboxes}
              selectedCheckboxes={selectedCheckboxes}
              data={candidateAssessmentDetails}
              updateManyStatus={updateManyStatus}
              currentTab={currentTab}
            />
          ) : (
            <SearchHeader
              setGridView={setGridView}
              gridView={gridView}
              currentTab={currentTab}
              tableParams={tableParams}
              setTableParams={setTableParams}
              totalDocuments={totalDocuments}
              assessmentId={assessmentId}
              fetchAssessment={fetchAssessment}
              setSelectedCheckboxes={setSelectedCheckboxes}
              selectedCheckboxes={selectedCheckboxes}
              data={candidateAssessmentDetails}
              updateManyStatus={updateManyStatus}
              totalPending={totalPending}
              setTotalPending={setTotalPending}
              fetchAssessmentDetails={fetchAssessmentDetails}
              setTestScore={setTestScore}
              testScore={testScore}
              setLoading={setLoading}
              setCandidateAssessmentDetails={setCandidateAssessmentDetails}
              loading={loading}
              setTotalDocuments={setTotalDocuments}
              setTotalPages={setTotalPages}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
            />
          ))}

        {/* Content based on selected tab */}
        <Box sx={{ mb: "16px" }}>
          {candidateAssessmentDetails &&
          candidateAssessmentDetails.length > 0 ? (
            <>
              {currentTab === "invited" &&
                (!gridView ? (
                  <Invited
                    setLoading={setLoading}
                    loading={loading}
                    data={candidateAssessmentDetails}
                    totalDocuments={totalDocuments}
                    totalPages={totalPages}
                    tableParams={tableParams}
                    getPaginationOptions={getPaginationOptions}
                    setTableParams={setTableParams}
                  />
                ) : (
                  <InvitedGrid
                    setLoading={setLoading}
                    loading={loading}
                    data={candidateAssessmentDetails}
                    totalDocuments={totalDocuments}
                    totalPages={totalPages}
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    selectedCheckboxes={selectedCheckboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    handleChangePage={handleChangePage}
                    handleRowsChange={handleRowsChange}
                    currentTab={currentTab}
                    getPaginationOptions={getPaginationOptions}
                  />
                ))}
              {currentTab === "appeared" &&
                (!gridView ? (
                  <TestTaken
                    setLoading={setLoading}
                    loading={loading}
                    data={candidateAssessmentDetails}
                    totalDocuments={totalDocuments}
                    totalPages={totalPages}
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    updateStatus={updateStatus}
                    handleStatusClick={handleStatusClick}
                    handleCheckboxChange={handleCheckboxChange}
                    selectedCheckboxes={selectedCheckboxes}
                    getPaginationOptions={getPaginationOptions}
                  />
                ) : (
                  <TestTakenGrid
                    data={candidateAssessmentDetails}
                    tableParams={tableParams}
                    setLoading={setLoading}
                    totalDocuments={totalDocuments}
                    totalPages={totalPages}
                    loading={loading}
                    setTableParams={setTableParams}
                    handleCheckboxChange={handleCheckboxChange}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedRow={setSelectedRow}
                    handleChangePage={handleChangePage}
                    handleRowsChange={handleRowsChange}
                    handleStatusClick={handleStatusClick}
                    currentTab={currentTab}
                    getPaginationOptions={getPaginationOptions}
                  />
                ))}
              {currentTab === "passed" &&
                (!gridView ? (
                  <Passed
                    setLoading={setLoading}
                    loading={loading}
                    data={candidateAssessmentDetails}
                    totalDocuments={totalDocuments}
                    totalPages={totalPages}
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    selectedCheckboxes={selectedCheckboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    handleStatusClick={handleStatusClick}
                    setSelectedRow={setSelectedRow}
                    selectedRow={selectedRow}
                    getPaginationOptions={getPaginationOptions}
                  />
                ) : (
                  <PassedGrid
                    setLoading={setLoading}
                    loading={loading}
                    data={candidateAssessmentDetails}
                    totalDocuments={totalDocuments}
                    totalPages={totalPages}
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    selectedCheckboxes={selectedCheckboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    handleChangePage={handleChangePage}
                    handleRowsChange={handleRowsChange}
                    currentTab={currentTab}
                    setSelectedRow={setSelectedRow}
                    selectedRow={selectedRow}
                    handleStatusClick={handleStatusClick}
                    getPaginationOptions={getPaginationOptions}
                  />
                ))}
              {currentTab === "reviewed" &&
                (!gridView ? (
                  <Reviewed
                    setLoading={setLoading}
                    loading={loading}
                    data={candidateAssessmentDetails}
                    totalDocuments={totalDocuments}
                    totalPages={totalPages}
                    tableParams={tableParams}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    setTableParams={setTableParams}
                    handleStatusClick={handleStatusClick}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    handleChangePage={handleChangePage}
                    getPaginationOptions={getPaginationOptions}
                  />
                ) : (
                  <ReviewedGrid
                    setLoading={setLoading}
                    loading={loading}
                    data={candidateAssessmentDetails}
                    totalDocuments={totalDocuments}
                    totalPages={totalPages}
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    handleStatusClick={handleStatusClick}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    currentTab={currentTab}
                    handleChangePage={handleChangePage}
                    getPaginationOptions={getPaginationOptions}
                  />
                ))}
              {currentTab === "shortlisted" &&
                (!gridView ? (
                  <Shortlisted
                    setLoading={setLoading}
                    loading={loading}
                    data={candidateAssessmentDetails}
                    totalDocuments={totalDocuments}
                    totalPages={totalPages}
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    updateStatus={updateStatus}
                    handleStatusClick={handleStatusClick}
                    selectedCheckboxes={selectedCheckboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    getPaginationOptions={getPaginationOptions}
                  />
                ) : (
                  <ShortlistedGrid
                    setLoading={setLoading}
                    loading={loading}
                    data={candidateAssessmentDetails}
                    totalDocuments={totalDocuments}
                    totalPages={totalPages}
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    updateStatus={updateStatus}
                    handleStatusClick={handleStatusClick}
                    selectedCheckboxes={selectedCheckboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    currentTab={currentTab}
                    getPaginationOptions={getPaginationOptions}
                  />
                ))}
            </>
          ) : (
            // Render fallback message if data is empty or missing
            <Typography
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                marginTop: "20px",
              }}
            >
              <img
                src="/assets/NoData.svg"
                alt="No data found"
                style={{
                  height: "60px",
                  width: "auto",
                }}
              />
              No data found
            </Typography>
          )}
        </Box>
      </Box>
      <CommonModal
        icon={"/assets/users.svg"}
        handleClose={() => {
          setOpenStatusModal(false);
        }}
        open={openStatusModal}
        title={"Change Status"}
        sendButtonName={"Confirm"}
        onSubmit={() => {
          updateStatus(selectedRow);
        }}
      >
        <Typography
          style={{
            backgroundColor: "#fff0e7",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <img
            src="/assets/alert-1.svg"
            alt="Alert"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          />
          <strong>
            {`Are you certain you wish to ${status} for this Candidate?`}
          </strong>
          <br />
        </Typography>
      </CommonModal>
      <CommonModal
        icon={"/assets/users.svg"}
        handleClose={() => {
          setOpenMultipleStatusModal(false);
        }}
        open={openMultipleStatusModal}
        title={"Change Status"}
        sendButtonName={"Confirm"}
        onSubmit={() => {
          updateMultipleStatus(selectedCheckboxes, status);
        }}
      >
        <Typography
          style={{
            backgroundColor: "#fff0e7",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <img
            src="/assets/alert-1.svg"
            alt="Alert"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          />
          <strong>
            {status === "Shortlisted"
              ? `Do you want to Shortlist selected ${
                  currentTab === "passed"
                    ? selectedCheckboxes.length
                    : selectAll
                    ? totalDocuments
                    : selectedCheckboxes.length
                } Candidates?`
              : status === "Reviewed"
              ? `Do you want to Move selected ${
                  currentTab === "passed"
                    ? selectedCheckboxes.length
                    : selectAll
                    ? totalDocuments
                    : selectedCheckboxes.length
                } Candidates to Review?`
              : status === "Appeared"
              ? `Do you want to Move selected ${
                  currentTab === "Passed"
                    ? selectedCheckboxes.length
                    : selectAll
                    ? totalDocuments
                    : selectedCheckboxes.length
                } Candidates to Passed?`
              : `Are you certain you wish to ${status} for selected ${
                  currentTab === "Passed"
                    ? selectedCheckboxes.length
                    : selectAll
                    ? totalDocuments
                    : selectedCheckboxes.length
                } Candidates?`}
          </strong>
          <br />
        </Typography>
      </CommonModal>
      <CommonModal
        icon={"/assets/success.svg"}
        handleClose={() => {
          setIsMultipleStatusSuccess(false);
        }}
        open={isMultipleStatusSuccess}
        title={"Candidate Status Changed"}
        cancelButtonName={"Close"}
        hideSubmit={true}
        onSubmit={() => {
          setIsMultipleStatusSuccess(false);
        }}
      >
        <Typography
          style={{
            backgroundColor: "#edf7ed",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <img
            src="/assets/success.svg"
            alt="Alert"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          />
          <strong>
            {status === "Appeared"
              ? `Selected ${updatedStatusCount} Candidates were Moved to Passed successfully`
              : `Selected ${updatedStatusCount} Candidates were ${status} successfully`}
          </strong>
          <br />
        </Typography>
      </CommonModal>
      <CommonModal
        icon={"/assets/success.svg"}
        handleClose={() => {
          setIsStatusSuccess(false);
        }}
        open={isStatusSuccess}
        title={"Candidate Status Changed"}
        cancelButtonName={"Close"}
        hideSubmit={true}
        onSubmit={() => {
          setIsStatusSuccess(false);
        }}
      >
        <Typography
          style={{
            backgroundColor: "#edf7ed",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <img
            src="/assets/success.svg"
            alt="Alert"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          />
          <strong>Status for this Candidate has been updated.</strong>
          <br />
        </Typography>
      </CommonModal>
      {loading && (
        <>
          <LoadingScreen />
        </>
      )}
    </>
  );
};

export default Candidates;
