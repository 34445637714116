import { SvgIcon } from "@mui/material";
import React from "react";

const CircleCheck = ({ fill = "#71777B" }) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0003 18.085C8.40149 18.085 6.83862 17.6109 5.5093 16.7227C4.17997 15.8344 3.14389 14.572 2.53207 13.0949C1.92025 11.6178 1.76017 9.9925 2.07207 8.42446C2.38398 6.85641 3.15386 5.41607 4.28435 4.28557C5.41485 3.15508 6.85519 2.3852 8.42324 2.07329C9.99128 1.76139 11.6166 1.92147 13.0937 2.53329C14.5707 3.14511 15.8332 4.1812 16.7214 5.51052C17.6097 6.83984 18.0837 8.40271 18.0837 10.0015C18.0813 12.1446 17.2289 14.1992 15.7134 15.7147C14.198 17.2301 12.1434 18.0825 10.0003 18.085ZM10.0003 3.08497C8.6323 3.08497 7.29506 3.49062 6.15765 4.25061C5.02024 5.01061 4.13373 6.09082 3.61024 7.35464C3.08675 8.61847 2.94978 10.0091 3.21665 11.3508C3.48352 12.6925 4.14226 13.9249 5.10955 14.8922C6.07684 15.8595 7.30924 16.5182 8.65091 16.7851C9.99258 17.0519 11.3833 16.915 12.6471 16.3915C13.9109 15.868 14.9911 14.9815 15.7511 13.8441C16.5111 12.7067 16.9168 11.3694 16.9168 10.0015C16.9147 8.16773 16.1854 6.40967 14.8887 5.11301C13.5921 3.81635 11.834 3.087 10.0003 3.08497Z"
        fill={fill}
      />
      <path
        d="M9.16692 12.2518C9.09028 12.2519 9.01437 12.2368 8.94356 12.2075C8.87275 12.1782 8.80844 12.1351 8.75432 12.0809L7.08765 10.4142C6.97888 10.3046 6.91796 10.1564 6.91824 10.002C6.91852 9.84766 6.97997 9.69967 7.08914 9.5905C7.19831 9.48133 7.3463 9.41988 7.50069 9.4196C7.65508 9.41932 7.80329 9.48024 7.91285 9.58901L9.16692 10.8435L12.0877 7.92234C12.1972 7.81357 12.3454 7.75265 12.4998 7.75293C12.6542 7.75321 12.8022 7.81466 12.9114 7.92383C13.0205 8.033 13.082 8.18099 13.0823 8.33538C13.0825 8.48977 13.0216 8.63798 12.9129 8.74754L9.57952 12.0809C9.52539 12.1351 9.46108 12.1782 9.39027 12.2075C9.31946 12.2368 9.24356 12.2519 9.16692 12.2518Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default CircleCheck;
