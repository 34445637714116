import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  labelText: {
    "& .MuiListItemText-root": {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
      fontFamily: "Roboto",
      color: "#535A5F",
    },
  },
}));
const guidelineRules = [
  { id: "1", primary: "Ensure data is accurately filled in each column." },
  { id: "2", primary: "Do not include any additional sheets or columns." },
  {
    id: "3",
    primary:
      "Check for proper formatting, especially for date and numerical fields.",
  },
  { id: "4", primary: "Save the file in .xlsx format before uploading." },
  { id: "5", primary: "Double-check for errors before submitting." },
  {
    id: "6",
    primary:
      "Contact support if you encounter any issues during the upload process.",
  },
];
function Guidelines() {
  const classes = useStyles();
  return (
    <Box>
      <Stack spacing={1} direction={"row"} alignItems={"center"}>
        {/* <Box
          component={Icon}
          icon={"/assets/icons/guideline.svg"}
          color={"orangeButton"}
          sx={{
            width: "16.17px",
            height: "16.17px",
            color: theme.palette.orangeButton.main,
            strokeWidth: "0.5",
          
          }}
        /> */}
        <Box
          width={"16.17px"}
          height={"16.17px"}
          component={"img"}
          src={"/assets/icons/guideline.svg"}
        />
        <Typography
          sx={{
            fontSize: "16px",
            color: "primary.black",
            fontWeight: 600,
            fontFamily: "Nunito",
            lineHeight: "24px",
          }}
        >
          Guidelines:
        </Typography>
      </Stack>
      <List>
        {guidelineRules.map((rules) => (
          <ListItem
            sx={{ padding: "0" }}
            key={rules.id}
            className={classes.labelText}
          >
            <ListItemText
              disableTypography
              primary={`${rules.id}. ${rules.primary}`}
              sx={{ margin: "0" }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default Guidelines;
