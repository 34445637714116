import React, {
  createContext,
  useState,
  useContext,
  useEffect,
} from "react";

const NotificationContext = createContext();

export function NotificationProvider({ children }) {
  const [notificationCounts, setNotificationCounts] = useState(0);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data && event.data.type === "NEW_NOTIFICATION") {
          setNotificationCounts((prevCount) => prevCount + 1);
        }
      });
    }
  }, []);
  return (
    <NotificationContext.Provider value={{ notificationCounts, setNotificationCounts }}>
      {children}
    </NotificationContext.Provider>
  );
}

export const useNotification = () => useContext(NotificationContext);
