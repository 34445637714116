import React, { useContext, useEffect, useState } from "react";
import { breadcrumbsContext } from "../../context/breadcrumbsContext";
import { PATH_DASHBOARD } from "../../routes/paths";
import {
  Box,
  Stack,
  Button,
  IconButton,
  Grid,
  Tabs,
  Tab,
  capitalize,
  Tooltip,
  TablePagination,
  Typography,
  Chip,
  Badge,
  ButtonGroup,
} from "@mui/material";
import SearchBar from "../../components/common/SearchBar";
import Iconify from "../../components/iconify/Iconify";
import ReadymadeTestCard from "../../components/common/ReadymadeTestCard";
import { makeStyles, useTheme } from "@mui/styles";
import { AuthContext } from "../../context/authContext";
import TabsList from "../../components/candidateShortlisted/Tabs";
import APICall from "../../utils/api";
import {
  handleApplyFilter,
  countCheckedFilters,
  resetAllFilters,
  renderFilterTooltipContent,
} from "../../utils/filter";
import AssesmentFilter from "../../components/assesmentPage/AssesmentFilter";
import { handleTableSearchChange } from "../../utils/search";
import {
  getPaginationOptions,
  handleChangePage,
  handleChangeRowsPerPage,
} from "../../utils/pagination";
import { getDifficultSettingsByLevel } from "../../utils";
import LoadingScreen from "../../layouts/LoadingScreen";

const useStyles = makeStyles((theme) => ({
  tabHeader: {
    marginBottom: "16px",
    "& button[aria-selected='true']": {
      color: theme.palette.green.secondaryGreen,
    },
    "& .MuiTabs-indicator": {
      background: theme.palette.green.secondaryGreen,
    },
  },
  tableCard: {
    borderRadius: "4px",
    padding: "16px",
    background: theme.palette.background.default,
    width: "100%",
  },
}));

const TemplateLibrary = () => {
  const [value, setTabValue] = useState("all");
  const { authUser } = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const { setBreadcrumbsData } = useContext(breadcrumbsContext);
  const theme = useTheme();
  const [currentTabIndex, setCurrentTabIndex] = useState(-1);
  const [filterLoading, setFilterLoading] = useState(false);
  const [jobtabList, setJobTablist] = useState([]);
  const [difficultyLevels, setDifficultyLevels] = useState([]);
  const [templates, setTemplate] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchedSkill, setSearchedSkill] = useState("");
  const [searchJobRoles, setSearchJobRoles] = useState("");
  const [openAccordion, setOpenAccordion] = useState(null);

  const [mediumCount, setMedium] = useState(0);
  const [hardCount, setHard] = useState(0);
  const [easyCount, setEasy] = useState(0);
  const filterOptions = [
    {
      label: "Skills",
      value: "skills",
      isSearchBar: true,
      searchValue: "",
      total: 0,
      onSearch: function (query) {
        setSearchedSkill(query);
      },
      checkBox: [
        {
          label: "Select All",
          value: "Select All",
          isChecked: false,
        },
      ],
      onCheckChange: function (selectedSkills) {
        if (selectedSkills.length === 0) return;
        const topics = new Set();
        selectedSkills.forEach((skill) => {
          skill?.topics?.forEach((topic) => topics.add(topic));
        });
      },
    },
    {
      label: "Job Roles",
      value: "jobRoles",
      isSearchBar: true,
      searchValue: "",
      total: 0,
      onSearch: function (query) {
        setSearchJobRoles(query);
      },
      checkBox: [
        {
          label: "Select All",
          value: "Select All",
          isChecked: false,
        },
      ],
      onCheckChange: function (selectedJobRoles) {
        if (selectedJobRoles.length === 0) return;
        const jobs = new Set();
        selectedJobRoles.forEach((job) => {
          jobs.add(job);
        });
      },
    },
  ];

  const tableFilters = {
    query: "",
    skills: [],
    jobRoles: [],
  };

  const [tableParams, setTableParams] = useState({
    filters: tableFilters,
    pagination: {
      page: 1,
      limit: 10,
    },
  });
  const [filterCount, setFilterCount] = useState(0);

  const resetFilters = () => {
    resetAllFilters(setTableParams, filters, handleFilterClose);
    setSearchJobRoles("");
    setSearchedSkill("");
    fetchJobRoles();
    fetchSkills();
  };

  const fetchTemplates = async () => {
    try {
      setFilterLoading(true);
      let url = `/assessment/template?page=${tableParams.pagination.page}&limit=${tableParams.pagination.limit}`;

      const filterKeys = Object.keys(tableParams.filters);
      filterKeys.forEach((key) => {
        if (typeof tableParams.filters[key] === "string") {
          if (tableParams.filters[key].length > 0) {
            url += `&${key}=${encodeURIComponent(tableParams.filters[key])}`;
          }
        } else if (
          Array.isArray(tableParams.filters[key]) &&
          tableParams.filters[key].length > 0
        ) {
          url += `&${key}=${encodeURIComponent(
            tableParams.filters[key].join(",")
          )}`;
        }
      });

      // if (value && value !== "all") {
      //   url += `&jobRoleId=${value}`; // Apply selected job role filter
      // }

      const difficultyLevelsMap = ["Easy", "Medium", "Hard"];
      const selectedDifficultyLevel =
        currentTabIndex !== -1 ? difficultyLevelsMap[currentTabIndex] : null;
      if (selectedDifficultyLevel) {
        url += `&difficultyLevels=${selectedDifficultyLevel}`;
      }

      if (isFilterOpen && filters.length > 0) {
        filters.forEach((filter) => {
          const selectedFilterValues = filter.checkBox
            .filter((cb) => cb.isChecked && cb.label !== "Select All")
            .map((cb) => cb.value);

          if (selectedFilterValues.length > 0) {
            url += `&${filter.value}=${selectedFilterValues.join(",")}`;
          }
        });
      }

      const response = await APICall(url);
      setTotalDocuments(response.data.total);
      setTemplate(response.data.documents);
    } catch (error) {
      console.error("Failed to fetch Templates:", error);
    } finally {
      setFilterLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, [currentTabIndex, value, tableParams]);

  const templateLibraryStats = async () => {
    try {
      let url = `/stats/templates/LibraryStats`;
      const filterKeys = Object.keys(tableParams.filters);
      filterKeys.forEach((key) => {
        const filterValue = tableParams.filters[key];

        if (typeof filterValue === "string" && filterValue.length > 0) {
          url += `&${key}=${encodeURIComponent(filterValue)}`;
        } else if (Array.isArray(filterValue) && filterValue.length > 0) {
          url += `&${key}=${encodeURIComponent(filterValue.join(","))}`;
        }
      });

      if (url.includes("&")) {
        url = url.replace("&", "?");
      }

      const response = await APICall(url);

      if (response?.data) {
        const { easy, medium, hard } = response.data;
        setEasy(easy || 0);
        setMedium(medium || 0);
        setHard(hard || 0);
      }

      console.log(response.data, 213);
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };
  useEffect(() => {
    templateLibraryStats();
  }, [tableParams]);

  console.log(tableParams, 235);

  const fetchSkills = async (query, keepSlected = true) => {
    let limit = query ? 1000 : 10;

    let url = `/skill?page=1&limit=${limit}`;
    if (query) {
      url += `&query=${query}`;
    }
    try {
      const response = await APICall(url);
      const temp = [];
      filters.forEach((filter) => {
        if (filter.label === "Skills") {
          if (keepSlected) {
            const filteredCheckBoxes = filter.checkBox.filter(
              (cb) => cb.label !== "Select All" && cb.isChecked
            );
            temp.push(...filteredCheckBoxes);
          }
        }
      });
      response?.data?.documents?.forEach((skill) =>
        temp.push({
          label: capitalize(skill.name),
          value: skill._id,
          isChecked: false,
          topics: skill.topics,
        })
      );

      setFilters((prevFilters) => {
        return prevFilters?.map((filter) => {
          if (filter.label === "Skills") {
            return {
              ...filter,
              total: response.data.total,
              checkBox: [
                {
                  label: "Select All",
                  value: "Select All",
                  isChecked: false,
                },
                ...temp,
              ],
            };
          }
          return filter;
        });
      });
    } catch (error) {
      console.error("Failed to fetch skills:", error);
    }
  };

  const fetchDifficultyLevels = async () => {
    try {
      let url = `/difficultyLevel`;
      const response = await APICall(url);
      const temp = {
        easy: null,
        medium: null,
        hard: null,
      };

      response?.data?.documents?.forEach((difficultyLevel) => {
        let imgSrc;

        switch (difficultyLevel.level.toLowerCase()) {
          case "easy":
            imgSrc = "/assets/icons/greenFireEmpty.svg";
            break;
          case "medium":
            imgSrc = "/assets/icons/orangeFireEmpty.svg";
            break;
          case "hard":
            imgSrc = "/assets/icons/redFireEmpty.svg";
            break;
          default:
            imgSrc = "/assets/icons/defaultIcon.svg";
            break;
        }

        temp[difficultyLevel.level.toLowerCase()] = {
          label: capitalize(difficultyLevel.level),
          value: difficultyLevel._id,
          imgSrc: imgSrc,
        };
      });

      const orderedTemp = [];
      if (temp.easy) orderedTemp.push(temp.easy);
      if (temp.medium) orderedTemp.push(temp.medium);
      if (temp.hard) orderedTemp.push(temp.hard);

      setDifficultyLevels(orderedTemp);
    } catch (error) {
      console.error("Failed to fetch difficulty levels:", error);
    }
  };

  const fetchJobRoles = async (query, keepSlected = true) => {
    let limit = query ? 1000 : 10;
    let url = `/jobRole?limit=${limit}`;

    if (query) {
      url += `&query=${query}`;
    }
    const response = await APICall(url);
    const temp = [];
    filters.forEach((filter) => {
      if (filter.label === "Job Roles") {
        if (keepSlected) {
          const filteredCheckBoxes = filter.checkBox.filter(
            (cb) => cb.label !== "Select All" && cb.isChecked
          );
          temp.push(...filteredCheckBoxes);
        }
      }
    });
    response?.data?.documents?.forEach((job) =>
      temp.push({
        label: capitalize(job.jobRole),
        value: job._id,
        isChecked: false,
      })
    );

    setFilters((prevFilters) => {
      return prevFilters?.map((filter) => {
        if (filter.label === "Job Roles") {
          return {
            ...filter,
            total: response.data.total,
            checkBox: [
              {
                label: "Select All",
                value: "Select All",
                isChecked: false,
              },
              ...temp,
            ],
          };
        }
        return filter;
      });
    });
  };

  const handleFilterOptions = () => {
    setFilters([...filterOptions]);
    setSearchValue("");
    setDifficultyLevels([]);
    setTableParams({
      filters: tableFilters,
      pagination: {
        page: 1,
        limit: 10,
      },
    });
  };

  const handleFilterOpen = (type) => {
    setOpenAccordion(type);
    setIsFilterOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterOpen(false);
  };

  useEffect(() => {
    fetchSkills(searchedSkill);
  }, [searchedSkill]);

  useEffect(() => {
    fetchJobRoles(searchJobRoles);
  }, [searchJobRoles]);

  useEffect(() => {
    fetchDifficultyLevels();
  }, []);

  const handleTabClick = (index) => {
    if (currentTabIndex === index) {
      setCurrentTabIndex(-1);
    } else {
      setCurrentTabIndex(index);
    }
  };

  useEffect(() => {
    handleFilterOptions();
    fetchSkills(null, false);
    fetchDifficultyLevels();
    fetchJobRoles();
    setBreadcrumbsData([
      { name: "Assessments", href: PATH_DASHBOARD.assessments },
      { name: "Template Library", href: PATH_DASHBOARD.template_library },
      { name: value || "All" },
    ]);
  }, []);

  useEffect(() => {
    const updateBreadcrumbs = () => {
      const selectedTab = jobtabList.find((tab) => tab.value === value);
      const tabName = selectedTab ? selectedTab.label : "All";
      const difficultyLevelsMap = ["Easy", "Medium", "Hard"];
      const selectedDifficulty =
        currentTabIndex !== -1 ? difficultyLevelsMap[currentTabIndex] : null;
      const breadcrumbs = [
        { name: "Assessments", href: PATH_DASHBOARD.assessments },
        { name: "Template Library", href: PATH_DASHBOARD.template_library },
      ];
      if (tabName) {
        breadcrumbs.push({ name: tabName });
      }
      if (selectedDifficulty) {
        breadcrumbs.push({ name: selectedDifficulty });
      }
      setBreadcrumbsData(breadcrumbs);
    };
    updateBreadcrumbs();
  }, [value, jobtabList, currentTabIndex, setBreadcrumbsData]);

  useEffect(() => {
    fetchTemplates();
    let totalChecked = countCheckedFilters(filters);
    setFilterCount(totalChecked);
  }, [tableParams]);

  const onHandleApplyFilter = async (newFilter) => {
    let appliedFilters = newFilter ? newFilter : filters;

    const conditionFn = (filter) => {
      return filter.checkBox.some(
        (cb) => cb.label === "Select All" && cb.isChecked
      );
    };

    handleApplyFilter(
      appliedFilters,
      tableParams,
      setTableParams,
      setIsFilterOpen,
      conditionFn
    );
  };

  const handleSkillRemove = (skill) => {
    const appliedFilters = filters?.map((filter) => {
      if (filter.label === "Skills") {
        const updatedCheckBox = filter.checkBox.map((checkBox) => {
          if (checkBox.value === skill) {
            return { ...checkBox, isChecked: false };
          }
          return checkBox;
        });
        return { ...filter, checkBox: updatedCheckBox };
      }

      if (filter.label === "Job Roles") {
        const updatedCheckBox = filter.checkBox.map((checkBox) => {
          if (checkBox.value === skill) {
            return { ...checkBox, isChecked: false };
          }
          return checkBox;
        });
        return { ...filter, checkBox: updatedCheckBox };
      }

      return filter;
    });

    setFilters(appliedFilters);
    handleApplyFilter(
      appliedFilters,
      tableParams,
      setTableParams,
      setIsFilterOpen,
      appliedFilters
    );
  };

  const selectedFilters = {
    skills: Array.isArray(filters[0]?.checkBox)
      ? filters[0].checkBox
          .filter(
            (cb) =>
              Array.isArray(tableParams?.filters?.skills) &&
              tableParams.filters.skills.includes(cb.value)
          )
          .map((cb) => ({ label: cb.label, value: cb.value }))
      : [],
    jobRoles: Array.isArray(filters[1]?.checkBox)
      ? filters[1].checkBox
          .filter(
            (cb) =>
              Array.isArray(tableParams?.filters?.jobRoles) &&
              tableParams.filters.jobRoles.includes(cb.value)
          )
          .map((cb) => ({ label: cb.label, value: cb.value }))
      : [],
  };

  const MoreSkillsChipWithTooltip = ({ hiddenItems }) => {
    const labels = hiddenItems.map((item) => item.label).join(", ");
    return (
      <Tooltip title={labels} placement="bottom" arrow>
        <Chip
          label={`+${selectedFilters.skills.length - 5} more`}
          onClick={() => handleFilterOpen("Skills")}
          sx={{
            backgroundColor: "#d3d3d3",
            color: "#363E45",
            fontWeight: "500",
            fontSize: "12px",
            cursor: "pointer",
            borderRadius: "4px",
          }}
        />
      </Tooltip>
    );
  };

  const MoreRojRolesChipWithTooltip = ({ hiddenItems }) => {
    const labels = hiddenItems.map((item) => item.label).join(", ");
    return (
      <Tooltip title={labels} placement="bottom" arrow>
        <Chip
          label={`+${selectedFilters.jobRoles.length - 5} more`}
          onClick={() => handleFilterOpen("Job Roles")}
          sx={{
            backgroundColor: "#d3d3d3",
            color: "#363E45",
            fontWeight: "500",
            fontSize: "12px",
            cursor: "pointer",
            borderRadius: "4px",
          }}
        />
      </Tooltip>
    );
  };
  const hiddenSkills = selectedFilters?.skills?.slice(5);
  const hiddenJobRoles = selectedFilters?.jobRoles?.slice(5);

  const getPaginationOptions = (totalDocuments) => {
    if (totalDocuments <= 10) {
      return [10];
    } else if (totalDocuments > 10 && totalDocuments <= 25) {
      return [10, 25];
    } else if (totalDocuments > 25 && totalDocuments <= 50) {
      return [10, 25, 50];
    } else {
      return [10, 25, 50, 100];
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "12px", flexDirection: "column" }}>
      {authUser?.role === "recruiter" && (
        <Box>
          {/* <TabsList
            className={classes.tabHeader}
            tabs={jobtabList}
            handleTabChange={handleTabChange}
            value={value}
          /> */}
        </Box>
      )}
      <Stack
        gap={"16px"}
        sx={{
          padding: "16px",
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
        }}
        flexDirection={"column"}
      >
        <Stack
          justifyContent={"space-between"}
          direction={{ xs: "column", md: "row" }}
          sx={{ gap: "12px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              [theme.breakpoints.down("md")]: {
                flexBasis: "100%",
              },
            }}
          >
            <ButtonGroup
              variant="outlined"
              aria-label="difficulty button group"
            >
              {difficultyLevels.map((button, index) => {
                const settings = getDifficultSettingsByLevel(
                  button.label.toLowerCase()
                );

                const isActive = currentTabIndex === index;

                return (
                  <Button
                    key={button.value}
                    sx={{
                      padding: "8px 12px",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      borderRadius: "4px",
                      backgroundColor: isActive ? settings?.bgcolor : "#fff",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                      height: "36px",
                      color: settings?.color,
                      border: `1px solid ${
                        isActive ? settings?.bgcolor : "#ccc"
                      }`,
                    }}
                    color="inherit"
                    onClick={() => handleTabClick(index)}
                  >
                    <Box
                      component="img"
                      src={settings?.path}
                      alt={`${settings?.difficulty} icon`}
                      sx={{ width: 20, height: 20 }} // Customize icon size if needed
                    />
                    {/* {settings?.difficulty} */}
                    {settings?.difficulty === "Medium" && (
                      <>
                        {settings?.difficulty} ({mediumCount})
                      </>
                    )}
                    {settings?.difficulty === "Easy" && (
                      <>
                        {settings?.difficulty} ({easyCount})
                      </>
                    )}
                    {settings?.difficulty === "Hard" && (
                      <>
                        {settings?.difficulty} ({hardCount})
                      </>
                    )}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Box>

          <Box sx={{ display: "flex", gap: "12px" }}>
            <SearchBar
              search={search}
              value={searchValue}
              onChange={(e) =>
                handleTableSearchChange(
                  e,
                  setSearchValue,
                  setTableParams,
                  tableParams
                )
              }
              placeholder="Search Template"
              setSearch={setSearch}
            />
            <Box
              sx={{
                display: { md: "block", base: "none" },
                cursor: "pointer",
              }}
            >
              <Tooltip title="Filter" placement="bottom" arrow>
                <Tooltip
                  title={renderFilterTooltipContent(filterCount)}
                  placement="bottom"
                  arrow
                >
                  <StyledBadge badgeContent={filterCount?.totalChecked}>
                    <IconButton
                      onClick={handleFilterOpen}
                      sx={{
                        width: "36px",
                        height: "36px",
                        border: "1px solid",
                        borderRadius: "4px",
                        padding: 0,
                        borderColor: "secondary.main",
                      }}
                    >
                      <Iconify
                        icon="mage:filter"
                        width={"16px"}
                        color={"secondary.main"}
                      />
                    </IconButton>
                  </StyledBadge>
                </Tooltip>
              </Tooltip>
              {isFilterOpen && (
                <AssesmentFilter
                  open={isFilterOpen}
                  onClose={handleFilterClose}
                  filters={filters}
                  setFilters={setFilters}
                  filterLoading={filterLoading}
                  handleApplyFilter={onHandleApplyFilter}
                  openAccordion={openAccordion}
                  resetFilters={resetFilters}
                />
              )}
            </Box>
          </Box>
        </Stack>

        <Stack
          justifyContent="flex-start"
          direction="column"
          sx={{ gap: "12px" }}
        >
          <Box>
            {(selectedFilters.skills.length > 0 ||
              selectedFilters.jobRoles.length > 0) && (
              <>
                {selectedFilters.skills.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexWrap: "wrap",
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ flexShrink: 0, marginRight: "8px" }}
                    >
                      Skills:
                    </Typography>
                    <Box
                      display="flex"
                      gap="3px"
                      alignItems="center"
                      flexWrap="wrap"
                      sx={{
                        maxWidth: { xs: "100%" },
                        overflow: "hidden",
                      }}
                    >
                      {selectedFilters.skills
                        .slice(0, 5)
                        .map((filter, index) => (
                          <Chip
                            key={index}
                            label={filter.label}
                            onDelete={() => handleSkillRemove(filter.value)}
                            deleteIcon={
                              <Iconify
                                icon="eva:close-outline"
                                style={{ color: "white" }}
                              />
                            }
                            size="small"
                            sx={{
                              backgroundColor: "#00C49A",
                              color: "white",
                              fontWeight: "500",
                              fontSize: "12px",
                              cursor: "pointer",
                              borderRadius: "4px",
                              "&:hover": {
                                backgroundColor: "#d3d3d3",
                              },
                              "& .MuiChip-icon": {
                                color: "white",
                                width: "18px",
                                height: "18px",
                                marginRight: "4px",
                              },
                            }}
                          />
                        ))}
                      {selectedFilters.skills.length > 5 && (
                        <MoreSkillsChipWithTooltip hiddenItems={hiddenSkills} />
                      )}
                    </Box>
                  </Box>
                )}
                {selectedFilters.jobRoles.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ flexShrink: 0, marginRight: "8px" }}
                    >
                      Job Roles:
                    </Typography>
                    <Box
                      display="flex"
                      gap="3px"
                      alignItems="center"
                      flexWrap="wrap"
                      sx={{
                        maxWidth: { xs: "100%" },
                        overflow: "hidden",
                      }}
                    >
                      {selectedFilters.jobRoles
                        .slice(0, 5)
                        .map((filter, index) => (
                          <Chip
                            key={index}
                            label={filter.label}
                            onDelete={() => handleSkillRemove(filter.value)}
                            deleteIcon={
                              <Iconify
                                icon="eva:close-outline"
                                style={{ color: "white" }}
                              />
                            }
                            size="small"
                            sx={{
                              backgroundColor: "#00C49A",
                              color: "white",
                              fontWeight: "500",
                              fontSize: "12px",
                              cursor: "pointer",
                              borderRadius: "4px",
                              "&:hover": {
                                backgroundColor: "#d3d3d3",
                              },
                              "& .MuiChip-icon": {
                                color: "white",
                                width: "18px",
                                height: "18px",
                                marginRight: "4px",
                              },
                            }}
                          />
                        ))}
                      {selectedFilters.jobRoles.length > 5 && (
                        <MoreRojRolesChipWithTooltip
                          hiddenItems={hiddenJobRoles}
                        />
                      )}
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Stack>

        <Grid container spacing={2}>
          {filterLoading ? (
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                <LoadingScreen />
              </Typography>
            </Grid>
          ) : templates.length === 0 ? (
            <Grid item xs={12}>
              <Typography
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  marginTop: "20px",
                }}
              >
                <img
                  src="/assets/NoData.svg"
                  alt="No data found"
                  style={{
                    height: "60px",
                    width: "auto",
                  }}
                />
                <p> No data found</p>
              </Typography>
            </Grid>
          ) : (
            templates.map((template, index) => (
              <ReadymadeTestCard template={template} />
            ))
          )}
        </Grid>
        <Box>
          <TablePagination
            component="div"
            count={totalDocuments}
            page={tableParams.pagination.page - 1} // Adjust page index (starts from 0)
            onPageChange={(e, newPage) => {
              handleChangePage(newPage, setTableParams);
            }}
            rowsPerPageOptions={getPaginationOptions(totalDocuments)}
            rowsPerPage={tableParams.pagination.limit}
            onRowsPerPageChange={(e) => {
              handleChangeRowsPerPage(e.target.value, setTableParams);
            }}
            labelRowsPerPage="Templates per page:"
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default TemplateLibrary;

const StyledBadge = (props) => {
  return (
    <Badge
      {...props}
      sx={{
        "& .MuiBadge-badge": {
          top: 4,
          right: 0,
          width: "16px",
          height: "16px",
          minWidth: "16px",
          minHeight: "16px",
          backgroundColor: "#F94948",
          color: "#FFFFFF",
          fontSize: "8px",
          borderRadius: "50%",
          padding: "0 4px",
          fontFamily: "Poppins",
          fontWeight: 600,
        },
      }}
    >
      {props.children}
    </Badge>
  );
};
