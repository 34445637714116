import React, { useState } from "react";
import CustomTable from "../../common/customTable/CustomTable";
import { Box, LinearProgress, Typography } from "@mui/material";
import { ShortlistData } from "../../../utils/data";

const columnsWidth = [
  "60px",
  "299px",
  "130px",
  "148px",
  "215px",
  "130px",
  "165px",
];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
  bgColor: "#F2F4F8",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const getColorByStatus = (status) => {
  if (status === "Passed") {
    return { bg: "#D5F9F1", color: "#004E3E" };
  } else if (status === "Intermediate") {
    return { bg: "#FBE3B1", color: "#614105" };
  }
  return { bg: "#FADCDA", color: "#733430" };
};
const tableBodyRowStyleProps = {
  height: "44px",
};

const tableHeaderTitles = [
  {
    title: "Sr. no",
    cellStyleProps: {
      width: columnsWidth[0],
    },
  },
  {
    title: "Student Name",
    cellStyleProps: {
      width: columnsWidth[1],
    },
  },
  {
    title: "Score",
    cellStyleProps: {
      width: columnsWidth[2],
    },
  },
  {
    title: "Questions Attempted",
    cellStyleProps: {
      width: columnsWidth[3],
    },
  },
  {
    title: "Correct & Incorrect Attempts",
    cellStyleProps: {
      width: columnsWidth[4],
    },
  },
  {
    title: "Marks Obtained",
    cellStyleProps: {
      width: columnsWidth[5],
      textAlign: "center",
    },
  },
  {
    title: "Status",
    cellStyleProps: {
      width: columnsWidth[6],
    },
  },
];

const tableHeaderCells = () => {
  return tableHeaderTitles.map((item) => {
    return {
      isSortCol: item?.isSortCol ? item.isSortCol : false,
      colId: item?.colId ? item.colId : "",
      element: item.title,
      cellStyleProps: item.cellStyleProps,
      isSortable: item?.isSortable ? item.isSortable : false,
      cellId: item?.cellId ? item.cellId : "",
    };
  });
};

const DataTable = () => {
  const tableBodyRowCell = (row, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.srNo}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img
              src="/assets/user.png"
              alt="user"
              style={{ height: "20px", width: "20px" }}
            />
            <Typography
              sx={{
                ...tableContentCellTextStyleProps,
              }}
            >
              {row.candidateName}
            </Typography>
          </Box>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.score}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {`10/10`}
          </Typography>
        ),
      },

      {
        cellStyleProps,
        element: (
          <Box>
            <LinearProgress
              variant="determinate"
              value={parseInt(row.testScore)}
              sx={{
                bgcolor: "#FD948C", // Background color
                "& .MuiLinearProgress-bar": {
                  bgcolor: "#00C49A", // Progress color
                },
              }}
            />
          </Box>
        ),
      },
      {
        cellStyleProps,
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
              textAlign: "center",
            }}
          >
            {row.testScore}
          </Typography>
        ),
      },
      {
        cellStyleProps,
        element: getStatus(row.status),
      },
    ];
  };

  const tableBodyContent = () => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };

    return ShortlistData.map((row, idx) => {
      return {
        rowElement: tableBodyRowCell({ ...row, srNo: idx + 1 }, cellStyleProps),
      };
    });
  };

  const getStatus = (status) => {
    return (
      <Box
        sx={{
          display: "inline-block",
          background: getColorByStatus(status).bg,
          padding: "2px 8px",
          color: getColorByStatus(status).color,
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "16px",
          fontFamily: "Roboto",
          borderRadius: "0.25rem",
          whiteSpace: "nowrap",
        }}
      >
        {status}
      </Box>
    );
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      <CustomTable
        tableHeaderCells={tableHeaderCells()}
        tableHeaderCellStyleProps={tableHeaderCellStyleProps}
        tableBodyContent={tableBodyContent()}
        tableData={ShortlistData}
        tableBodyRowStyleProps={tableBodyRowStyleProps}
        sortRows={() => {}}
        isPaginationVisible={false}
      />
    </Box>
  );
};

export default DataTable;
