import React, { useEffect, useRef, useState } from "react";
import {
  MenuItem,
  TextField,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  validityDropdown: {
    width: "100%",
    height: "48px",
    "& .MuiSelect-select": {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: theme.palette.primary.black,
    },
    "& .MuiInputLabel-asterisk": {
      color: "#F94948",
    },

    "& .MuiFormLabel-root": {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      padding: "0px 4px",
      fontFamily: "'Roboto', sans-serif",
    },
    color: theme.palette.gray.main,
  },

  inputText: {
    width: "100%",
    color: theme.palette.primary.black,
    "& .MuiInputBase-root": {
      height: "45px",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: theme.palette.primary.black,
    },
    "& .MuiFormLabel-root": {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      padding: "0px, 4px",
      color: theme.palette.gray.main,
      fontFamily: "Roboto",
    },
    "& .MuiInputLabel-asterisk": {
      color: "#F94948",
    },
  },
}));

function DropdownField({
  data,
  id,
  label,
  variant,
  required,
  select,
  type,
  defaultValue,
  validator,
  error,
  helperText,
  inputProps,
  height,
  ...props
}) {
  const classes = useStyles();

  const labelId = `select-value-${label}`;

  const selectRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [menuProps, setMenuProps] = useState({});

  useEffect(() => {
    if (selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;

      setMenuProps({
        PaperProps: {
          style: {
            maxHeight: 48 * 4,
            overflowY: "auto",
          },
        },
        anchorOrigin: {
          vertical:
            spaceBelow < 120 && spaceAbove > spaceBelow ? "top" : "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical:
            spaceBelow < 120 && spaceAbove > spaceBelow ? "bottom" : "top",
          horizontal: "left",
        },
      });
    }
  }, [open]);

  return (
    <>
      {select ? (
        <FormControl
          fullWidth
          required={required}
          className={classes.validityDropdown}
          size="small"
          error={error}
        >
          <InputLabel id={labelId} className={classes.label}>
            {label}
          </InputLabel>
          <Select
            labelId={labelId}
            id={labelId}
            label={label}
            name={id}
            value={defaultValue || ""}
            ref={selectRef}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            IconComponent={ExpandMoreIcon}
            // style={{ height: "48px" }}
            MenuProps={menuProps}
            height={height}
            {...props}
          >
            {data?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={error} id={labelId}>
            {helperText}
          </FormHelperText>
        </FormControl>
      ) : (
        <TextField
          className={classes.inputText}
          label={label}
          id={`${label}-id`}
          name={label}
          type={type ?? "text"}
          required={required}
          value={defaultValue || ""}
          inputProps={inputProps}
          error={error}
          helperText={helperText}
          height={height}
          {...props}
          size="small"
        />
      )}
    </>
  );
}

export default DropdownField;
