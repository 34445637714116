import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Router from "./routes";
import { useMemo, useState } from "react";
import { navContext } from "./context/navContext";
import "./index.css";
import { SnackbarProvider } from "notistack";
import AuthProvider from "./context/authProvider";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

import { NotificationProvider } from "./context/NotificationProvider";
import { PingProvider } from "./context/pingContext";

function App() {
  const [isNavMini, setIsNavMini] = useState(false);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const handleChangeDrawer = () => {
    setToggleDrawer(!toggleDrawer);
  };

  const obj = useMemo(
    () => ({
      isNavMini,
      setIsNavMini,
      toggleDrawer,
      setToggleDrawer,
      isVisible,
      setIsVisible,
    }),
    [isNavMini, toggleDrawer, isVisible]
  );
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <navContext.Provider value={obj}>
            <AuthProvider>
              <PingProvider>
                <BrowserRouter>
                  <SnackbarProvider maxSnack={3}>
                    <NotificationProvider>
                      <Router />
                    </NotificationProvider>
                  </SnackbarProvider>
                </BrowserRouter>
              </PingProvider>
            </AuthProvider>
          </navContext.Provider>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
