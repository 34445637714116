import { SvgIcon } from "@mui/material";
import React from "react";

const ChevronDownIcon = ({ fill = "#71777B" }) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 13.0846C9.92343 13.0847 9.84751 13.0696 9.7767 13.0403C9.70588 13.011 9.64154 12.9679 9.58738 12.9137L4.5862 7.91277C4.4774 7.80319 4.41647 7.65495 4.41675 7.50053C4.41703 7.34611 4.4785 7.1981 4.58769 7.08891C4.69689 6.97972 4.84491 6.91825 4.99934 6.91797C5.15376 6.91769 5.30201 6.97862 5.4116 7.08742L10.0001 11.6761L14.5886 7.08742C14.6982 6.97862 14.8464 6.91769 15.0008 6.91797C15.1553 6.91825 15.3033 6.97972 15.4125 7.08891C15.5217 7.1981 15.5831 7.34611 15.5834 7.50053C15.5837 7.65495 15.5228 7.80319 15.414 7.91277L10.4128 12.9137C10.3586 12.9679 10.2943 13.011 10.2235 13.0403C10.1526 13.0696 10.0767 13.0847 10.0001 13.0846Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default ChevronDownIcon;
