import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import QuestionAnalyticsDataTable from "../assesmentPage/QuestionAnalytics/QuestionAnalyticsDataTable";
import APICall from "../../utils/api";
import LoadingScreen from "../../layouts/LoadingScreen";

const QuestionsAnalytics = ({ assessmentId }) => {
  const [questionAnalyticsData, setQuestionAnalyticsData] = useState({
    questionAnalytics: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchQuestionsAnalytics = async () => {
    try {
      setLoading(true);
      setError(null);
      const url = `/candidateAssessment/questionsAnalytics/${assessmentId}`;
      const res = await APICall(url);

      if (res && res.data) {
        setQuestionAnalyticsData(res.data);
      } else {
        setError("No data available");
      }
    } catch (error) {
      setError("Failed to fetch assessment details. Please try again later.");
      console.error("Failed to fetch assessment details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestionsAnalytics();
  }, [assessmentId]);

  const data = [
    {
      icon: "/assets/questionanalytics1.svg",
      label: "100",
      subTitle: "Total Question",
    },
    {
      icon: "/assets/questionanalytics2.svg",
      label: "80",
      subTitle: "Attempted",
    },
    {
      icon: "/assets/questionanalytics3.svg",
      label: "05",
      subTitle: "Unattempted",
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          marginTop: "16px",
          flexDirection: { sm: "row", base: "column" },
        }}
      >
        <Box
          sx={{
            width: { sm: "33.33%", base: "100%" },
            border: "1px solid #E8E9EE",
            height: "84px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            padding: "0 12px",
            gap: "12px",
          }}
        >
          <Box>
            <img src="/assets/questionanalytics1.svg" alt="Total Quations" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "32px",
                color: "#363E45",
              }}
            >
              {questionAnalyticsData.totalQuestions}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#636A75",
              }}
            >
              Total Question
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: { sm: "33.33%", base: "100%" },
            border: "1px solid #E8E9EE",
            height: "84px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            padding: "0 12px",
            gap: "12px",
          }}
        >
          <Box>
            <img src="/assets/questionanalytics2.svg" alt="Total Attempted" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "32px",
                color: "#363E45",
              }}
            >
              {questionAnalyticsData.totalAttempted}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#636A75",
              }}
            >
              Attempted
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: { sm: "33.33%", base: "100%" },
            border: "1px solid #E8E9EE",
            height: "84px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            padding: "0 12px",
            gap: "12px",
          }}
        >
          <Box>
            <img src="/assets/questionanalytics3.svg" alt="Total Unattempted" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "32px",
                color: "#363E45",
              }}
            >
              {questionAnalyticsData.totalUnattempted}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#636A75",
              }}
            >
              Unattempted
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          {loading ? (
            <LoadingScreen />
          ) : error ? (
            <div>{error}</div>
          ) : (
            <QuestionAnalyticsDataTable
              questionAnalyticsData={questionAnalyticsData}
              totalCandidatesAttempted={
                questionAnalyticsData.totalCandidatesAttempted
              }
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionsAnalytics;
