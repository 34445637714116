import { Box, Typography, Button, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import { MdDashboard } from "react-icons/md";
import { LuTable2 } from "react-icons/lu";
import { makeStyles } from "@mui/styles";
import theme from "../../theme/theme";

const useStyles = makeStyles((theme) => ({
  tabs: {
    "&.Mui-selected": {
      background: "black",
      color: " #fff",
    },
  },
}));
const ToggleSquareIcons = ({ setShortCandidates, shortCandidates }) => {
  const handelChangeGrid = () => {
    setShortCandidates(true);
  };
  const handelChangeSort = () => {
    setShortCandidates(false);
  };
  const classes = useStyles();
  return (
    <Box
      sx={{
        background: "#E9EAEB",
        borderRadius: "6px",
        border: "1px solid rgba(0,0,0,0.2)",
        borderColor: theme.palette.primary.gray,
        padding: "4px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Tabs
        className={classes.tabs}
        value={shortCandidates}
        sx={{
          background: "#E9EAEB",
          minHeight: "28px",
        }}
        aria-label="secondary tabs example"
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        <Tab
          onClick={handelChangeSort}
          sx={{
            padding: "0px",
            minWidth: "36px",
            minHeight: "28px",
            "&.Mui-selected": {
              background: theme.palette.tertiary.blue,
              color: "#fff",
              borderRadius: "4px",
            },
          }}
          icon={<LuTable2 />}
          value={false}
        />
        <Tab
          onClick={handelChangeGrid}
          sx={{
            padding: "0px",
            minWidth: "36px",
            minHeight: "28px",
            "&.Mui-selected": {
              background: theme.palette.tertiary.blue,
              color: "#fff",
              borderRadius: "4px",
            },
          }}
          icon={<MdDashboard />}
          value={true}
        />
      </Tabs>
    </Box>
  );
};

export default ToggleSquareIcons;
