import React from "react";
import Box from "@mui/material/Box";
import { Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import theme from "../../theme/theme";
import { HiOutlineBriefcase } from "react-icons/hi";
import { TbBuildingCommunity } from "react-icons/tb";
import { LuUsers } from "react-icons/lu";

const Card = ({ data, width = "100%" }) => {
  const roundColors = {
    "Technical Round": "#F0F4FF",
    Invited: "#F0F4FF",
    Appeared: "#F0F4FF",
    Hired: "#F0F4FF",
    Rejected: "#F0F4FF",
    "Round 1": "#FFF0E7",
    "Round 2": "#FADCDA",
    "HR Round": "#D9E6DC",
    "Start Interview": "#35ba96",
    "Start Inteview": "#e0e0e0",
  };

  return (
    <>
      <Box
        sx={{
          padding: "14px",
          width: width,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          borderRadius: "4px",
          boxShadow: "0 1px 3px 0px rgba(0,0,0,0.2)",
          height: "100%",
        }}
      >
        <Box
          sx={{ display: "flex", gap: "12px", justifyContent: "space-between" }}
        >
          <Box sx={{ display: "flex", gap: "12px" }}>
            <Box
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                objectPosition: "cover",
              }}
              component={"img"}
              src={data?.avtar ? data?.avtar : "/assets/user.png"}
            >
              {/* <img
                src={data?.avtar ? data?.avtar : "/assets/user.png"}
                height="40px"
                width="40px"
              /> */}
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#313131",
                  fontFamily: "Nunito,sans-serif",
                }}
              >
                {data?.candidateId?.name}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  paddingTop: "4px",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#535A5F",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                <HiOutlineBriefcase size={18} color="#69778A" />
                {data.assessmentId?.jobRoleId?.jobRole}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: roundColors[data.round],
              color: theme.palette.black || "black",
              padding: "4px 12px",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "16px",
              borderRadius: "0.25rem",
              whiteSpace: "nowrap",
              margin: "auto 0",
              marginBottom: "22px",
            }}
          >
            {data?.round}
          </Box>
        </Box>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1.5}
          sx={{ justifyContent: "space-between" }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#535A5F",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <Tooltip title="Scheduled By" placement="bottom" arrow>
              <img
                src="/assets/icons/users24.svg"
                style={{ cursor: "pointer", height: "20px" }}
                color="#69778A"
              />
            </Tooltip>
            {data.interviewerId?.name}
          </Typography>

          <Button
            variant="contained"
            sx={{
              backgroundColor: roundColors[data.startDate],
              color: theme.palette.black || "black",
              padding: "4px 12px",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "16px",
              borderRadius: "0.25rem",
              whiteSpace: "nowrap",
              margin: "auto 0",
              marginBottom: "22px",
              color: "white",
              "&:hover": {
                backgroundColor: roundColors[data.startDate],
              },
            }}
          >
            <img
              src="/assets/video.svg"
              alt="interview"
              style={{ marginRight: "4px" }}
            />
            {data?.startDate}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default Card;
