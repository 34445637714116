import React, { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import katex from "katex";
import "katex/dist/katex.min.css";
import CommonModal from "../../components/modal/CommonModal";

const SunTextEditor = ({
  value,
  setValue,
  height,
  minHeight,
  onBlur,
  error,
  props,
  width,
  placeholder,
  disable,
  hideToolbar,
  setOptions,
  setAdditionalOptions,
}) => {
  //   const handleChange = (content) => {
  //     if (content === "<p><br></p>" || content.trim() === "") {
  //       setValue("");
  //     } else {
  //       setValue(content);
  //     }
  //   };

  const handleChange = setValue
    ? (content) => {
        setValue(content);
      }
    : null;

  return (
    <div
      style={{
        height: height,
        width: width,
        border: error ? "2px solid red" : "",
      }}
    >
      <SunEditor
        setContents={value}
        onChange={handleChange}
        disable={disable}
        onBlur={onBlur}
        height={height}
        hideToolbar={hideToolbar}
        style={{
          minHeight: minHeight,
        }}
        setOptions={{
          katex: katex,
          minHeight: "150px",
          buttonList: [
            [
              "undo",
              "redo",
              "formatBlock",
              "paragraphStyle",
              "blockquote",
              "bold",
              "italic",
              "underline",
              "strike",
              "subscript",
              "superscript",
              "math",
              "fontColor",
              "hiliteColor",
              "removeFormat",
              "indent",
              "outdent",
              "align",
              "horizontalRule",
              "list",
              "lineHeight",
              "table",
              "image",
              "video",
              "fullScreen",
              "showBlocks",
              "preview",
            ],
          ],
          video: {
            url: true,
          },
          ...setAdditionalOptions,
        }}
        {...props}
      />
    </div>
  );
};

export default SunTextEditor;
