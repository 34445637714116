import React, { useState, useRef } from "react";
import { Button, Box, Typography, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import Guidelines from "./Guidelines";
import CandidatesCard from "./CandidatesCard";
import BorderedFile from "./BorderedFile";
import ExcelJS from "exceljs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    backgroundColor: theme.palette.background.default,
    border: `2px dashed ${theme.palette.secondary.main}`,
    borderRadius: "8px",
  },
  fileInput: {
    display: "none",
  },
  fileName: {
    fontSize: "14px",
    color: theme.palette.gray.main,
    fontWeight: "500",
    lineHeight: "20px",
  },
  selectedFile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "4px",
    flexGrow: "1",
  },
  fileType: {
    fontSize: "12px",
    color: theme.palette.gray.main,
    fontWeight: "400",
    lineHeight: "16px",
    fontFamily: "Roboto",
  },
}));

const FileUpload = ({ onFileChange }) => {
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  // const [setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [fileUploaded, setFileUploaded] = useState(false);
  const [candidatesData, setCandidatesData] = useState({
    totalCandidates: 0,
    duplicates: 0,
    duplicateErrors: [],
    validationErrors: [],
    allCandidates: [],
    candidatesSet: new Set(),
  });
  const classes = useStyles();
  const theme = useTheme();
  const fileInputRef = useRef(null);

  const validateInviteData = (inviteData) => {
    const { candidate, email, invitedOn, expiry, invitedBy, status } =
      inviteData;

    let errors = {};
    let isValid = true;

    // Email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const getEmailValue = (email) =>
      typeof email === "object" ? email.text : email;

    const emailValue = getEmailValue(email);
    if (!emailValue || !emailRegex.test(emailValue)) {
      errors.email = "Invalid email format";
      isValid = false;
    }

    // Check for empty values in other fields
    if (!candidate.trim()) {
      errors.name = "Candidate Name cannot be empty";
      isValid = false;
    }
    if (!invitedOn.trim()) {
      errors.channelName = "Invited On cannot be empty";
      isValid = false;
    }
    if (!expiry.trim()) {
      errors.role = "Expiry Date cannot be empty";
      isValid = false;
    }
    if (!invitedBy.trim()) {
      errors.designation = "Invited By cannot be empty";
      isValid = false;
    }
    if (!status.trim()) {
      errors.seniorityLevel = "Status cannot be empty";
      isValid = false;
    }

    return { isValid, errors };
  };

  const handleFileUpload = async (event) => {
    console.log("File uploaded");
    try {
      const selectedFile = event.target.files[0];
      setFileName(selectedFile.name);
      setFileSize((selectedFile.size / 1024 / 1024).toFixed(2) + "MB");
      setFileUploaded(true);
      setUploadStatus("");

      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const buffer = e.target.result;
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(buffer);
          const worksheet = workbook.getWorksheet("Sample Upload Format");

          let allCandidates = [];
          let validationErrors = [];

          worksheet.eachRow((row, rowNumber) => {
            if (rowNumber >= 2) {
              const inviteData = {
                candidate: row.getCell(1).value,
                email:
                  typeof row.getCell(2).value == `object`
                    ? row.getCell(2).value.text
                    : row.getCell(2).value,
                invitedOn: row.getCell(3).value,
                expiry: row.getCell(4).value,
                invitedBy: row.getCell(5).value,
                status: row.getCell(6).value,
              };
              console.log(`Processing row ${rowNumber}`, inviteData);
              const { isValid, errors } = validateInviteData(inviteData);
              if (!isValid) {
                validationErrors.push({ ...errors, rowNumber });
              } else {
                allCandidates.push(inviteData);
              }
            }
          });

          const emailSet = new Set(
            allCandidates.map((member) =>
              typeof member.email === "object"
                ? member.email.text
                : member.email
            )
          );

          console.log("Set", emailSet);
          setCandidatesData((prevState) => ({
            ...prevState,
            allCandidates: [...prevState.allCandidates, ...allCandidates],
            validationErrors: [
              ...prevState.validationErrors,
              ...validationErrors,
            ],
            totalCandidates: allCandidates.length,
            duplicates: allCandidates.length - emailSet.size,
            candidatesSet: new Set([...prevState.candidatesSet, ...emailSet]),
          }));
          onFileChange(allCandidates);
        } catch (error) {
          console.error("Error processing the file", error);
          setUploadStatus("Error processing the file");
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading the file", error);
        setUploadStatus("Error reading the file");
      };

      reader.readAsArrayBuffer(selectedFile);
    } catch (error) {
      console.error("Error handling the file upload", error);
      setUploadStatus(
        "Error handling the file upload, Please correct the validation errors in the file"
      );
    }
  };

  const handleRemoveDuplicates = () => {
    console.log("Removing duplicates");
    const { allCandidates } = candidatesData;
    const seenEmails = new Set();
    const uniqueCandidates = [];

    allCandidates.forEach((member) => {
      const email =
        typeof member.email === "object" ? member.email.text : member.email;
      if (!seenEmails.has(email)) {
        uniqueCandidates.push(member);
        seenEmails.add(email);
      }
    });

    setCandidatesData((prevState) => ({
      ...prevState,
      allCandidates: uniqueCandidates,
      duplicates: 0, // Assuming you want to keep track of the duplicates here
      totalCandidates: uniqueCandidates.length,
    }));
    onFileChange(uniqueCandidates);
  };

  const handleRemoveFile = () => {
    setFileName("");
    setFileSize("");
    setFileUploaded(false);
    setUploadStatus("");
    setCandidatesData({
      totalCandidates: 0,
      duplicates: 0,
      duplicateErrors: [],
      validationErrors: [],
      allCandidates: [],
      candidatesSet: new Set(),
    });

    console.log("Reuploading file");
    onFileChange(null);
  };

  const handleReuploadFile = () => {
    fileInputRef?.current?.click();
  };

  const handleSampleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sample Upload Format");

    worksheet.columns = [
      { header: "Candidate", key: "candidate", width: 20 },
      { header: "Email", key: "email", width: 15 },
      { header: "Invited On", key: "invitedOn", width: 20 },
      { header: "Expiry", key: "expiry", width: 20 },
      { header: "Invited By", key: "invitedBy", width: 20 },
      { header: "Status", key: "status", width: 25 },
    ];

    worksheet.addRow({
      candidate: "Example Candidate Name",
      email: "example@email.com",
      invitedOn: "24/11/2023 07:48:25",
      expiry: "24/11/2023 07:48:25",
      invitedBy: "N/A",
      status: "Shortlisted",
    });

    const fileName = "Sample_Upload_Format.xlsx";
    await workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "flex-start", sm: "center" }}
        sx={{ gap: "24px" }}
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: theme.palette.primary.black,
            lineHeight: "24px",
            fontWeight: 600,
            fontFamily: "Nunito",
          }}
        >
          Upload file
        </Typography>
        <Button
          color="gray"
          variant="outlined"
          endIcon={
            <Box
              component={"img"}
              sx={{ width: "16px", height: "16px" }}
              src={"/assets/icons/download.svg"}
            />
          }
          sx={{
            padding: "6px 12px 6px 16px",
            fontSize: "14px",
            fontWeight: 500,
            color: theme.palette.gray.dark,
            border: `1px solid ${theme.palette.gray.dark}`,
            borderRadius: "4px",
            maxWidth: "fit-content",
            fontFamily: "Roboto",
            lineHeight: "20px",
          }}
          onClick={handleSampleDownload}
        >
          {fileUploaded ? "Uploaded File" : "Download Sample File"}
        </Button>
      </Stack>

      {fileName ? (
        <BorderedFile
          fileName={fileName}
          handleRemoveFile={handleRemoveFile}
          handleReuploadFile={handleReuploadFile}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ width: "48px", height: "48px" }}
          >
            <Box
              component={"img"}
              src={`/assets/icons/fileupload.svg`}
              sx={{ width: "30.8px", height: "38.8px" }}
            />
          </Stack>
          <Stack direction={"column"} sx={{ gap: "4px" }}>
            <Stack direction={"column"}>
              <Typography variant="body2" className={classes.fileName}>
                File Name
              </Typography>
              <Typography
                style={{
                  textDecorationLine: "underline",
                  padding: "2px 0px",
                  color: theme.palette.secondary.main,
                  fontSize: "1rem",
                  fontWeight: "500",
                  fontFamily: "Roboto",
                  lineHeight: "20px",
                }}
              >
                {fileName}
              </Typography>
            </Stack>
            <Typography className={classes.fileType}>{fileSize}</Typography>
            <input
              accept=".xls, .xlsx, .csv"
              className={classes.fileInput}
              id="file-upload"
              type="file"
              ref={fileInputRef}
              onChange={(e) => handleFileUpload(e)}
            />
          </Stack>
        </BorderedFile>
      ) : (
        <BorderedFile>
          <Box
            component={"img"}
            src={`/assets/icons/fileupload.svg`}
            sx={{ width: "30.8px", height: "38.8px" }}
          />
          <Typography variant="body2" className={classes.fileName}>
            No file selected
          </Typography>
          <input
            accept=".xls, .xlsx, .csv"
            className={classes.fileInput}
            id="file-upload"
            type="file"
            ref={fileInputRef}
            onChange={(e) => handleFileUpload(e)}
          />
          <label
            htmlFor="file-upload"
            style={{ fontWeight: "400", fontSize: "1rem" }}
          >
            <Stack
              direction={{ xs: "column", sm: "column", md: "row" }}
              alignItems={"center"}
            >
              <Button
                component="span"
                variant="text"
                style={{
                  textDecorationLine: "underline",
                  padding: "2px 4px",
                  color: theme.palette.secondary.main,
                  fontSize: "1rem",
                  fontWeight: "500",
                  borderRadius: "4px",
                  fontFamily: "Roboto",
                  lineHeight: "20px",
                }}
              >
                Click to upload
              </Button>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  color: "#535A5F",
                  fontFamily: "Roboto",
                  lineHeight: "20px",
                }}
              >
                or Drag and Drop
              </Typography>
            </Stack>
          </label>
          <Stack sx={{ gap: "4px" }} direction={"row"}>
            <Typography className={classes.fileType}>XLS, XLSX, CSV</Typography>
            <Typography className={classes.fileType}>(max. 20MB)</Typography>
          </Stack>
        </BorderedFile>
      )}

      {uploadStatus && (
        <Typography variant="body2" color="error">
          {uploadStatus}
        </Typography>
      )}

      {!fileName ? (
        <Guidelines />
      ) : (
        <>
          {/* <Button onClick={handleSubmit}>Submit</Button> */}
          <CandidatesCard
            totalCandidates={candidatesData.totalCandidates}
            duplicates={candidatesData.duplicates}
            duplicateErrors={candidatesData.duplicateErrors}
            validationErrors={candidatesData.validationErrors}
            removeDuplicates={handleRemoveDuplicates}
          />
        </>
      )}
    </Box>
  );
};

export default FileUpload;
