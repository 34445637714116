import { Checkbox, Grid, TablePagination, Skeleton } from "@mui/material";
import React from "react";
import TestTakenCandidateCard from "../common/TestTakenCandidateCard";
import { ReviewedData } from "../../utils/data";
import { getPaginationOptions } from "../../utils/pagination";

const TestTakenGrid = ({
  data,
  handleStatusClick,
  setSelectedRow,
  selectedCheckboxes,
  handleCheckboxChange,
  totalDocuments,
  tableParams,
  handleChangePage,
  handleRowsChange,
  currentTab,
  loading,
}) => {
  return (
    <>
      {" "}
      <Grid
      // sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}
      >
        {loading
          ? [...Array(tableParams.pagination.limit)].map((_, i) => (
              <Grid item xs={12} key={i} style={{ marginBottom: "-40px" }}>
                <Skeleton animation="wave" variant="text" height={130} />
              </Grid>
            ))
          : data.map((elem, index) => (
              <Grid
                container
                key={index}
                alignItems="center"
                marginBottom={"10px"}
                marginTop={"10px"}
              >
                <Grid item>
                  <Checkbox
                    onChange={() =>
                      handleCheckboxChange(elem.candidateAssessmentId)
                    }
                    checked={selectedCheckboxes.includes(
                      elem.candidateAssessmentId
                    )}
                  />
                </Grid>
                <Grid item xs>
                  <TestTakenCandidateCard
                    data={elem}
                    handleStatusClick={handleStatusClick}
                    setSelectedRow={setSelectedRow}
                    currentTab={currentTab}
                  />
                </Grid>
              </Grid>
            ))}
      </Grid>
      <TablePagination
        component="div"
        count={totalDocuments}
        rowsPerPage={tableParams.pagination.limit}
        page={tableParams.pagination.page - 1}
        onPageChange={(e, newPage) => {
          handleChangePage(newPage + 1);
        }}
        onRowsPerPageChange={handleRowsChange}
        className="custom-table-pagination"
        rowsPerPageOptions={getPaginationOptions(
          totalDocuments,
          tableParams.pagination.page,
          tableParams.pagination.limit
        )}
      />
    </>
  );
};

export default TestTakenGrid;
