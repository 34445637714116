// paginationUtils.js
export const getPaginationOptions = (
  totalDocuments,
  currentPage,
  currentRowsPerPage
) => {
  // Calculate the number of documents already viewed
  const documentsViewed = (currentPage - 1) * currentRowsPerPage;
  const remainingDocuments = totalDocuments - documentsViewed;

  // Dynamically set base options based on remaining documents
  let baseOptions = [10];

  if (remainingDocuments >= 10) {
    baseOptions.push(25);
  }
  if (remainingDocuments >= 25) {
    baseOptions.push(50);
  }
  if (remainingDocuments >= 50) {
    baseOptions.push(100);
  }

  // Sort the options
  return baseOptions.sort((a, b) => a - b);
};

export const handleChangePage = (page, setTableParams) => {
  setTableParams((prev) => ({
    ...prev,
    pagination: {
      ...prev.pagination,
      page: page + 1,
    },
  }));
};

export const handleChangeRowsPerPage = (rowsPerPage, setTableParams) => {
  setTableParams((prev) => ({
    ...prev,
    pagination: {
      ...prev.pagination,
      page: 1,
      limit: rowsPerPage,
    },
  }));
};
