import {
  Box,
  Drawer,
  Typography,
  Tooltip,
  Divider,
  Chip,
  capitalize,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import DOMPurify from "dompurify";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 20,
  height: 20,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #8591A2",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#00C49A",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#00C49A",
  },
  "input:checked ~ &": {
    boxShadow: "none",
  },
});

const options = [
  { text: "1. Open source JavaScript back end library", isCorrect: false },
  {
    text: "2. JavaScript front end library to create a database",
    isCorrect: true,
  },
  {
    text: "3. Free and Open source JavaScript front end library",
    isCorrect: true,
  },
  { text: "4. None of the Mentioned", isCorrect: false },
];
const sanitizerConfig = {
  ADD_TAGS: ["iframe"],
  ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "src"],
  ALLOWED_URI_REGEXP: /^(https?:|mailto:|data:)/i,
};

const handleTryQuestionClick = (event) => {
  event.stopPropagation();
  window.open("https://assessment.hire360.ai", "_blank");
};

const QuestionPreview = ({ open, onClose, question, difficultySettings }) => {
  const cleanQuestion = DOMPurify.sanitize(
    question?.question || "No statement provided",
    sanitizerConfig
  );

  const katexConfig = {
    throwOnError: false,
  };

  const calculateHealthScore = () => {
    const reductionPerUse = 0.2;
    const maxHealthScore = question?.healthScore?.healthScore || 100;
    const minHealthScore = 0;
    const normalizedMax = 10;

    const healthScore = Math.max(
      maxHealthScore - question?.noOfTimesUsed * reductionPerUse,
      minHealthScore
    );

    const normalizedScore = (healthScore / maxHealthScore) * normalizedMax;

    const scoreInRange = Math.max(1, normalizedScore);

    return parseFloat(scoreInRange.toFixed(0));
  };

  const displayScore = calculateHealthScore();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ backdropFilter: "blur(3px)" }}
    >
      <style jsx>{`
        .sun-editor {
          overflow: visible;
          width: auto;
        }
      `}</style>

      <Box sx={{ width: { sm: "700px", base: "350px" } }}>
        <Box
          sx={{
            padding: "12px 16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E9EAEB",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Typography
              sx={{ fontSize: "16px", fontWeight: "700", color: "#363E45" }}
            >
              MCQ Question Preview
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
              onClick={handleTryQuestionClick}
            >
              <img src="/assets/Visit copy.svg" alt="check" />
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#ff6812" }}
              >
                Try Question
              </Typography>
            </Box>
          </Box>
          <Box onClick={onClose} sx={{ cursor: "pointer" }}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.00099 18.7008C5.8625 18.7008 5.72712 18.6597 5.61198 18.5828C5.49683 18.5058 5.40708 18.3965 5.35408 18.2685C5.30108 18.1405 5.28722 17.9997 5.31424 17.8639C5.34125 17.7281 5.40794 17.6033 5.50587 17.5053L17.5059 5.5041C17.6373 5.37356 17.8152 5.30045 18.0005 5.30078C18.1857 5.30112 18.3633 5.37487 18.4943 5.50589C18.6253 5.63691 18.6991 5.81451 18.6994 5.9998C18.6997 6.18508 18.6266 6.36295 18.4961 6.49444L6.49611 18.4957C6.43113 18.5608 6.35395 18.6124 6.26899 18.6476C6.18403 18.6828 6.09295 18.7009 6.00099 18.7008Z"
                fill="#71777B"
              />
              <path
                d="M18.001 18.7008C17.909 18.7009 17.818 18.6828 17.733 18.6476C17.648 18.6124 17.5708 18.5608 17.5059 18.4957L5.50587 6.49444C5.37534 6.36295 5.30224 6.18508 5.30258 5.9998C5.30291 5.81451 5.37666 5.63691 5.50766 5.50589C5.63867 5.37487 5.81625 5.30112 6.00152 5.30078C6.18679 5.30045 6.36464 5.37356 6.49611 5.5041L18.4961 17.5053C18.594 17.6033 18.6607 17.7281 18.6877 17.8639C18.7148 17.9997 18.7009 18.1405 18.6479 18.2685C18.5949 18.3965 18.5052 18.5058 18.39 18.5828C18.2749 18.6597 18.1395 18.7008 18.001 18.7008Z"
                fill="#71777B"
              />
            </svg>
          </Box>
        </Box>
        <Box
          sx={{
            padding: "0 16px",
            marginTop: "18px",
            display: "flex",
            flexDirection: "column", // Stack children vertically
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <img src="/assets/question-mark.svg" alt="menu" />
              <Typography
                sx={{
                  fontFamily: "Nunito, sans-serif",
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "24px",
                  color: "#363E45",
                }}
              >
                Question
              </Typography>
            </Box>
            <Divider sx={{ mx: 2, width: "62%" }} />
            <Box
              sx={{
                backgroundColor: "#D3D9E2",
                padding: "4px 8px",
                borderRadius: "4px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "rgba(56, 68, 85, 1)",
                }}
              >
                {question.isHire360Question ? "Hire360 Library" : "My Library"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: "0 16px", marginTop: "24px" }}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                padding: "4px 8px",
                backgroundColor: difficultySettings?.bgcolor,
                borderRadius: "4px",
              }}
            >
              <img src={difficultySettings?.path} alt="difficulty" />
              <Typography
                sx={{ color: difficultySettings?.color, fontSize: "12px" }}
              >
                {difficultySettings?.difficulty}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                color: "#363E45",
                marginLeft: "10px",
              }}
            >
              {question?.questionTitle}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                marginLeft: "auto",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <img src="/assets/health.svg" alt="health" />
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "400", color: "#535A5F" }}
                >
                  Health : {displayScore}/10
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  borderLeft: "1px solid #E8E9EE",
                  paddingLeft: "8px",
                }}
              >
                <Tooltip title="Score" placement="bottom-start" arrow>
                  <img src="/assets/remark.svg" alt="remark" />
                </Tooltip>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "400", color: "#71777B" }}
                >
                  Score : {question?.score}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginTop: "12px" }}>
            {question && (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(question?.question || ""),
                }}
                className="sun-editor-editable"
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                }}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  padding: "12px 0",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Box
                  sx={{
                    display: { md: "flex", base: "none" },
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Tooltip title="Skills" placement="bottom-start" arrow>
                    <img src="/assets/topic.svg" alt="topic" />
                  </Tooltip>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#535A5F",
                    }}
                  >
                    {question?.skills
                      .map((skill) => capitalize(skill.name))
                      .join(", ")}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  padding: "0 0",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Box
                  sx={{
                    display: { md: "flex", base: "none" },
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Tooltip title="Topics" placement="bottom-start" arrow>
                    <img src="/assets/briefcase.svg" alt="topic" />
                  </Tooltip>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#535A5F",
                    }}
                  >
                    {question?.topics
                      .map((topic) => capitalize(topic.name))
                      .join(", ")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            padding: "0 16px",
            marginTop: "24px", // Corrected to have a single marginTop declaration
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <img src="/assets/QuestionPreviewSolution.svg" alt="menu" />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Nunito, sans-serif",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "24px",
                color: "#363E45",
              }}
            >
              Choices
            </Typography>
            <Divider sx={{ flexGrow: 1 }} />
          </Box>
        </Box>

        <Box
          sx={{
            padding: "16px",
            borderRadius: "4px",
          }}
        >
          {question.answers.map((answer, index) => {
            const isHtmlContent = /<\/?[a-z][\s\S]*>/i.test(answer.option);

            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "8px",
                }}
              >
                <Typography variant="body1" sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: "inline",
                      wordBreak: "break-word",
                    }}
                  >
                    <span style={{ marginRight: "8px" }}>{`${
                      index + 1
                    }.`}</span>
                    {isHtmlContent ? (
                      <>
                        {question?.correctAnswers.includes(answer.optionId) && (
                          <Chip
                            label="Correct Answer"
                            sx={{
                              backgroundColor: "#d5f9f1",
                              color: "#00C49A",
                              fontSize: "12px",
                              borderRadius: "4px",
                              height: "24px",
                            }}
                          />
                        )}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(answer.option),
                          }}
                          className="sun-editor-editable"
                          style={{
                            padding: "10px",
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {answer.option}
                        {question?.correctAnswers.includes(answer.optionId) && (
                          <Chip
                            label="Correct Answer"
                            sx={{
                              backgroundColor: "#d5f9f1",
                              color: "#00C49A",
                              fontSize: "12px",
                              borderRadius: "4px",
                              height: "24px",
                              padding: "0 8px",
                              marginLeft: "8px",
                            }}
                          />
                        )}
                      </>
                    )}
                  </Box>
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Drawer>
  );
};

export default QuestionPreview;
