import { Box, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import TabsList from "../../candidateShortlisted/Tabs";
import DashboardSelect from "../../dashboardPage/DashboardSelect";
import { TbTableExport } from "react-icons/tb";
import theme from "../../../theme/theme";
import DataTable from "./DataTable";
import SkillsChart from "./SkillChart";
import TestAttempted from "./TestAttempted";
import useResponsive from "../../../hooks/useResponsive";
import CommonModal from "../../modal/CommonModal";
import APICall from "../../../utils/api";
import { useMessage } from "../../../components/snackbar/snackbar";

const menuItems = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "passed",
    label: "Passed",
  },
  {
    value: "intermediate",
    label: "Intermediate",
  },
  {
    value: "training",
    label: "Training Required",
  },
];

const Card = ({ title, body, fixedHeight = false }) => {
  return (
    <Box
      sx={{
        border: "1px solid #EBEBEB",
        borderRadius: "4px",
        width: "100%",
        height: { base: `${fixedHeight ? "410px" : "auto"}`, sm: "410px" },
        boxShadow: "0px 0px 4px 0px #0000001A",
      }}
    >
      <Box
        sx={{
          height: "48px",
          borderBottom: "1px solid #EBEBEB",
          padding: "16px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "17px",
            color: "#333333",
          }}
        >
          {title}
        </Typography>
      </Box>
      {/* body */}
      <Box sx={{ display: "flex" }}>{body}</Box>
    </Box>
  );
};

const SecondCardBody = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          height: "56px",
          width: "100%",
          padding: "1rem",
          display: "flex",
          alignItems: "center",
          gap: { base: "32px", xss: "64px" },
          bgcolor: "#F7F7F7",
        }}
      >
        {[
          { bg: "#FF9595", t1: "Training Required", t2: "25" },
          { bg: "#EFB02E", t1: "Intermediate", t2: "20" },
          { bg: "#00C49A", t1: "Passed", t2: "120" },
        ].map((item) => {
          return (
            <Box
              sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
              key={item.bg}
            >
              <Box
                sx={{
                  width: "4px",
                  height: "32px",
                  bgcolor: item.bg,
                  borderRadius: "4px",
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#686868",
                  }}
                >
                  {item.t1}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#333",
                  }}
                >
                  {item.t2}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box>
        <SkillsChart />
      </Box>
    </Box>
  );
};

const SkillAnalytics = ({ assessmentId }) => {
  const message = useMessage();
  const [skillQuestionData, setSkillQuestionData] = useState([]);

  const isLargeScreen = useResponsive("up", "lg");
  const allTabItems = [
    { label: "HTML", value: "html" },
    { label: "CSS", value: "css" },
    { label: "Javascript", value: "javascript" },
    { label: "React", value: "react" },
    { label: "Angular", value: "angular" },
  ];
  const [currentTab, setCurrentTab] = useState(allTabItems[0].value);
  const [editScoreOpen, setEditScoreOpen] = useState(false);

  const handleEdit = () => {
    setEditScoreOpen(false);
  };

  const handleEditScore = () => {
    setEditScoreOpen(true);
    handleClose();
  };

  const handleClose = () => {
    setEditScoreOpen(false);
  };

  const fetchSkillAnalytics = async () => {
    let url = `/skillAnalytics/assessment/${assessmentId}`;

    try {
      const res = await APICall(url);
      setSkillQuestionData(res.data.documents);
    } catch (error) {
      message(error.message, "error");
    }
  };

  React.useEffect(() => {
    fetchSkillAnalytics();
  }, []);

  return (
    <Box
      sx={{
        padding: "1rem 0rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "cener",
          gap: "1rem",
          flexDirection: isLargeScreen ? "row" : "column",
        }}
      >
        <Card
          title="Skill / Question Attempt Ratio"
          body={<TestAttempted skillQuestionData={skillQuestionData} />}
        />
        <Card
          fixedHeight={true}
          title="Skill Proficiency"
          body={<SecondCardBody />}
        />
      </Box>
      <TabsList
        value={currentTab}
        tabs={allTabItems}
        handleTabChange={setCurrentTab}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: isLargeScreen ? "center" : "flex-start",
          justifyContent: "space-between",
          width: "100%",
          flexDirection: isLargeScreen ? "row" : "column",
          gap: isLargeScreen ? "0" : "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          {[
            {
              img: "/assets/circlecheck_1.svg",
              bg: "#439D621A",
              t1: "10",
              t2: "Passed",
            },
            {
              img: "/assets/circleminus_1.svg",
              bg: "#F9E8A480",
              t1: "15",
              t2: "Intermediate",
            },
            {
              img: "/assets/circleminus.svg",
              bg: "#FFF0E7",
              t1: "05",
              t2: "Training Required",
            },
          ].map((item) => {
            return (
              <Box
                key={item.bg}
                sx={{
                  border: "1px solid #E8E9EE",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Box
                  sx={{
                    bgcolor: item.bg,
                    padding: "4px",
                    borderRadius: "4px",
                    height: "24px",
                    width: "24px",
                  }}
                >
                  <img src={item.img} alt="circle" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Nunito",
                      fontWeight: 700,
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#363E45",
                    }}
                  >
                    {item.t1}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#636A75",
                    }}
                  >
                    {item.t2}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img src="/assets/remark.svg" alt="remark" />
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#363E45",
              }}
            >
              {`15/30`}
            </Typography>
            <Box
              sx={{ marginLeft: "2px", marginTop: "2px", cursor: "pointer" }}
              onClick={handleEditScore}
            >
              <img src="/assets/edit_3.svg" alt="edit" />
            </Box>
          </Box>
          <Box sx={{ width: "150px" }}>
            <DashboardSelect menuItems={menuItems} handleChange={() => {}} />
          </Box>
          <Tooltip title="Export" arrow>
            <span>
              <TbTableExport
                size={22}
                color={theme.palette.secondary.main}
                fontWeight={400}
              />
            </span>
          </Tooltip>
        </Box>
      </Box>
      <DataTable />

      <CommonModal
        icon={"/assets/delete_icon.svg"}
        open={editScoreOpen}
        title={"Edit Score"}
        onSubmit={handleEdit}
        sendButtonName={"Yes"}
        cancelButtonName={"No"}
        handleClose={() => setEditScoreOpen(false)}
      >
        <Typography
          style={{
            backgroundColor: "#fff0e7",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <img
            src="/assets/alert-1.svg"
            alt="Alert"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          />
          <strong>Are you certain you wish to delete this instruction?</strong>
        </Typography>
      </CommonModal>
    </Box>
  );
};

export default SkillAnalytics;
