import {
  Box,
  Divider,
  Stack,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { HiOutlineBriefcase } from "react-icons/hi";
import { LuUsers } from "react-icons/lu";
import { styled } from "@mui/system";
import { getColorByStatus, capitalizeWords } from "../../utils";

const useStyles = makeStyles((theme) => ({
  candidateName: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    fontFamily: "Roboto",
    color: "#313131",
  },
  candidateEmail: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    fontFamily: "Roboto",
    color: "#535A5F",
    height: "16px",
  },
}));
const CandidateName = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "20px",
  fontFamily: "Roboto",
  color: "#313131",
}));

const CandidateEmail = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14px",
  fontFamily: "Roboto",
  color: "#535A5F",
  height: "16px",
}));

const Round2Candidatecard = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      alignItems={"center"}
      sx={{
        padding: "16px",
        backgroundColor: "#FFFFFF",
        gap: "12px",
        border: "1px solid #E8E9EE",
        borderRadius: "4px",
        minHeight: "100px",
      }}
    >
      {/* left section */}
      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        gap={"12px"}
        flexGrow={1}
        sx={{ padding: 0, gap: "12px" }}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"12px"}
        >
          <Box
            component={"img"}
            src={data?.candidateProfilePicture}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/user.png";
            }}
            sx={{ borderRadius: "50%" }}
            width={"40px"}
            height={"40px"}
          />
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            gap={"4px"}
            sx={{ gap: "4px" }}
          >
            <CandidateName
              onClick={() =>
                navigate(`/assessments/candidateDetails`, {
                  state: {
                    candidateAssessmentId: data?.candidateAssessmentId,
                    assessmentId: data?.assessmentId?._id,
                    assessmentDetails: data?.assessmentId,
                  },
                })
              }
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              {data?.candidateId?.name}
            </CandidateName>
            <CandidateEmail>{data?.candidateId?.email}</CandidateEmail>
          </Stack>
        </Stack>

        <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"8px"}
            sx={{ textWrap: "nowrap" }}
          >
            <Box
              //   component={"img"}
              //   src="/assets/icons/briefcase.svg"
              width={"16px"}
              height={"16px"}
              color={"#69778A"}
              sx={{ color: "#69778A" }}
            >
              <HiOutlineBriefcase size={16} color="#69778A" />
            </Box>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: " 12px",
                lineHeight: "16px",
                color: "#535A5F",
              }}
            >
              {capitalizeWords(data?.jobRoleDetails?.jobRole)}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"8px"}
            sx={{ textWrap: "nowrap" }}
          >
            <Tooltip title="Scheduled by" placement="bottom" arrow>
              <Box width={"16px"} height={"16px"}>
                <LuUsers size={16} color="#69778A" />
              </Box>
            </Tooltip>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",
                color: "#535A5F",
              }}
            >
              {data?.InterviewDetails?.round2?.Scheduler?.name
                ? data.InterviewDetails.round2.Scheduler.name
                : data?.InterviewDetails?.round1?.Scheduler?.name}
            </Typography>
            <Tooltip title="Ping Scheduler" arrow>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <img src="/assets/Vector-5.svg" alt="Publisher" />
              </Box>
            </Tooltip>{" "}
          </Stack>
        </Stack>
      </Stack>

      {/* right section */}
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"12px"}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          gap={"2px"}
          sx={{ padding: "0" }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "32px",
              color: "#333333",
            }}
          >
            {data?.assessmentId?.totalScore && data?.totalObtainedScore
              ? (
                  (data.totalObtainedScore / data.assessmentId.totalScore) *
                  100
                ).toFixed(0)
              : "0"}
            %
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "16px",
              color: "#535A5F",
              textWrap: "nowrap",
            }}
          >
            Test Score
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          gap={"2px"}
          justifyContent={"center"}
          sx={{
            padding: "4px 12px",
            gap: "4px",
            backgroundColor: getColorByStatus(data?.currentStatus).bg,
            borderRadius: "20px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: getColorByStatus(data?.currentStatus).color,
              textWrap: "nowrap",
            }}
          >
            {data?.currentStatus?.replace(/R1|R2/gi, "").trim()}{" "}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Round2Candidatecard;
