import { Box, Typography } from "@mui/material";
import React from "react";
import CustomTable from "../common/customTable/CustomTable";
import DifficultyIcon from "./DifficultyIcon";
import { question_table_one } from "../../utils/data";
const columnsWidth = ["60px", "244px", "244px", "320px", "244px"];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const tableBodyRowStyleProps = {
  height: "48px",
};

const SectionTable = ({ data, topic }) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return null;
  }

  const tableHeaderTitles = [
    {
      title: "Sr. no",
      cellStyleProps: {
        borderRight: "1px solid #E8E9EE",
        width: columnsWidth[0],
      },
    },
    {
      title: "Section Name",
      cellStyleProps: {
        width: columnsWidth[1],
      },
    },
    {
      title: `Questions (${data.reduce((acc, total) => acc + total.total, 0)})`,
      cellStyleProps: {
        width: columnsWidth[2],
      },
    },
    {
      title: "Difficulty",
      cellStyleProps: {
        width: columnsWidth[3],
      },
    },
    {
      title: "Score",
      cellStyleProps: {
        width: columnsWidth[4],
      },
    },
  ];

  const filteredData =
    topic.toLowerCase() === "all"
      ? data.filter((row) => row.total > 0)
      : data.filter((row) => row.section.toLowerCase() === topic.toLowerCase());

  if (filteredData.length === 0) {
    return null;
  }

  const tableHeaderCells = () => {
    return tableHeaderTitles.map((item) => {
      return {
        element: item.title,
        cellStyleProps: item.cellStyleProps,
        isSortable: item?.isSortable ? item.isSortable : false,
        cellId: item?.cellId ? item.cellId : "",
      };
    });
  };

  const tableBodyRowCell = (row, idx, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          borderRight: "1px solid #E8E9EE",
          ...cellStyleProps,
        },
        element: (
          <Typography sx={tableContentCellTextStyleProps}>{idx + 1}</Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Typography sx={tableContentCellTextStyleProps}>
            {row.section}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Typography sx={tableContentCellTextStyleProps}>
            {row.total}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {getDifficultyTag("#439D62", "#439D621A", `Easy (${row.easy})`)}
            {getDifficultyTag("#FF9736", "#FF97361A", `Medium (${row.medium})`)}
            {getDifficultyTag("#F94948", "#F949481A", `Hard (${row.hard})`)}
          </Box>
        ),
      },
      {
        cellStyleProps: {
          ...cellStyleProps,
        },
        element: (
          <Typography sx={tableContentCellTextStyleProps}>
            {row.totalScore}
          </Typography>
        ),
      },
    ];
  };

  const tableBodyContent = () => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };
    const filteredDataForTable = filteredData?.filter((row) => row.total > 0);
    return filteredDataForTable.map((row, idx) => {
      return {
        rowElement: tableBodyRowCell(row, idx, cellStyleProps),
      };
    });
  };

  const getDifficultyTag = (color, bg, text) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: bg,
          padding: "2px 8px",
          color: color,
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "16px",
          fontFamily: "Roboto",
          borderRadius: "0.25rem",
          whiteSpace: "nowrap",
          height: "20px",
        }}
      >
        <DifficultyIcon color={color} />
        {text}
      </Box>
    );
  };

  return (
    <CustomTable
      tableHeaderCells={tableHeaderCells()}
      tableBodyContent={tableBodyContent()}
      tableData={data}
      sortRows={() => {}}
      tableBodyRowStyleProps={tableBodyRowStyleProps}
      tableHeaderCellStyleProps={tableHeaderCellStyleProps}
      isPaginationVisible={false}
    />
  );
};

export default SectionTable;
