import { SvgIcon } from "@mui/material";
import React from "react";

const ChevronUpIcon = ({ fill = "#71777B" }) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9998 13.085C14.9232 13.085 14.8473 13.07 14.7765 13.0406C14.7057 13.0113 14.6414 12.9683 14.5872 12.9141L9.99934 8.32665L5.41147 12.9141C5.30189 13.0228 5.15367 13.0838 4.99926 13.0835C4.84485 13.0832 4.69685 13.0217 4.58767 12.9126C4.47849 12.8034 4.41703 12.6554 4.41675 12.501C4.41647 12.3466 4.4774 12.1984 4.58618 12.0889L9.58669 7.08886C9.69614 6.97944 9.84457 6.91797 9.99934 6.91797C10.1541 6.91797 10.3025 6.97944 10.412 7.08886L15.4125 12.0889C15.4941 12.1705 15.5497 12.2744 15.5722 12.3876C15.5947 12.5008 15.5832 12.6181 15.539 12.7248C15.4948 12.8314 15.42 12.9225 15.3241 12.9866C15.2281 13.0507 15.1153 13.085 14.9998 13.085Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default ChevronUpIcon;
