import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Snackbar,
  Alert as MuiAlert,
  Checkbox,
  TextField,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import SearchBar from "../common/SearchBar";
import theme from "../../theme/theme";
import { AuthContext } from "../../context/authContext";
import { CheckBox } from "@mui/icons-material";
import ExcelJS from "exceljs";

// import CandidateToggleSquareIcons from "../../components/common/CandidateToggleSquareIcons";
import Iconify from "../../components/iconify/Iconify";
import IconButton from "@mui/material/IconButton";
import { useFormik } from "formik";
import CommonModal from "../../components/modal/CommonModal";
import * as yup from "yup";
import { useMessage } from "../../components/snackbar/snackbar";
import ToggleSquareIcons from "../common/ToggleSquareIcons";
import APICall from "../../utils/api";

const TestTakenSearchHeader = ({
  setGridView,
  gridView,
  tableParams,
  setTableParams,
  totalDocuments,
  assessmentId,
  fetchAssessment,
  totalPending,
  setSelectedCheckboxes,
  selectedCheckboxes,
  updateManyStatus,
  fetchAssessmentDetails,
  currentTab,
  data,
}) => {
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const { authUser } = useContext(AuthContext);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [openUpdateCutOffModal, setOpenUpdateCutOffModal] = useState(false);
  const [cutOff, SetCutOff] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState("");

  const message = useMessage();

  const handleStatusChange = (event) => {
    setTableParams({
      ...tableParams,
      filters: { ...tableParams.filters, currentStatus: event.target.value },
      pagination: { ...tableParams.pagination, page: 1 },
    });
  };

  const handleFilterOpen = () => {
    setIsFilterOpen(true);
  };

  const handleCloseAddOrEdit = () => {
    setOpenUpdateCutOffModal(false);
  };
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };
  const handleSelectAll = () => {
    if (
      selectedCheckboxes.length ===
      data?.filter((row) => row.currentStatus === "Appeared").length
    ) {
      setSelectedCheckboxes([]);
    } else {
      const allIds = data
        ?.filter((row) => row.currentStatus === "Appeared")
        .map((row) => row.candidateAssessmentId);
      setSelectedCheckboxes(allIds);
    }
  };

  const fetchData = async () => {
    try {
      const res = await APICall(`/assessment/${assessmentId}`);
      console.log(res.data.cutOff);

      SetCutOff(res.data.cutOff);
      formik.setFieldValue("cutOff", res.data.cutOff);
      formik.setFieldValue("totalScore", res.data.totalScore);
    } catch (error) {
      console.error("Failed to fetch assessment details:", error);
    }
  };

  const handleInvitesReportsStatsExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    worksheet.columns = [
      { header: "Channel Name", key: "channel", width: 15 },
      { header: "Total Invites", key: "totalInvites", width: 15 },
      { header: "Used Invites", key: "usedInvites", width: 15 },
    ];

    let totalInvites = 0;
    let usedInvites = 0;

    // Make API call to get the data
    const response = await APICall("/stats/super-admin/invites/reports/export");
    const invitesReportsData = response.data;

    invitesReportsData.forEach((data) => {
      worksheet.addRow({
        channel: data.channelName,
        totalInvites: data.stats.totalInvites,
        usedInvites: data.stats.usedInvites,
      });

      totalInvites += data.stats.totalInvites;
      usedInvites += data.stats.usedInvites;
    });

    // Add total row
    worksheet.addRow({
      channel: "Total",
      totalInvites: totalInvites,
      usedInvites: usedInvites,
    });

    await workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Invites-Reports.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  };

  const handleSubmit = async (values) => {
    setOpenUpdateCutOffModal(false);
    try {
      const res = await APICall(
        `/Assessment/${assessmentId}`,
        formik.values,
        "patch"
      );

      message("Cutoff updated successfully !!", "success");
      fetchAssessmentDetails();
    } catch (error) {
      console.log(error, "error");
    }

    fetchAssessment();
  };

  const validationSchema = yup.object({
    cutOff: yup
      .string()
      .required("CutOff is required")
      .test(
        "is-less-than-totalScore",
        "CutOff cannot be more than Total Score",
        function (value) {
          const { totalScore } = this.parent;
          return !totalScore || parseFloat(value) <= parseFloat(totalScore);
        }
      ),
    totalScore: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      cutOff: cutOff || 0,
      totalScore: 0,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleFilterClose = () => {
    setIsFilterOpen(false);
  };

  const handleExportCandidates = async () => {
    try {
      // Fetch data from API
      const response = await APICall(
        `/candidateAssessment/assessment/${assessmentId}/export`
      );

      const data = await response.data.documents;
      console.log("API Data:", data); // Debug logging

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Candidates");

      // Add total candidates count before setting columns
      worksheet.spliceRows(1, 0, []);

      // Define columns
      worksheet.columns = [
        { header: "Srl No", key: "serialNumber", width: 10 },
        { header: "Candidate Name", key: "candidateName", width: 20 },
        { header: "Email ID", key: "email", width: 25 },
        { header: "Score", key: "score", width: 10 },
        { header: "Percentile", key: "scorePercentage", width: 15 },
        { header: "Section Score", key: "sectionScore", width: 20 },
        { header: "Status", key: "status", width: 15 },
        { header: "Test Duration", key: "testDuration", width: 20 },
      ];

      // Add data rows
      data.forEach((row, index) => {
        worksheet.addRow({
          serialNumber: index + 1,
          candidateName: row?.candidateDetails?.name || "N/A",
          email: row?.candidateDetails?.email || "N/A",
          score:
            `${row?.assessmentResult?.totalObtainedScore} / ${row.assessmentDetails.totalScore}` ||
            "0",
          scorePercentage:
            row?.assessmentDetails?.totalScore &&
            row?.assessmentResult?.totalObtainedScore
              ? (
                  (row.assessmentResult.totalScore /
                    row.assessmentDetails.totalScore) *
                  100
                ).toFixed(2)
              : "0",
          sectionScore: row?.assessmentResult?.sectionScores || "0",
          status:
            row?.currentStatus === "Appeared"
              ? row?.assessmentResult.totalScore >
                row?.assessmentDetails?.cutOff
                ? "Passed"
                : "Failed"
              : row?.currentStatus,
          testDuration: row?.assessmentResult?.totalTimeSpent
            ? `${Math.round(row.assessmentResult.totalTimeSpent / 60)} minutes`
            : "N/A",
        });
      });

      worksheet.addRow([`Total Candidates: ${response.data.total}`]).font = {
        bold: true,
      };

      // Generate the file
      const fileName = "Candidates.xlsx";
      await workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    } catch (error) {
      console.error("Error exporting candidates:", error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDownloadSuccess(false);
  };

  return (
    <Box>
      {/* for desktop */}
      <Grid
        container
        spacing={1}
        sx={{ mt: "0.1rem", display: { base: "none", md: "flex" } }}
      >
        <Grid item base={6} lg={7} xl={8}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#010101",
                fontFamily: "Nunito",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* {authUser.role === "recruiter" && (
                  <>
                    <Checkbox
                      checked={
                        selectedCheckboxes.length ===
                          data?.filter(
                            (row) => row.currentStatus === "Appeared"
                          ).length && selectedCheckboxes.length > 0
                      }
                      onChange={handleSelectAll}
                    />
                    <Typography>{`Review Pending Candidates (${
                      totalPending || 0
                    })`}</Typography>
                  </>
                )} */}
                {/* {(authUser?.role === "super admin" ||
                  authUser?.role === "hr") && ( */}
                <Typography>Total Candidates ({totalDocuments})</Typography>
                {/* )} */}
              </Box>
            </Typography>
            <SearchBar
              placeholder="Search Candidate"
              value={tableParams.filters.query}
              onChange={(e) =>
                setTableParams({
                  ...tableParams,
                  filters: { ...tableParams.filters, query: e.target.value },
                  pagination: { ...tableParams.pagination, page: 1 },
                })
              }
              width="296px"
            />
            {selectedCheckboxes.length !== 0 && (
              <>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "4px",
                    color: "white",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    textTransform: "none",
                    padding: "6px 12px",
                  }}
                  onClick={() =>
                    updateManyStatus(selectedCheckboxes, "Reviewed")
                  }
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "white",
                      textWrap: "nowrap",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/assets/icons/userPlusWhite.svg"
                      alt="check"
                      style={{ marginRight: "4px" }}
                    />
                    Reviewed
                  </Typography>
                </Button>
                <Button
                  sx={{
                    borderRadius: "4px",
                    color: "white",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    textTransform: "none",
                    padding: "6px 12px",
                    border: "1px solid #FF6812",
                  }}
                  onClick={() =>
                    updateManyStatus(selectedCheckboxes, "Shortlisted")
                  }
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#FF6812",
                      textWrap: "nowrap",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/assets/icons/userPlusorange.svg"
                      alt="check"
                      style={{ marginRight: "4px" }}
                    />
                    Shortlist
                  </Typography>
                </Button>
              </>
            )}
          </Box>
        </Grid>
        <Grid
          item
          base={6}
          lg={5}
          xl={4}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {(currentTab !== "reviewed" || currentTab !== "shortlisted") && (
              <FormControl
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  height: "38px",
                  width: "150px",
                  ...((authUser?.role === "super admin" ||
                    authUser?.role === "hr") && {
                    marginLeft: "150px",
                  }),
                }}
              >
                <InputLabel
                  sx={{
                    letterSpacing: "0.5px",
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  sx={{
                    height: "38px",
                    width: "100%",
                  }}
                  value={tableParams.filters.currentStatus}
                  label="status"
                  onChange={handleStatusChange}
                  inputProps={{ className: "select-padding" }}
                >
                  <MenuItem value="Appeared">All</MenuItem>
                  <MenuItem value="Passed">Passed</MenuItem>

                  <MenuItem value="Failed">Failed</MenuItem>
                  <MenuItem value="Shortlisted">Shortlisted</MenuItem>
                  <MenuItem value="Reviewed">Reviewed</MenuItem>
                  {/* <MenuItem value="Shortlisted">Shortlisted</MenuItem>
                  <MenuItem value="Reviewed">Reviewed</MenuItem> */}
                </Select>
              </FormControl>
            )}

            {authUser.role === "recruiter" && (
              <Button
                onClick={() => {
                  setOpenUpdateCutOffModal(!openUpdateCutOffModal);

                  fetchData();
                }}
                variant="contained"
                sx={{
                  minWidth: "137px",
                  color: theme.palette.background.default,
                  padding: "10px 10px 10px 12px ",
                  height: "36px",
                  width: "50%",
                  lineHeight: "20px",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  borderRadius: "0.25rem",
                }}
                color="orangeButton"
                size="large"
                startIcon={<Box component={"img"} src="/assets/cutoff.svg" />}
              >
                Update Cut off
              </Button>
            )}

            <Button
              variant="contained"
              onClick={handleExportCandidates}
              sx={{
                backgroundColor: theme.palette.green.secondaryGreen,
                minWidth: "91px",
                color: theme.palette.background.default,
                padding: "10px 10px 10px 12px ",
                height: "36px",
                width: "30%",
                lineHeight: "20px",
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: "400",
                borderRadius: "0.25rem",
              }}
              color="greenButton"
              size="large"
              startIcon={
                <Box component={"img"} src="/assets/export_icon.svg" />
              }
            >
              Export
            </Button>

            {authUser.role === "recruiter" && (
              <>
                <ToggleSquareIcons
                  setShortCandidates={setGridView}
                  shortCandidates={gridView}
                />
              </>
            )}

            <Snackbar
              open={downloadSuccess}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleClose}
                severity="success"
              >
                Downloaded successfully !!
              </MuiAlert>
            </Snackbar>
          </Box>
        </Grid>
        <CommonModal
          icon={"/assets/icons/briefcaseBlack.svg"}
          handleClose={handleCloseAddOrEdit}
          open={openUpdateCutOffModal}
          title="Update Cut-off"
          sendButtonName="Save"
          onSubmit={formik.handleSubmit}
        >
          <TextField
            sx={{ marginBottom: "16px" }}
            fullWidth
            size="small"
            id="cutOff"
            label="Cut off"
            name="cutOff"
            value={formik.values.cutOff}
            onChange={formik.handleChange}
            error={formik.touched.cutOff && Boolean(formik.errors.cutOff)}
            helperText={formik.touched.cutOff && formik.errors.cutOff}
            required
          />
        </CommonModal>
      </Grid>
      {/* for tablet/mobile */}
      <Box
        sx={{
          my: "1rem",
          ml: "0.25rem",
          display: { base: "block", md: "none" },
        }}
      >
        <Typography
          sx={{
            whiteSpace: "nowrap",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#010101",
            fontFamily: "Nunito",
          }}
        >
          Candidates
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{ display: { base: "flex", md: "none" } }}
      >
        <Grid item base={12}>
          <SearchBar
            width="100%"
            placeholder="Search Candidate"
            value={tableParams.filters.query}
            onChange={(e) =>
              setTableParams({
                ...tableParams,
                filters: { ...tableParams.filters, query: e.target.value },
                pagination: { ...tableParams.pagination, page: 1 },
              })
            }
          />
        </Grid>
        <Grid item base={12} sm={4}>
          <FormControl
            fullWidth
            sx={{
              backgroundColor: "#fff",
              height: "40px",
              // width: "100%",
            }}
          >
            <InputLabel
              sx={{
                letterSpacing: "0.5px",
              }}
            >
              Status
            </InputLabel>
            <Select
              sx={{
                "& .select-padding": {
                  padding: "10px 8px", // Adjust padding as needed
                },
                height: "40px",
              }}
              value={tableParams.filters.currentStatus}
              label="chanel"
              onChange={handleStatusChange}
              inputProps={{ className: "select-padding" }}
            >
              <MenuItem value="Appeared">All</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item base={12} sm={8}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {authUser.role === "recruiter" && (
              <Button
                // onClick={() => setToggleDrawer((prev) => !prev)}
                variant="contained"
                sx={{
                  minWidth: "137px",
                  color: theme.palette.background.default,
                  padding: "10px 10px 10px 12px ",
                  height: "36px",
                  width: "100%",
                  lineHeight: "20px",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  borderRadius: "0.25rem",
                }}
                color="orangeButton"
                size="large"
                startIcon={<Box component={"img"} src="/assets/cutoff.svg" />}
              >
                Update Cutoff
              </Button>
            )}
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.green.secondaryGreen,
                minWidth: "91px",
                color: theme.palette.background.default,
                padding: "10px 10px 10px 12px ",
                height: "36px",
                width: "100%",
                lineHeight: "20px",
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: "400",
                borderRadius: "0.25rem",
              }}
              color="greenButton"
              size="large"
              startIcon={
                <Box component={"img"} src="/assets/export_icon.svg" />
              }
            >
              Export
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TestTakenSearchHeader;
