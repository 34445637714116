import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { IconButton, Box } from "@mui/material";
import { CSS } from "@dnd-kit/utilities";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export const SortableItem = ({ id, children, isEditing }) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: {
      duration: 150,
      easing: "cubic-bezier(0.25, 1, 0.5, 1)",
    },
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Box display="flex" alignItems="center">
        {isEditing && (
          <IconButton {...listeners} sx={{ cursor: "grab" }}>
            <img src="/assets/drag.svg" style={{ height: "18px" }} />
          </IconButton>
        )}

        <Box flexGrow={1}>{children}</Box>
      </Box>
    </div>
  );
};
