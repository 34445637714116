import React from "react";
import {
  Box,
  Typography,
  Modal,
  Divider,
  Button,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Iconify from "../iconify/Iconify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 440,
  boxShadow: 24,
  borderRadius: "4px",
  bgcolor: "background.paper",
};

const useStyles = makeStyles((theme) => ({
  headingSection: {
    display: "flex",
    padding: "20px 20px 0px",
    justifyContent: "space-between",
  },
  headingIcon: {
    height: "36px",
    width: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "12px",
    borderRadius: "50%",
    zIndex: -1,
  },
  headingTitle: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "700 !important",
    lineHeight: "20px !important",
    color: "#363E45 !important",
    display: "flex",
    alignItems: "center",
  },
  headingWrapper: {
    display: "flex",
  },
  headingCloseIcon: {
    cursor: "pointer",
  },
  modalActions: {
    margin: "10px 20px -28px 20px",
  },
  modalContent: {
    margin: "28px 20px 28px",
    maxHeight: "calc(100vh - 200px)",
    "&::-webkit-scrollbar": {
      height: "4px",
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E8E9EE",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#FF6812",
      borderRadius: "8px",
      width: "1px",
    },
  },

  modalButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    padding: "12px 20px 12px",
  },
  modalSubmit: {
    color: theme.palette.background.default,
  },
  backdrop: {
    backdropFilter: "blur(2px)",
    backgroundColor: "rgba(49, 62, 79, 0.44)",
  },
}));

const CommonModal = ({
  open,
  handleClose,
  RequestReschedule = false,
  handleRequestReschedule,
  RequestRescheduleButton,
  inviteCancelButton,
  inviteCancel,
  inviteCancelButtonText,
  showInviteCancelButton,
  iconName,
  iconColor,
  rescheduleHeaderBackground,
  borderColor,
  onButtonClick,
  title,
  onSubmit,
  icon,
  cancelButtonName,
  hideCancel,
  sendButtonName,
  children,
  isDeleting,
  width,
  height,
  modalActions,
  isScrollable,
  bulkInvite,
  showBulkInvite,
  showFooter = true,
  headingIconBackgroundColor = false,
  hideSubmit,
  showRescheduleFooter,
  startInterviewTime,
  endInterviewTime,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const headingIconBgColor = RequestReschedule
    ? rescheduleHeaderBackground
    : sendButtonName === "Ping" || isDeleting
    ? "#fff0e7"
    : theme.palette.green.light;
  const handleButtonClick = () => {
    if (RequestRescheduleButton === "Delete") {
      // Handle "Delete" logic
      if (onButtonClick) onButtonClick(); // Optionally handle Delete action
    }
    if (RequestRescheduleButton === "Revert") {
      // Handle "Delete" logic
      if (onButtonClick) onButtonClick();
    }
    if (RequestRescheduleButton === "Request Reschedule") {
      if (handleRequestReschedule) handleRequestReschedule();
    }
    if (RequestRescheduleButton === "Cancel") {
      if (handleClose) handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <Box sx={style} width={width ?? width}>
        <Box
          className={classes.headingSection}
          sx={{
            backgroundColor:
              title === "Edit Slot Details" || title === "Request Reschedule"
                ? "#FFFFFF"
                : rescheduleHeaderBackground,
            padding: RequestReschedule
              ? "14px 20px !important"
              : "20px 20px 0px",
          }}
        >
          <Box className={classes.headingWrapper}>
            <Box
              className={classes.headingIcon}
              style={{
                background: headingIconBackgroundColor
                  ? headingIconBackgroundColor
                  : headingIconBgColor,
                border: RequestReschedule
                  ? ` 1.17px solid ${borderColor}`
                  : "none",
                zIndex: 0,
              }}
            >
              {RequestReschedule ? (
                <Iconify icon={iconName} color={iconColor} width={"20px"} />
              ) : (
                <Box
                  width={"20px"}
                  height={"20px"}
                  component={"img"}
                  src={icon ?? "/assets/icons/briefcase.png"}
                />
              )}
            </Box>
            <Typography className={classes.headingTitle} id="modal-modal-title">
              {title}
            </Typography>
          </Box>
          <Box
            className={classes.headingCloseIcon}
            component={"img"}
            onClick={handleClose}
            src="/assets/icons/x.svg"
          />
        </Box>
        {modalActions && (
          <Box className={classes.modalActions}>{modalActions}</Box>
        )}
        <Box
          className={classes.modalContent}
          style={isScrollable ? { overflowY: "auto" } : {}}
          height={height ?? height}
        >
          {children}
        </Box>
        {showFooter && (
          <>
            <Divider />
            <Box>
              <Box className={classes.modalButton}>
                {showInviteCancelButton === true && (
                  <Button
                    onClick={() => {
                      if (inviteCancel) inviteCancel();
                    }}
                    variant="outlined"
                    color="orangeButton"
                    sx={{ height: "35px" }}
                  >
                    {inviteCancelButtonText ?? "Cancel"}
                  </Button>
                )}
                {RequestReschedule &&
                  !(
                    RequestRescheduleButton === "Request Reschedule" &&
                    new Date().setHours(0, 0, 0, 0) >
                      new Date(endInterviewTime).setHours(23, 59, 59, 999)
                  ) && (
                    <Button
                      onClick={handleButtonClick}
                      variant="outlined"
                      color="orangeButton"
                      sx={{ height: "35px" }}
                    >
                      {RequestRescheduleButton ?? "Cancel"}
                    </Button>
                  )}

                {hideCancel !== true && (
                  <>
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      color="orangeButton"
                      sx={{ height: "35px" }}
                    >
                      {cancelButtonName ?? "Cancel"}
                    </Button>
                  </>
                )}
                {bulkInvite === true && (
                  <Button
                    onClick={showBulkInvite}
                    variant="outlined"
                    color="orangeButton"
                    sx={{ height: "35px" }}
                  >
                    Send Bulk Invites
                  </Button>
                )}
                {/* {hideSubmit !== true &&
                  !(
                    sendButtonName === "Start Round" &&
                    new Date().setHours(0, 0, 0, 0) >
                      new Date(startInterviewTime).setHours(23, 59, 59, 999)
                  ) && (
                    <Button
                      onClick={(event) => {
                        event.preventDefault();
                        onSubmit();
                      }}
                      variant="contained"
                      className={classes.modalSubmit}
                      sx={{
                        backgroundColor:
                          sendButtonName === "Cancelled"
                            ? "#FF6812"
                            : "greenButton",
                        color: "white",
                        height: "35px",
                        "&:hover": {
                          backgroundColor:
                            sendButtonName === "Cancelled"
                              ? "#FF6812"
                              : "green",
                        },
                      }}
                      disabled={
                        (sendButtonName === "Start Round" &&
                          new Date().setHours(0, 0, 0, 0) <
                            new Date(startInterviewTime).setHours(
                              0,
                              0,
                              0,
                              0
                            )) ||
                        new Date().setHours(0, 0, 0, 0) >
                          new Date(startInterviewTime).setHours(23, 59, 59, 999)
                      }
                    >
                      {sendButtonName === "Start Round" ? (
                        <>
                          <img
                            src="/assets/video.svg"
                            alt="Start Round"
                            style={{ marginRight: "5px" }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {sendButtonName ?? "Add"}
                    </Button>
                  )} */}

                {hideSubmit !== true &&
                  !(
                    sendButtonName === "Start Round" &&
                    new Date().setHours(0, 0, 0, 0) >
                      new Date(startInterviewTime).setHours(23, 59, 59, 999)
                  ) && (
                    <Button
                      onClick={(event) => {
                        event.preventDefault();
                        onSubmit();
                      }}
                      variant="contained"
                      className={classes.modalSubmit}
                      sx={{
                        backgroundColor:
                          sendButtonName === "Cancelled"
                            ? "#FF6812"
                            : "greenButton",
                        color: "white",
                        height: "35px",
                        "&:hover": {
                          backgroundColor:
                            sendButtonName === "Cancelled"
                              ? "#FF6812"
                              : "green",
                        },
                      }}
                      disabled={
                        (sendButtonName === "Start Round" &&
                          (() => {
                            const now = new Date();
                            const startTime = new Date(startInterviewTime);
                            const endTime = new Date(endInterviewTime);
                            const diffToStart = startTime - now;

                            // Enable button if within 5 minutes before start and up to end time
                            return diffToStart > 300000 || now > endTime;
                          })()) ||
                        new Date().setHours(0, 0, 0, 0) >
                          new Date(startInterviewTime).setHours(23, 59, 59, 999)
                      }
                    >
                      {sendButtonName === "Start Round" ? (
                        <>
                          <img
                            src="/assets/video.svg"
                            alt="Start Round"
                            style={{ marginRight: "5px" }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {sendButtonName ?? "Add"}
                    </Button>
                  )}
              </Box>
            </Box>
          </>
        )}

        {showRescheduleFooter && (
          <>
            <Divider />
            <Box>
              <Box className={classes.modalButton}>
                {showInviteCancelButton === true && (
                  <Button
                    onClick={() => {
                      if (inviteCancel) inviteCancel();
                    }}
                    variant="outlined"
                    color="orangeButton"
                    sx={{ height: "35px" }}
                  >
                    {inviteCancelButtonText ?? "Cancel"}
                  </Button>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CommonModal;
