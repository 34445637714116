import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControlLabel,
  FormGroup,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchBar from "../common/SearchBar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../../theme/theme";

const AssesmentFilter = ({
  open,
  onClose,
  filters,
  setFilters,
  filterLoading,
  setFilterLoading,
  handleApplyFilter,
  openAccordion,
  resetFilters,
}) => {
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(openAccordion);

  useEffect(() => {
    if (openAccordion) {
      setExpanded(openAccordion);
    }
  }, [openAccordion]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleApply = () => {
    setLoading(true);
    setTimeout(() => {
      handleApplyFilter();
      setLoading(false);
    }, 2000);
    filters.forEach((filter) => {
      if (filter.searchValue !== undefined) {
        //reset searchbar
        filter.searchValue = "";
      }
    });
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ backdropFilter: "blur(3px)" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box sx={{ width: "350px" }}>
          <Box
            sx={{
              padding: "12px 16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 1,
              borderBottom: "1px solid #E9EAEB",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: "700", color: "#363E45" }}
            >
              Sort By
            </Typography>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={resetFilters}
                sx={{ textTransform: "none", height: "30px" }}
              >
                Remove Filters
              </Button>
              <Button
                variant="contained"
                onClick={handleApply}
                sx={{
                  backgroundColor: "#00C49A",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "400",
                  height: "30px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#008d7a",
                  },
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>
          <Box sx={{ gap: "1px", display: "flex", flexDirection: "column" }}>
            {filters?.map((item, index) => (
              <Accordion
                key={index}
                square={true}
                sx={{ border: "none", boxShadow: "none" }}
                expanded={expanded === item.label}
                onChange={handleAccordionChange(item.label)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#363E45",
                    fontFamily: "",
                    borderBottom: "1px solid #E8E9EE",
                  }}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {`${item.label}${item.total ? ` (${item.total})` : ""}`}
                </AccordionSummary>
                <AccordionDetails>
                  {item.isSearchBar && (
                    <Box sx={{ marginBottom: "16px" }}>
                      <SearchBar
                        width="100%"
                        onChange={(e) => {
                          setTimeout(() => {
                            item.onSearch(e.target.value);
                          }, 1000);
                          setFilters((prev) => {
                            const newFilters = [...prev];
                            const currentFilter = newFilters[index];
                            currentFilter.searchValue = e.target.value;
                            return newFilters;
                          });
                        }}
                        value={item.searchValue}
                      />
                    </Box>
                  )}
                  <FormGroup>
                    {item.checkBox
                      ?.filter(
                        (elem, i, self) =>
                          self.findIndex((cb) => cb.value === elem.value) === i
                      )
                      ?.map((elem, i) => (
                        <FormControlLabel
                          sx={{ marginTop: "14px" }}
                          key={i}
                          control={
                            <Checkbox
                              title="test"
                              icon={
                                <Box
                                  width={"20px"}
                                  height={"20px"}
                                  sx={{
                                    border: "1px solid",
                                    borderRadius: "4px",
                                    borderColor: theme.palette.secondary.main,
                                  }}
                                />
                              }
                              checkedIcon={
                                <Box
                                  component={"img"}
                                  src={"/assets/icons/check.svg"}
                                />
                              }
                              value={elem.value}
                              checked={elem.isChecked}
                              onChange={(event) => {
                                const newFilters = [...filters];
                                const currentFilter = newFilters[index];

                                if (elem.label === "Select All") {
                                  currentFilter.checkBox.forEach(
                                    (cbElem) =>
                                      (cbElem.isChecked = event.target.checked)
                                  );
                                } else {
                                  const checkBoxToUpdate =
                                    currentFilter.checkBox.find(
                                      (cbElem) => cbElem === elem
                                    );
                                  if (checkBoxToUpdate) {
                                    checkBoxToUpdate.isChecked =
                                      event.target.checked;
                                  }
                                  const allChecked =
                                    currentFilter.checkBox.every(
                                      (cbElem) =>
                                        cbElem.isChecked ||
                                        cbElem.label === "Select All"
                                    );

                                  const selectAllCheckBox =
                                    currentFilter.checkBox.find(
                                      (cbElem) => cbElem.label === "Select All"
                                    );
                                  if (selectAllCheckBox) {
                                    selectAllCheckBox.isChecked = allChecked;
                                  }
                                }

                                setFilters(newFilters);

                                if (item.onCheckChange) {
                                  item.onCheckChange(
                                    newFilters[index].checkBox.filter(
                                      (cb) =>
                                        cb.isChecked &&
                                        cb.label !== "Select All"
                                    ),
                                    elem.value
                                  );
                                }
                              }}
                              sx={{
                                paddingY: "0",
                                "&.Mui-checked": {},
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#363E45",
                              }}
                            >
                              {elem.label}
                            </Typography>
                          }
                        />
                      ))}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography
            sx={{ marginTop: "16px", fontSize: "16px", textAlign: "center" }}
          >
            Hold Tight...
            <br />
            We're filtering the information according to your preferences. This
            will just take a moment!
          </Typography>
        </Box>
      </Backdrop>
    </Drawer>
  );
};

export default AssesmentFilter;
