import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { AuthContext } from "./authContext";
import useCookieChangeListener from "../hooks/useCookieChangeListener";

export function AuthProvider({ children }) {
  const [authUser, setAuthUser] = useState(
    JSON.parse(localStorage.getItem("authUser")) || null
  );

  const [isLoggedIn, setIsLoggedIn] = useState(
    !!Cookies.get("accessToken") && !!authUser
  );

  useEffect(() => {
    const handleStorageChange = () => {
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      const accessToken = Cookies.get("accessToken");

      if (!authUser || !accessToken) {
        window.location.reload();
      } else if (!authUser) {
        Cookies.remove("accessToken");
      } else {
        setAuthUser(authUser);
        if (accessToken) setIsLoggedIn(true);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useCookieChangeListener("accessToken", (value) => {
    if (value === null) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  });

  const authContextValue = useMemo(
    () => ({
      isLoggedIn,
      setIsLoggedIn,
      authUser,
      setAuthUser,
    }),
    [isLoggedIn, authUser]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
