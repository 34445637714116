import { Grid, Skeleton, TablePagination } from "@mui/material";
import React from "react";
import ShortlistedCandidateCard from "../common/ShortlistedCandidateCard";
import { getPaginationOptions, handleChangePage } from "../../utils/pagination";

const SortListedGrid = ({
  loading,
  setLoading,
  setTableParams,
  data,
  totalDocuments,
  handleRowsChange,
  totalPages,
  tableParams,
}) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}
      >
        {loading
          ? Array.from(new Array(6)).map((_, index) => (
              <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height={100}
                />
              </Grid>
            ))
          : data.map((elem, index) => (
              <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                <ShortlistedCandidateCard data={elem} />
              </Grid>
            ))}
      </Grid>
      <TablePagination
        component="div"
        count={totalDocuments}
        rowsPerPage={tableParams.pagination.limit}
        page={tableParams.pagination.page - 1}
        onPageChange={(e, newPage) => {
          handleChangePage(newPage, setTableParams);
        }}
        onRowsPerPageChange={handleRowsChange}
        className="custom-table-pagination"
        rowsPerPageOptions={getPaginationOptions(
          totalDocuments,
          tableParams.pagination.page,
          tableParams.pagination.limit
        )}
      />
    </>
  );
};

export default SortListedGrid;
