import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Toolbar,
  Tooltip,
  Typography,
  TextField,
  Stack,
  Badge,
  IconButton,
  Dialog,
  Modal,
  Card,
  CardContent,
  Divider,
  Button,
  Grid,
} from "@mui/material";
import { HEADER, NAV } from "../config-global";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useResponsive from "../hooks/useResponsive";
import CustomBreadcrumbs from "../components/custom-breadcrumbs";
import { navContext } from "../context/navContext";
import { breadcrumbsContext } from "../context/breadcrumbsContext";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CommonModal from "../components/modal/CommonModal";
import Cookies from "js-cookie";
import { useMessage } from "../components/snackbar/snackbar";
import { makeStyles, styled } from "@mui/styles";
import { notificationsMessage } from "../utils/data";
import TabsList from "../components/candidateShortlisted/Tabs";
import Iconify from "../components/iconify/Iconify";
import axios from "axios";
import { useNotification } from "../context/NotificationProvider";
import APICall from "../utils/api";
import PingModal from "../components/pingModal/pingModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    background: "#fff",
    padding: "16px",
    border: "1px solid #E8E9EE",
    borderRadius: "12px",
  },
  desktop: {
    display: "flex",
    background: "#fff",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "column", // Default value for base
    gap: "20px", // Default value for base
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      gap: "0",
      alignItems: "center ",
    },
  },
  iconBox: {
    padding: "4px 6px",
    border: "1px solid #D3D9E2",
    borderRadius: 4,
    width: 36,
    height: 32,
  },
  avatarBox: {
    // display: "flex",
    alignItems: "center",
    gap: 6,
  },
  infoBox: {
    alignItems: "center",
    gap: "8px",
  },
  statusBox: {
    background: "#FFF0E7",
    padding: "4px 8px",
    color: "#FF6812",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    borderRadius: "0.25rem",
  },
  subtitle: {
    color: "#8591A2",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
  },
  textField: {
    border: "2px solid #E8E9EE",
    borderRadius: "4px",
  },
  placeholder: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#363E45",
  },
  backdrop: {
    backdropFilter: "blur(2px)",
    backgroundColor: "rgba(49, 62, 79, 0.44)",
  },
}));

const style = {
  position: "absolute",
  top: "50px",
  right: "15px",
  // transform: "translate(-50%, -50%)",
  minWidth: 446,
  borderRadius: "4px",
  bgcolor: "background.paper",
  padding: "12px 0px 0px 0px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
};

const tab_list = [
  {
    label: "All (0)",
    value: "all",
  },
  {
    label: "Assessment (0)",
    value: "assessment",
  },
  {
    label: "Job (0)",
    value: "job",
  },
  {
    label: "Interview (0)",
    value: "interview",
  },
];

const Header = () => {
  const navigate = useNavigate();
  const message = useMessage();
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");
  const { isNavMini } = useContext(navContext);
  const { breadcrumbsData } = useContext(breadcrumbsContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { authUser } = useContext(AuthContext);
  const [openRequestModal, setOpenRequestModal] = React.useState(false);
  const [openNotificationModal, setOpenNotificationModal] =
    React.useState(false);
  const [tabList, setTabList] = useState([]);
  const [value, setTabValue] = useState("all");
  const [unSeen, setunSeen] = useState(0);
  const [activeSection, setActiveSection] = useState("Unread");

  // const [value, setTabValue] = React.useState(tabList[0].value);

  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationsCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const classes = useStyles();
  const cardRef = useRef(null);
  const { notificationCounts } = useNotification();

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const handelNotificationOpen = () => {
    setOpenNotificationModal(true);
  };
  const handelNotificationClose = () => {
    setOpenNotificationModal(false);
  };
  const handleRequestModalClose = () => {
    setOpenRequestModal(false);
  };

  const handleRequestDemo = () => {
    handleRequestModalClose();
    message("Request sent successfully !!", "success");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBellClick = () => {
    setOpenModal(true);
  };

  const handleClosePing = () => {
    setOpenModal(false);
  };

  const handlePingClick = () => {
    console.log("Ping button clicked");
    setOpenModal(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    Cookies.remove("accessToken");
    window.location.reload();
  };
  const handelMessageClick = () => {
    navigate("/chatbot");
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        handelNotificationClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cardRef]);

  const fetchNotificationsImpressionCount = async () => {
    try {
      const unSeenRes = await axios.get(
        `${process.env.REACT_APP_NOTIFICATION_URL}/pushNotification/unseen-count/${authUser._id}`
      );
      if (unSeenRes && unSeenRes.data) {
        setunSeen(unSeenRes.data.unseenCount);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  useEffect(() => {
    fetchNotificationsImpressionCount();
  }, [notificationCounts, openNotificationModal]);

  const seenNotification = async (notificationId) => {
    try {
      const seenRes = await axios.patch(
        `${process.env.REACT_APP_NOTIFICATION_URL}/pushNotification/notification/seen/${notificationId}`
      );
      if (seenRes && seenRes.data) {
        fetchNotificationsImpressionCount();
        fetchnotifications();
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const fetchnotifications = async () => {
    if (!value) return;
    try {
      let notificationRes;
      if (value === "all") {
        notificationRes = await axios.get(
          `${process.env.REACT_APP_NOTIFICATION_URL}/pushNotification/all/${authUser._id}/${activeSection}`
        );
      } else {
        notificationRes = await axios.get(
          `${process.env.REACT_APP_NOTIFICATION_URL}/pushNotification/all/${authUser._id}/${activeSection}?category=${value}`
        );
      }
      if (notificationRes && notificationRes.data) {
        setNotifications(notificationRes.data);
      }
    } catch (error) {
      console.error("Error fetching notifications", error);
      setNotifications([]);
    }
  };

  useEffect(() => {
    fetchnotifications();
  }, [value, openNotificationModal, activeSection]);

  const handleMarkReadAll = async () => {
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_NOTIFICATION_URL}/pushNotification/seen/${authUser._id}`
      );
      if (res) {
        fetchNotificationsImpressionCount();
        fetchnotifications();
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const fetchnotificationsStatData = async () => {
    try {
      const statRes = await axios.get(
        `${process.env.REACT_APP_NOTIFICATION_URL}/pushNotification/stat/${authUser._id}`
      );
      if (statRes && statRes.data) {
        setTabList(statRes.data.tabList);
        setNotificationsCount(statRes.data.totalCount);
      }
    } catch (error) {
      console.error("Error fetching notification stats:", error);
    }
  };

  useEffect(() => {
    fetchnotificationsStatData();
  }, [openNotificationModal]);

  const dashboardHeader = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <CustomBreadcrumbs links={breadcrumbsData} />
      <Stack direction={"row"} gap={"8px"} alignItems={"center"}>
        <IconButton aria-label="message" onClick={handelMessageClick}>
          <StyledBadge badgeContent={4}>
            <Box
              component="img"
              sx={{
                width: 24,
                height: 24,
              }}
              src="/assets/icons/messageDots_Filled.svg"
            />
          </StyledBadge>
        </IconButton>

        {/* <Tooltip title="Messages" arrow placement="top">
          <Box
            component="img"
            sx={{
              width: 24,
              height: 24,
            }}
            src="/assets/icons/fourDot.svg"
          />
        </Tooltip> */}
        <Tooltip title="Notifications" arrow placement="top">
          <IconButton
            aria-label="Notifications"
            onClick={handelNotificationOpen}
          >
            <StyledBadge badgeContent={unSeen}>
              <Box
                component="img"
                sx={{
                  width: 24,
                  height: 24,
                }}
                src="/assets/icons/bell.svg"
              />
            </StyledBadge>
          </IconButton>
        </Tooltip>
        <Box
          sx={{
            margin: "0 8px",
            padding: "4px 8px",
            backgroundColor: "secondary.light",
            borderRadius: "4px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              color: "secondary.main",
              textTransform: "capitalize",
            }}
          >
            {authUser
              ? authUser.role.charAt(0).toUpperCase() + authUser.role.slice(1)
              : "User"}
          </Typography>
        </Box>
        <Avatar
          src={authUser.profilePhoto}
          sx={{ cursor: "pointer", width: "32px", height: "32px" }}
          onClick={handleClick}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleMenuItemClick("/account")}>
            <ListItemIcon>
              {/* <AccountCircleIcon fontSize="small" /> */}
              <Avatar
                src={authUser.profilePhoto}
                sx={{ cursor: "pointer", width: "20px", height: "20px" }}
              />
            </ListItemIcon>
            <ListItemText>My Profile</ListItemText>
          </MenuItem>
          {authUser.role === "super admin" && (
            <MenuItem onClick={() => handleMenuItemClick("/settings")}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              setOpenRequestModal(!openRequestModal);
              handleMenuItemClick();
            }}
          >
            {" "}
            <ListItemIcon>
              <img src="/assets/request.svg" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Request a Demo</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuItemClick("/contactSupport");
            }}
          >
            <ListItemIcon>
              <img src="/assets/support.svg" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Contact Support</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleLogout("/logout")}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </Stack>

      {openNotificationModal && (
        <Box
          ref={cardRef}
          sx={{
            position: "absolute",
            top: "50px",
            right: "0px",
            maxWidth: "466px",
            zIndex: 10,
            backgroundColor: "#FFFFFF",
            padding: "12px 0px 0px 0px",
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            [theme.breakpoints.down("md")]: {
              top: "120px",
            },
          }}
        >
          {/* Card Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 12px",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  height: "36px",
                  width: "36px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  // zIndex: -1,
                  background: "#D5F9F1",
                }}
              >
                <Box
                  width={"20px"}
                  height={"20px"}
                  component={"img"}
                  src={"/assets/icons/bellRinging.svg"}
                />
              </Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "700 !important",
                  lineHeight: "20px !important",
                  color: "#363E45 !important",
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 0px 0px 12px",
                }}
                id="modal-modal-title"
              >
                Notifications ({notificationCount})
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#FF6812",
                cursor: "pointer",
              }}
              onClick={() => handleMarkReadAll()}
            >
              Mark All as Read
            </Typography>
          </Box>

          <Stack direction={"column"} gap={"2px"}>
            <TabsList
              tabs={tabList}
              handleTabChange={setTabValue}
              value={value}
            />
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                <Typography
                  onClick={() => handleSectionClick("Unread")}
                  sx={{
                    fontSize: "14px",
                    cursor: "pointer",
                    color: activeSection === "Unread" ? "#00c49a" : "none",
                    borderBottom:
                      activeSection === "Unread" ? "1px solid #00c49a" : "none",
                    paddingBottom: "8px",
                  }}
                >
                  UnRead
                </Typography>
              </Grid>

              <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                <Typography
                  onClick={() => handleSectionClick("Read")}
                  sx={{
                    fontSize: "14px",
                    cursor: "pointer",
                    color: activeSection === "Read" ? "#00c49a" : "none",
                    borderBottom:
                      activeSection === "Read" ? "1px solid #00c49a" : "none",
                    paddingBottom: "8px",
                  }}
                >
                  Read
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                padding: "0px 12px",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                overflowY: "auto",
                maxHeight: "488px",
              }}
            >
              <>
                <Divider
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&::before": {
                      borderColor: "#E8E9EE",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#71777B",
                    }}
                  >
                    Today
                  </Typography>
                </Divider>
              </>
              <>
                {notifications.today.length > 0 ? (
                  notifications.today.map((notification) => (
                    <Box
                      key={notification._id}
                      sx={{
                        borderRadius: "6px",
                        padding: "8px",
                        backgroundColor: notification.isSeen
                          ? "#FFFFFF"
                          : "#FFF5EF",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        gap: "12px",
                        marginBottom: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => seenNotification(notification._id)}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        gap={"8px"}
                        flexGrow={1}
                      >
                        {/* Icon based on category */}
                        <IconButton
                          aria-label={notification.category}
                          // color="secondary"
                          color={
                            notification?.isSeen ? "secondary" : "orangeButton"
                          }
                          sx={{
                            backgroundColor: notification?.isSeen
                              ? "#00C49A"
                              : "#FF6812",
                            maxWidth: "24px",
                            maxHeight: "24px",
                            "&:hover": {
                              backgroundColor: notification?.isSeen
                                ? "#00C49A"
                                : "#FF6812",
                              cursor: "default",
                            },
                          }}
                        >
                          <Box
                            component={"img"}
                            src={
                              notification.category === "interview"
                                ? "/assets/icons/VideoRecord.svg"
                                : "/assets/icons/MedicalReport.svg"
                            }
                            width={"12px"}
                            height={"12px"}
                            alt={notification.category}
                          />
                        </IconButton>

                        {/* Notification Content */}
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          gap={"4px"}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Nunito",
                              fontWeight: 600,
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#313131",
                            }}
                          >
                            {notification.title}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center", // Ensures the image and text are vertically aligned
                              gap: "8px", // Adds space between the image and text
                            }}
                          >
                            <img
                              alt="Recruiter"
                              src={
                                (notification.userDetails &&
                                  notification.userDetails[0] &&
                                  notification.userDetails[0].profilePhotoId) ||
                                "/assets/user.png"
                              }
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                              }}
                            />
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#535A5F",
                              }}
                            >
                              {notification.body}
                            </Typography>
                            <Box
                              width={"20px"}
                              height={"20px"}
                              sx={{
                                paddingTop: "5px",
                                cursor: "pointer",
                              }}
                              component={"img"}
                              src={"/assets/Vector-5.svg"}
                              onClick={handleBellClick}
                            />
                          </Box>

                          <Stack
                            direction={"row"}
                            sx={{ padding: "1px 0px" }}
                            gap={"4px"}
                          >
                            <Iconify
                              icon="ci:calendar-event"
                              color="#8591A2"
                              width="16px"
                            />
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#71777B",
                              }}
                            >
                              {new Date(
                                notification.createdAt
                              ).toLocaleString()}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>

                      {/* Buttons and status indicator */}
                      <Stack
                        direction={"column"}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        gap={"12px"}
                      >
                        {notification.isSeen ? (
                          <>
                            <Box></Box>
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                minWidth: "8px",
                                minHeight: "8px",
                                borderRadius: "50%",
                                backgroundColor: "#FF6812",
                              }}
                            ></Box>
                          </>
                        )}

                        <Button
                          // color="greenButton"
                          color={
                            notification?.isSeen
                              ? "greenButton"
                              : "orangeButton"
                          }
                          variant={
                            notification?.category === "all" ? "" : "outlined"
                          }
                          sx={{
                            maxHeight: "28px",
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                          }}
                          startIcon={
                            <Iconify
                              icon={
                                notification.category === "interview"
                                  ? "tabler:video"
                                  : notification.category === "assessment"
                                  ? "iconamoon:eye-thin"
                                  : notification.category === "job"
                                  ? "lucide:edit"
                                  : notification.category === "all"
                                  ? ""
                                  : ""
                              }
                              width="16px"
                              color={
                                notification?.isSeen ? "#00C49A" : "#FF6812"
                              }
                            />
                          }
                          onClick={() => {
                            seenNotification(notification._id);
                            window.open(
                              notification.link,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {notification.category === "interview" && "Join"}
                          {notification.category === "assessment" && "View"}
                          {notification.category === "job" && "Create"}
                        </Button>
                      </Stack>
                    </Box>
                  ))
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "Nunito",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#BFC2C4",
                      textAlign: "center",
                    }}
                  >
                    No notifications were received Today
                  </Typography>
                )}
              </>
              <Divider
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&::before": {
                    borderColor: "#E8E9EE",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#71777B",
                  }}
                >
                  Yesterday
                </Typography>
              </Divider>
              <>
                {notifications && notifications.yesterday.length > 0 ? (
                  notifications.yesterday.map((notification) => (
                    <Box
                      key={notification._id}
                      sx={{
                        borderRadius: "6px",
                        padding: "8px",
                        backgroundColor: notification.isSeen
                          ? "#FFFFFF"
                          : "#FFF5EF",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        gap: "12px",
                        marginBottom: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => seenNotification(notification._id)}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        gap={"8px"}
                        flexGrow={1}
                      >
                        {/* Icon based on category */}
                        <IconButton
                          aria-label={notification.category}
                          color={
                            notification?.isSeen ? "secondary" : "orangeButton"
                          }
                          sx={{
                            backgroundColor: notification?.isSeen
                              ? "#00C49A"
                              : "#FF6812",
                            maxWidth: "24px",
                            maxHeight: "24px",
                            "&:hover": {
                              backgroundColor: notification?.isSeen
                                ? "#00C49A"
                                : "#FF6812",
                              cursor: "default",
                            },
                          }}
                        >
                          <Box
                            component={"img"}
                            src={
                              notification.category === "interview"
                                ? "/assets/icons/VideoRecord.svg"
                                : "/assets/icons/MedicalReport.svg"
                            }
                            width={"12px"}
                            height={"12px"}
                            alt={notification.category}
                          />
                        </IconButton>

                        {/* Notification Content */}
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          gap={"4px"}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Nunito",
                              fontWeight: 600,
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#313131",
                            }}
                          >
                            {notification.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontWeight: 400,
                              fontSize: "12px",
                              lineHeight: "16px",
                              color: "#535A5F",
                            }}
                          >
                            {notification.body}
                          </Typography>
                          <Stack
                            direction={"row"}
                            sx={{ padding: "1px 0px" }}
                            gap={"4px"}
                          >
                            <Iconify
                              icon="ci:calendar-event"
                              color="#8591A2"
                              width="16px"
                            />
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#71777B",
                              }}
                            >
                              {new Date(
                                notification.createdAt
                              ).toLocaleString()}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>

                      {/* Buttons and status indicator */}
                      <Stack
                        direction={"column"}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        gap={"12px"}
                      >
                        {notification.isSeen ? (
                          <>
                            <Box></Box>
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                minWidth: "8px",
                                minHeight: "8px",
                                borderRadius: "50%",
                                backgroundColor: "#FF6812",
                              }}
                            ></Box>
                          </>
                        )}

                        <Button
                          // color="greenButton"
                          color={
                            notification?.isSeen
                              ? "greenButton"
                              : "orangeButton"
                          }
                          variant={
                            notification?.category === "all" ? "" : "outlined"
                          }
                          sx={{
                            maxHeight: "28px",
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                          }}
                          startIcon={
                            <Iconify
                              icon={
                                notification.category === "interview"
                                  ? "tabler:video"
                                  : notification.category === "assessment"
                                  ? "iconamoon:eye-thin"
                                  : notification.category === "job"
                                  ? "lucide:edit"
                                  : notification.category === "all"
                                  ? ""
                                  : ""
                              }
                              width="16px"
                              color={
                                notification?.isSeen ? "#00C49A" : "#FF6812"
                              }
                            />
                          }
                          onClick={() => {
                            seenNotification(notification._id);
                            window.open(
                              notification.link,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {notification.category === "interview" && "Join"}
                          {notification.category === "assessment" && "View"}
                          {notification.category === "job" && "Create"}
                        </Button>
                      </Stack>
                    </Box>
                  ))
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "Nunito",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#BFC2C4",
                      textAlign: "center",
                    }}
                  >
                    No notifications were received
                  </Typography>
                )}
              </>
              <Divider
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&::before": {
                    borderColor: "#E8E9EE",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#71777B",
                  }}
                >
                  Last 7 days
                </Typography>
              </Divider>
              <>
                {notifications.last7Days.length > 0 ? (
                  notifications.last7Days.map((notification) => (
                    <Box
                      key={notification._id}
                      sx={{
                        borderRadius: "6px",
                        padding: "8px",
                        backgroundColor: notification.isSeen
                          ? "#FFFFFF"
                          : "#FFF5EF",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        gap: "12px",
                        marginBottom: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => seenNotification(notification._id)}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        gap={"8px"}
                        flexGrow={1}
                      >
                        {/* Icon based on category */}
                        <IconButton
                          aria-label={notification.category}
                          color={
                            notification?.isSeen ? "secondary" : "orangeButton"
                          }
                          sx={{
                            backgroundColor: notification?.isSeen
                              ? "#00C49A"
                              : "#FF6812",
                            maxWidth: "24px",
                            maxHeight: "24px",
                            "&:hover": {
                              backgroundColor: notification?.isSeen
                                ? "#00C49A"
                                : "#FF6812",
                              cursor: "default",
                            },
                          }}
                        >
                          <Box
                            component={"img"}
                            src={
                              notification.category === "interview"
                                ? "/assets/icons/VideoRecord.svg"
                                : "/assets/icons/MedicalReport.svg"
                            }
                            width={"12px"}
                            height={"12px"}
                            alt={notification.category}
                          />
                        </IconButton>

                        {/* Notification Content */}
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          gap={"4px"}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Nunito",
                              fontWeight: 600,
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#313131",
                            }}
                          >
                            {notification.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontWeight: 400,
                              fontSize: "12px",
                              lineHeight: "16px",
                              color: "#535A5F",
                            }}
                          >
                            {notification.body}
                          </Typography>
                          <Stack
                            direction={"row"}
                            sx={{ padding: "1px 0px" }}
                            gap={"4px"}
                          >
                            <Iconify
                              icon="ci:calendar-event"
                              color="#8591A2"
                              width="16px"
                            />
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#71777B",
                              }}
                            >
                              {new Date(
                                notification.createdAt
                              ).toLocaleString()}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>

                      {/* Buttons and status indicator */}
                      <Stack
                        direction={"column"}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        gap={"12px"}
                      >
                        {notification.isSeen ? (
                          <>
                            <Box></Box>
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                minWidth: "8px",
                                minHeight: "8px",
                                borderRadius: "50%",
                                backgroundColor: "#FF6812",
                              }}
                            ></Box>
                          </>
                        )}

                        <Button
                          // color="greenButton"
                          color={
                            notification?.isSeen
                              ? "greenButton"
                              : "orangeButton"
                          }
                          variant={
                            notification?.category === "all" ? "" : "outlined"
                          }
                          sx={{
                            maxHeight: "28px",
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                          }}
                          startIcon={
                            <Iconify
                              icon={
                                notification.category === "interview"
                                  ? "tabler:video"
                                  : notification.category === "assessment"
                                  ? "iconamoon:eye-thin"
                                  : notification.category === "job"
                                  ? "lucide:edit"
                                  : notification.category === "all"
                                  ? ""
                                  : ""
                              }
                              width="16px"
                              color={
                                notification?.isSeen ? "#00C49A" : "#FF6812"
                              }
                            />
                          }
                          onClick={() => {
                            seenNotification(notification._id);
                            window.open(
                              notification.link,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {notification.category === "interview" && "Join"}
                          {notification.category === "assessment" && "View"}
                          {notification.category === "job" && "Create"}
                        </Button>
                      </Stack>
                    </Box>
                  ))
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "Nunito",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#BFC2C4",
                      textAlign: "center",
                    }}
                  >
                    No notifications were received
                  </Typography>
                )}
              </>

              <Divider
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&::before": {
                    borderColor: "#E8E9EE",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#71777B",
                  }}
                >
                  Last 30 days
                </Typography>
              </Divider>

              <>
                {notifications.last30Days.length > 0 ? (
                  notifications.last30Days.map((notification) => (
                    <Box
                      key={notification._id}
                      sx={{
                        borderRadius: "6px",
                        padding: "8px",
                        backgroundColor: notification.isSeen
                          ? "#FFFFFF"
                          : "#FFF5EF",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        gap: "12px",
                        marginBottom: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => seenNotification(notification._id)}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        gap={"8px"}
                        flexGrow={1}
                      >
                        {/* Icon based on category */}
                        <IconButton
                          aria-label={notification.category}
                          // color="secondary"
                          color={
                            notification?.isSeen ? "secondary" : "orangeButton"
                          }
                          sx={{
                            backgroundColor: notification?.isSeen
                              ? "#00C49A"
                              : "#FF6812",
                            maxWidth: "24px",
                            maxHeight: "24px",
                            "&:hover": {
                              backgroundColor: notification?.isSeen
                                ? "#00C49A"
                                : "#FF6812",
                              cursor: "default",
                            },
                          }}
                        >
                          <Box
                            component={"img"}
                            src={
                              notification.category === "interview"
                                ? "/assets/icons/VideoRecord.svg"
                                : "/assets/icons/MedicalReport.svg"
                            }
                            width={"12px"}
                            height={"12px"}
                            alt={notification.category}
                          />
                        </IconButton>

                        {/* Notification Content */}
                        <Stack
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          gap={"4px"}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Nunito",
                              fontWeight: 600,
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#313131",
                            }}
                          >
                            {notification.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontWeight: 400,
                              fontSize: "12px",
                              lineHeight: "16px",
                              color: "#535A5F",
                            }}
                          >
                            {notification.body}
                          </Typography>
                          <Stack
                            direction={"row"}
                            sx={{ padding: "1px 0px" }}
                            gap={"4px"}
                          >
                            <Iconify
                              icon="ci:calendar-event"
                              color="#8591A2"
                              width="16px"
                            />
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#71777B",
                              }}
                            >
                              {new Date(
                                notification.createdAt
                              ).toLocaleString()}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>

                      {/* Buttons and status indicator */}
                      <Stack
                        direction={"column"}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        gap={"12px"}
                      >
                        {notification.isSeen ? (
                          <>
                            <Box></Box>
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                minWidth: "8px",
                                minHeight: "8px",
                                borderRadius: "50%",
                                backgroundColor: "#FF6812",
                              }}
                            ></Box>
                          </>
                        )}

                        <Button
                          // color="greenButton"
                          color={
                            notification?.isSeen
                              ? "greenButton"
                              : "orangeButton"
                          }
                          variant={
                            notification?.category === "all" ? "" : "outlined"
                          }
                          sx={{
                            maxHeight: "28px",
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                          }}
                          startIcon={
                            <Iconify
                              icon={
                                notification.category === "interview"
                                  ? "tabler:video"
                                  : notification.category === "assessment"
                                  ? "iconamoon:eye-thin"
                                  : notification.category === "job"
                                  ? "lucide:edit"
                                  : notification.category === "all"
                                  ? ""
                                  : ""
                              }
                              width="16px"
                              color={
                                notification?.isSeen ? "#00C49A" : "#FF6812"
                              }
                            />
                          }
                          onClick={() => {
                            seenNotification(notification._id);
                            window.open(
                              notification.link,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {notification.category === "interview" && "Join"}
                          {notification.category === "assessment" && "View"}
                          {notification.category === "job" && "Create"}
                        </Button>
                      </Stack>
                    </Box>
                  ))
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "Nunito",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#BFC2C4",
                      textAlign: "center",
                    }}
                  >
                    No notifications were received
                  </Typography>
                )}
              </>
            </Box>
          </Stack>
        </Box>
      )}
    </Box>
  );

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: 1000,
        bgcolor: "background.default",
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${230}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { md: "16px" },
        }}
        variant="dense"
      >
        {dashboardHeader}
      </Toolbar>

      <CommonModal
        icon={"/assets/request.svg"}
        handleClose={handleRequestModalClose}
        open={openRequestModal}
        title={"Request a Demo"}
        sendButtonName={"Send"}
        onSubmit={handleRequestDemo}
      >
        <Stack gap={"4px"}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "14px",
              lineheight: "20px",
              color: "#71777B",
            }}
          >
            Enter Message
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={6}
            placeholder="Enter your message"
            className={classes.textField}
            InputProps={{
              className: classes.placeholder, // Apply placeholder styles
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "8px 12px 8px 12px",
                "& fieldset": {
                  borderColor: "#E8E9EE",
                  borderWidth: "2px",
                  borderRadius: "4px",
                },
              },
              "& .MuiInputBase-input": {
                "&::placeholder": {
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "#363E45",
                  opacity: 1,
                },
              },
            }}
          />
        </Stack>
      </CommonModal>

      <PingModal
        openMemberModal={openModal}
        handleClose={handleClosePing}
        handlePingClick={handlePingClick}
        modalTitle="Ping To Scheduler"
      />
    </AppBar>
  );
};

export default Header;

const StyledBadge = (props) => {
  return (
    <Badge
      {...props}
      sx={{
        "& .MuiBadge-badge": {
          top: 8,
          right: 0,
          width: "16px",
          height: "16px",
          minWidth: "16px",
          minHeight: "16px",
          backgroundColor: "#F94948",
          color: "#FFFFFF",
          fontSize: "8px",
          borderRadius: "50%",
          padding: "0 4px",
          fontFamily: "Poppins",
          fontWeight: 600,
        },
      }}
    >
      {props.children}
    </Badge>
  );
};
