import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import DropdownField from "../../components/common/DropdownField";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useMessage } from "../../components/snackbar/snackbar";

import ReactQuill from "react-quill";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import katex from "katex";
import "katex/dist/katex.min.css";

import CheckIcon from "@mui/icons-material/Check";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as yup from "yup";
import { assesmentData } from "../../utils/data";
import APICall from "../../utils/api";

const difficultyButtons = [
  {
    difficulty: "Easy",
    bgcolor: "rgba(67, 157, 98, 0.1)",
    color: "rgba(67, 157, 98, 1)",
    path: "/assets/greenfire.svg",
    selectedPath: "/assets/icons/greenFireEmpty.svg",
  },
  {
    difficulty: "Medium",
    bgcolor: "rgba(255, 151, 54, 0.1)",
    color: "rgba(255, 151, 54, 1)",
    path: "/assets/orangefire.svg",
    selectedPath: "/assets/icons/orangeFireEmpty.svg",
  },
  {
    difficulty: "Hard",
    bgcolor: "rgba(249, 73, 72, 0.1)",
    color: "rgba(249, 73, 72, 1)",
    path: "/assets/libraryFire.svg",
    selectedPath: "/assets/icons/redFireEmpty.svg",
  },
];
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked": {
    color: "#ffffff",
  },
  "& .MuiSvgIcon-root": {
    backgroundColor: "#00C49A", // Green background color
    borderRadius: 4,
  },
}));

// Custom icon for unchecked state
const CustomUncheckedIcon = styled("span")(({ theme }) => ({
  width: 20,
  height: 20,
  backgroundColor: "transparent", // Green background color
  borderRadius: 4,
  border: "1px solid #00C49A",
}));

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ align: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: [] }, { background: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "background",
  "align",
];

const BpIcon = styled("span")(() => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  backgroundColor: "transparent",
  border: "3px solid #8591A2",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#00C49A",
  border: "none",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#00C49A",
  },
});

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const EditTestOverview = ({
  open,
  onClose,
  drawerHeading,
  assessmentDetails,
  fetchAssessmentDetails,
}) => {
  const theme = useTheme();
  const [selectedDifficulty, setSelectedDifficulty] = useState("Easy");
  const [multipleChoice, setMultipleChoice] = useState(false);
  const message = useMessage();
  const [startsOn, setStartsOn] = useState(dayjs());
  const [endsOn, setEndsOn] = useState(dayjs());
  const [jobRoleOptions, setJobRoleOptions] = useState([]);
  const [experienceOptions, setExperienceOptions] = useState([]);

  const handleDifficultyChange = (difficulty) => {
    setSelectedDifficulty(difficulty);
  };
  useEffect(() => {
    const loadJobRoles = async () => {
      const options = await fetchJobRoles();
      setJobRoleOptions(options);
    };

    loadJobRoles();
  }, []);

  useEffect(() => {
    const fetchExperienceOptions = async () => {
      let url = `/experience`;

      try {
        const res = await APICall(url);

        const options = res.data.documents.map((doc) => ({
          value: doc._id,
          label: doc.title,
        }));
        setExperienceOptions(options);
      } catch (error) {
        console.error("Error fetching experience options", error);
      }
    };

    fetchExperienceOptions();
  }, []);

  const fetchJobRoles = async () => {
    let url = `/jobRole?limit=1000`;

    const res = await APICall(url);
    console.log(res);

    const jobRoleOptions = Object.values(res.data.documents).map((doc) => ({
      value: doc._id,
      label: doc.jobRole,
    }));
    return jobRoleOptions;
  };

  const validationSchema = yup.object({
    name: yup.string().required("Test Name is required"),

    testDuration: yup
      .number()
      .typeError("Test Duration must be a number")
      .min(1, "Test Duration must be at least 1 minute")

      .required("Test Duration is required"),

    totalScore: yup
      .number()
      .typeError("Test Score must be a number")
      .min(1, "Test Score must be at least 1")
      .required("Test Score is required"),
    startsAt: yup
      .date()
      .typeError("Start Date must be a valid date")
      .required("Start Date is required"),
    endsAt: yup
      .date()
      .typeError("End Date must be a valid date")
      .required("End Date is required")
      .min(yup.ref("startsAt"), "End Date cannot be before Start Date"),
    cutOff: yup
      .number()
      .typeError("Cut Off must be a number")
      .min(1, "Cut Off must be at least 1")
      .required("Cut Off is required")
      .test(
        "cutOff-less-than-totalScore",
        "Cut Off cannot be more than the Test Score",
        function (value) {
          const { totalScore } = this.parent;
          return value <= totalScore;
        }
      ),
    jobRoleId: yup.string().required("Job Role is required"),

    experienceId: yup.string().required("Experience is required"),

    // testType: yup.string().required("Test Type is required"),

    // testInviteOnly: yup
    //   .boolean()
    //   .required("Test Invite Only must be specified"),

    librarySelection: yup
      .array()
      .of(yup.string())
      .min(1, "At least one library must be selected")
      .required("Library Selection is required"),
  });
  const startDateParsed = dayjs(assessmentDetails.startsAt);
  const endDateParsed = dayjs(assessmentDetails.endsAt);

  const formik = useFormik({
    initialValues: {
      name: assessmentDetails.name || "",
      testDuration: assessmentDetails.testDuration || "",
      description: assessmentDetails.description || "",
      startsAt: startDateParsed || "",
      endsAt: endDateParsed || "",
      totalScore: assessmentDetails.totalScore || "",
      cutOff: assessmentDetails.cutOff || "",
      jobRoleId: assessmentDetails?.jobRoleId?._id || "",
      jobRole: assessmentDetails?.jobRoleId?.jobRole || "",
      experienceId: assessmentDetails?.experienceId?._id || "",
      testType: assessmentDetails.testType || "",
      testInviteOnly: assessmentDetails.testInviteOnly || false,
      librarySelection: assessmentDetails.librarySelection || [],
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSaveChanges();
    },

    enableReinitialize: true,
  });

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      formik.setFieldValue("librarySelection", [
        ...formik.values.librarySelection,
        value,
      ]);
    } else {
      formik.setFieldValue(
        "librarySelection",
        formik.values.librarySelection.filter((item) => item !== value)
      );
    }
  };

  const handleSaveChanges = async () => {
    console.log(formik.values);
    const data = {
      name: formik.values.name,
      testDuration: formik.values.testDuration,
      description: formik.values.description,
      startsAt: formik.values.startsAt,
      endsAt: formik.values.endsAt,
      totalScore: formik.values.totalScore,
      cutOff: formik.values.cutOff,
      jobRoleId: formik.values.jobRoleId,
      experienceId: formik.values.experienceId,
      testType: formik.values.testType,
      testInviteOnly: formik.values.testInviteOnly,
      librarySelection: formik.values.librarySelection,
    };
    try {
      const res = await APICall(
        `/Assessment/${assessmentDetails._id}`,
        data,
        "patch"
      );
      message("Changes saved successfully !!", "success");
      fetchAssessmentDetails();
      onClose();
    } catch (error) {
      message(error.response.data.message, "error");
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ backdropFilter: "blur(.1875rem)" }}
      BackdropProps={{
        sx: {
          backgroundColor: open ? "rgba(49, 62, 79, 0.7)" : "transparent",
        },
      }}
    >
      <Box
        sx={{
          minWidth: {
            base: "21.875rem",
            sm: "720px", // tablet (fixed width)
            md: "500px", // desktop
          },
          height: "100vh",
        }}
      >
        <Box
          sx={{
            padding: ".75rem 1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: ".0625rem solid #E9EAEB",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "700",
                color: "#363E45",
                fontFamily: "Roboto",
                lineHeight: "1.25rem",
              }}
            >
              {drawerHeading}
            </Typography>
          </Box>
          <Box onClick={onClose} sx={{ cursor: "pointer" }}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.00099 18.7008C5.8625 18.7008 5.72712 18.6597 5.61198 18.5828C5.49683 18.5058 5.40708 18.3965 5.35408 18.2685C5.30108 18.1405 5.28722 17.9997 5.31424 17.8639C5.34125 17.7281 5.40794 17.6033 5.50587 17.5053L17.5059 5.5041C17.6373 5.37356 17.8152 5.30045 18.0005 5.30078C18.1857 5.30112 18.3633 5.37487 18.4943 5.50589C18.6253 5.63691 18.6991 5.81451 18.6994 5.9998C18.6997 6.18508 18.6266 6.36295 18.4961 6.49444L6.49611 18.4957C6.43113 18.5608 6.35395 18.6124 6.26899 18.6476C6.18403 18.6828 6.09295 18.7009 6.00099 18.7008Z"
                fill="#71777B"
              />
              <path
                d="M18.001 18.7008C17.909 18.7009 17.818 18.6828 17.733 18.6476C17.648 18.6124 17.5708 18.5608 17.5059 18.4957L5.50587 6.49444C5.37534 6.36295 5.30224 6.18508 5.30258 5.9998C5.30291 5.81451 5.37666 5.63691 5.50766 5.50589C5.63867 5.37487 5.81625 5.30112 6.00152 5.30078C6.18679 5.30045 6.36464 5.37356 6.49611 5.5041L18.4961 17.5053C18.594 17.6033 18.6607 17.7281 18.6877 17.8639C18.7148 17.9997 18.7009 18.1405 18.6479 18.2685C18.5949 18.3965 18.5052 18.5058 18.39 18.5828C18.2749 18.6597 18.1395 18.7008 18.001 18.7008Z"
                fill="#71777B"
              />
            </svg>
          </Box>
        </Box>

        <Box
          sx={{
            padding: "1rem",
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
          }}
        >
          <Stack
            direction={{ base: "column", sm: "column", md: "row" }}
            sx={{ width: "100%", gap: "1rem" }}
          >
            <DropdownField
              id="test_name"
              name="name"
              label="Test Name"
              fullWidth
              required={true}
              value={formik.values.name}
              defaultValue={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <DropdownField
              id="test_duration"
              name="testDuration"
              label="Test Duration"
              fullWidth
              required={true}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 10,
              }}
              value={formik.values.testDuration}
              defaultValue={formik.values.testDuration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.testDuration &&
                Boolean(formik.errors.testDuration)
              }
              helperText={
                formik.touched.testDuration && formik.errors.testDuration
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src="/assets/Vector-6.svg"
                      alt="icon"
                      style={{ width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack
            direction={{ base: "column", sm: "column", md: "row" }}
            sx={{ width: "100%", gap: "1rem" }}
          >
            <Box sx={{ position: "relative" }}>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#71777B",
                }}
              >
                Test Description
              </Typography>
              <Box sx={{ position: "relative", minHeight: "150px" }}>
                <SunEditor
                  setContents={formik.values.description}
                  onChange={(content) => {
                    formik.setFieldValue("description", content);
                  }}
                  onBlur={() => formik.setFieldTouched("description", true)}
                  setOptions={{
                    height: 150,
                    buttonList: [
                      [
                        "undo",
                        "redo",
                        "formatBlock",
                        "paragraphStyle",
                        "blockquote",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "subscript",
                        "superscript",
                        "fontColor",
                        "hiliteColor",
                        "removeFormat",
                        "indent",
                        "outdent",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "table",
                        "image",
                        "video",
                        "fullScreen",
                        "preview",
                      ],
                    ],
                    onImageUploadBefore: (files, info, uploadHandler) => {
                      const reader = new FileReader();
                      reader.onload = function (e) {
                        const base64String = e.target.result;
                        uploadHandler({ src: base64String });
                      };
                      reader.readAsDataURL(files[0]);
                    },
                  }}
                />
              </Box>
            </Box>
          </Stack>
          <Stack
            direction={{ base: "column", sm: "column", md: "row" }}
            sx={{ width: "100%", gap: "1rem" }}
          >
            <DateTimePicker
              id="StartsOn"
              name="startsAt"
              label={
                <span>
                  Starts On
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </span>
              }
              value={formik.values.startsAt}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.startsAt}
              onChange={(newValue) => {
                formik.setFieldValue("startsAt", newValue);
              }}
              slotProps={{
                textField: {
                  variant: "outlined",
                  error:
                    formik.touched.startsAt && Boolean(formik.errors.startsAt),
                  helperText: formik.touched.startsAt && formik.errors.startsAt,
                },
              }}
              sx={{ flex: 1 }}
              format="DD/MM/YYYY hh:mm A"
            />
            <DateTimePicker
              id="EndsOn"
              name="endsAt"
              label={
                <span>
                  Ends On
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </span>
              }
              value={formik.values.endsAt}
              defaultValue={formik.values.endsAt}
              onChange={(endsAt) => {
                formik.setFieldValue("endsAt", endsAt);
              }}
              onBlur={formik.handleBlur}
              // error={formik.touched.endsAt && Boolean(formik.errors.endsAt)}
              slotProps={{
                textField: {
                  variant: "outlined",
                  error: formik.touched.endsAt && Boolean(formik.errors.endsAt),
                  helperText: formik.touched.endsAt && formik.errors.endsAt,
                },
              }}
              helperText={formik.touched.endsAt && formik.errors.endsAt}
              required={true}
              sx={{ flex: 1 }}
              format="DD/MM/YYYY hh:mm A"
            />
          </Stack>
          <Stack
            direction={{ base: "column", sm: "column", md: "row" }}
            sx={{ width: "100%", gap: "1rem" }}
          >
            <DropdownField
              id="test_score"
              name="totalScore"
              label={
                <span>
                  Test Score
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </span>
              }
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 10,
              }}
              value={formik.values.totalScore}
              defaultValue={formik.values.totalScore}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              //disabled={assessmentDetails?.status === "Completed"}
              disabled={true}
              error={
                formik.touched.totalScore && Boolean(formik.errors.totalScore)
              }
              helperText={formik.touched.totalScore && formik.errors.totalScore}
              sx={{ flex: 1 }}
            />
            <DropdownField
              id="cut_off_score"
              name="cutOff"
              label="Cut Off Score"
              required={true}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.cutOff && Boolean(formik.errors.cutOff)}
              helperText={formik.touched.cutOff && formik.errors.cutOff}
              value={formik.values.cutOff}
              defaultValue={formik.values.cutOff}
              sx={{ flex: 1 }}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 10,
              }}
            />
          </Stack>
          <Stack
            direction={{ base: "column", sm: "column", md: "row" }}
            sx={{ width: "100%", gap: "1rem" }}
          >
            <FormControl
              sx={{ flex: 1 }}
              error={
                formik.touched.jobRoleId && Boolean(formik.errors.jobRoleId)
              }
            >
              <InputLabel
                id="jobRole-label"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Job Role
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              </InputLabel>
              <Select
                labelId="jobRole-label"
                id="jobrole"
                name="jobRoleId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.jobRoleId && Boolean(formik.errors.jobRoleId)
                }
                helperText={formik.touched.jobRole && formik.errors.jobRoleId}
                value={formik.values.jobRoleId}
                defaultValue={formik.values.jobRoleId}
                required={true}
                label="Job Role"
                sx={{ height: "45px" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      width: 70,
                    },
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                renderValue={(selected) => {
                  const selectedOption = jobRoleOptions.find(
                    (option) => option.value === selected
                  );
                  return selectedOption ? selectedOption.label : "";
                }}
              >
                {jobRoleOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>

              {formik.touched.jobRoleId && formik.errors.jobRoleId && (
                <FormHelperText error>{formik.errors.jobRoleId}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              sx={{ flex: 1 }}
              error={
                formik.touched.experienceId &&
                Boolean(formik.errors.experienceId)
              }
            >
              <InputLabel
                id="experience-label"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Experience
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              </InputLabel>

              <Select
                labelId="experience-label"
                id="experienceId"
                name="experienceId"
                defaultValue={formik.values.experienceId}
                value={formik.values.experienceId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required={true}
                label="Experience"
                sx={{ height: "45px" }}
                renderValue={(selected) =>
                  experienceOptions.find((option) => option.value === selected)
                    ?.label || ""
                }
              >
                {experienceOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.experienceId && formik.errors.experienceId && (
                <FormHelperText error>
                  {formik.errors.experienceId}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: "column", sm: "column", md: "row" }}
            sx={{ width: "100%", gap: "1rem" }}
          >
            <Box sx={{ width: "100%", display: { base: "none", lg: "flex" } }}>
              {/* test type */}
              <FormControl
                sx={{ flex: 1 }}
                error={Boolean(
                  formik.errors.testType && formik.touched.testType
                )}
              >
                <FormLabel
                  // required
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#71777B",
                  }}
                >
                  <Box component="span">
                    Test Type
                    <Box
                      component="span"
                      sx={{ color: "#ff0059", marginLeft: "0.25rem" }}
                    >
                      *
                    </Box>
                  </Box>
                </FormLabel>
                <RadioGroup
                  row
                  name="testType"
                  value={formik.values.testType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    value="public"
                    control={<BpRadio />}
                    label={
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                          color: "#363E45",
                          marginRight: "1rem",
                        }}
                      >
                        Hiring
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="private"
                    control={<BpRadio />}
                    label="Upskilling"
                  />
                </RadioGroup>
                {formik.touched.testType && formik.errors.testType && (
                  <FormHelperText>{formik.errors.testType}</FormHelperText>
                )}
              </FormControl>
              {/* invites only */}
              <FormControl sx={{ flex: 1, marginLeft: "20px" }}>
                <FormLabel
                  // required
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#71777B",
                  }}
                >
                  <Box component="span">
                    Invites Only
                    <Box
                      component="span"
                      sx={{ color: "#ff0059", marginLeft: "0.25rem" }}
                    >
                      *
                    </Box>
                  </Box>
                </FormLabel>
                <RadioGroup
                  row
                  name="testInviteOnly"
                  value={formik.values.testInviteOnly ? "true" : "false"}
                  onChange={(event) => {
                    const value = event.target.value === "true";
                    formik.setFieldValue("testInviteOnly", value);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    value="true"
                    control={<BpRadio />}
                    label={
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                          color: "#363E45",
                          marginRight: "1rem",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="false"
                    control={<BpRadio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            direction={{ base: "column", sm: "column", md: "row" }}
            sx={{ width: "100%", gap: "1rem" }}
          >
            {/* for small screen */}
            <Box sx={{ width: "100%", display: { base: "flex", lg: "none" } }}>
              {/* test type */}
              <FormControl sx={{ flex: 1 }}>
                <FormLabel
                  // required
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#71777B",
                  }}
                >
                  <Box component="span">
                    Test Type
                    <Box
                      component="span"
                      sx={{ color: "#ff0059", marginLeft: "0.25rem" }}
                    >
                      *
                    </Box>
                  </Box>
                </FormLabel>
                <RadioGroup row name="row-radio-buttons-group">
                  <FormControlLabel
                    value="public"
                    control={<BpRadio />}
                    label={
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                          color: "#363E45",
                        }}
                      >
                        Hiring
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="private"
                    control={<BpRadio />}
                    label="Upskilling"
                  />
                </RadioGroup>
              </FormControl>
              {/* invites only */}
              <FormControl sx={{ flex: 1 }}>
                <FormLabel
                  // required
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#71777B",
                  }}
                >
                  <Box component="span">
                    Invites Only
                    <Box
                      component="span"
                      sx={{ color: "#ff0059", marginLeft: "0.25rem" }}
                    >
                      *
                    </Box>
                  </Box>
                </FormLabel>
                <RadioGroup row name="row-radio-buttons-group">
                  <FormControlLabel
                    value="yes"
                    control={<BpRadio />}
                    label={
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                          color: "#363E45",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="no"
                    control={<BpRadio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            direction={{ base: "column", sm: "column", md: "row" }}
            sx={{ width: "100%", gap: "1rem" }}
          >
            <Box>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  // required
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#71777B",
                  }}
                >
                  <Box component="span">
                    Library
                    <Box
                      component="span"
                      sx={{ color: "#ff0059", marginLeft: "0.25rem" }}
                    >
                      *
                    </Box>
                  </Box>
                </FormLabel>
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    flexDirection: { base: "column", lg: "row" },
                    width: "100%",
                  }}
                >
                  {/* Hire Library */}
                  <Box
                    sx={{
                      width: { base: "100%", lg: "372px" },
                      height: "44px",
                      padding: "12px 16px 12px 16px",
                      borderRadius: "4px",
                      border: "1px solid #00C49A",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      label="Hire360 Library"
                      value="Hire360Library"
                      checked={formik.values.librarySelection.includes(
                        "Hire360Library"
                      )}
                      onChange={handleCheckboxChange}
                      control={
                        <CustomCheckbox
                          icon={<CustomUncheckedIcon />}
                          checkedIcon={
                            <CheckIcon
                              sx={{
                                padding: "0.25rem",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          }
                        />
                      }
                    />
                  </Box>
                  {/* My Library */}
                  <Box
                    sx={{
                      width: { base: "100%", lg: "372px" },
                      height: "44px",
                      padding: "12px 16px 12px 16px",
                      borderRadius: "4px",
                      border: "1px solid #00C49A",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      label="My Library"
                      value="MyLibrary"
                      checked={formik.values.librarySelection.includes(
                        "MyLibrary"
                      )}
                      onChange={handleCheckboxChange}
                      control={
                        <CustomCheckbox
                          icon={<CustomUncheckedIcon />}
                          checkedIcon={
                            <CheckIcon
                              sx={{
                                padding: "0.25rem",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          }
                        />
                      }
                    />
                  </Box>
                </Box>
                {formik.touched.librarySelection &&
                  formik.errors.librarySelection && (
                    <FormHelperText error>
                      {formik.errors.librarySelection}
                    </FormHelperText>
                  )}
              </FormControl>
            </Box>
          </Stack>
        </Box>
        {/* footer  */}
        <Box
          sx={{
            padding: "12px 20px",
            display: "flex",
            flexDirection: {
              base: "column",
              xs: "column",
              sm: "column",
              md: "row",
            },
            justifyContent: "space-between",
            borderTop: "1px solid #E9EAEB",
            boxSizing: "border-box",
            gap: "16px",
            alignItems: "flex-end",
          }}
        >
          <Box sx={{ flexGrow: 0 }}></Box>
          <Stack direction={"row"} gap={"16px"}>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              sx={{
                padding: "10px 16px",
                height: "40px",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              // color="#00C49A"
              sx={{
                padding: "10px 16px",
                height: "40px",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                backgroundColor: "#00C49A",
                fontFamily: "Roboto",
                "&:hover": {
                  backgroundColor: "#00C49A",
                },
                color: "#ffffff",
              }}
              onClick={() => {
                console.log(formik);

                formik.handleSubmit();
              }}
            >
              Save Changes
            </Button>
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

export default EditTestOverview;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#FF6812",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#BBC2CC" : "#FF6812",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
