import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  capitalize,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import TabsList from "../../components/candidateShortlisted/Tabs";
import Analytics from "../../components/assessmentAnalytics/Analytics";
import Candidates from "./Candidates";
import Questions from "./Questions";
import { breadcrumbsContext } from "../../context/breadcrumbsContext";
import { PATH_DASHBOARD } from "../../routes/paths";
import AnalyticsHeader from "../../components/assessmentAnalytics/AnalyticsHeader";
import TestOverview from "./TestOverview";
import APICall from "../../utils/api";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../../context/authContext";
import MyLibraryCard from "../../components/libraryPage/MyLibraryCard";
import { getDifficultSettingsByLevel } from "../../utils";
import LoadingScreen from "../../layouts/LoadingScreen";

function TemplateOverview() {
  const { setBreadcrumbsData } = useContext(breadcrumbsContext);
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { assessmentId, template, useTemplateEdit } = location.state;

  const [assessmentDetails, setAssessmentDetails] = useState();
  const [section, setSection] = useState("section_wise");
  const [topics, setTopics] = useState("all");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [assessmentQuestionsStateData, setAssessmentQuestionsStateData] =
    useState([]);
  const [assessmentQuestionsData, setAssessmentQuestionsData] = useState([]);

  console.log(topics, 35);

  // const handleTopicsChange = (event) => {
  //   const { value } = event.target;
  //   if (event.target.name === "section") {
  //     setSection(value);
  //   } else if (event.target.name === "topics") {
  //     setTopics(value);
  //   }
  // };
  const handleTopicsChange = (event) => {
    const { value } = event.target;
    if (event.target.name === "section") {
      setSection(value);
      setTopics("all");
    } else if (event.target.name === "topics") {
      setTopics(value);
    }
  };

  useEffect(() => {
    const filterQuestions = () => {
      try {
        setLoading(true);

        let questions = [];

        if (section === "section_wise") {
          const sectionWiseData = assessmentQuestionsData.skills || []; // Default to empty array

          sectionWiseData.forEach((sectionData) => {
            if (topics === "all") {
              questions = [
                ...questions,
                ...(sectionData.mcqQuestions.easyQuestions || []),
                ...(sectionData.mcqQuestions.mediumQuestions || []),
                ...(sectionData.mcqQuestions.hardQuestions || []),
                ...(sectionData.programmingQuestions.easyQuestions || []),
                ...(sectionData.programmingQuestions.mediumQuestions || []),
                ...(sectionData.programmingQuestions.hardQuestions || []),
              ];
            } else {
              const topicQuestions = {
                easy: (sectionData.mcqQuestions.easyQuestions || []).filter(
                  (q) => q.type === topics.toUpperCase()
                ),
                medium: (sectionData.mcqQuestions.mediumQuestions || []).filter(
                  (q) => q.type === topics.toUpperCase()
                ),
                hard: (sectionData.mcqQuestions.hardQuestions || []).filter(
                  (q) => q.type === topics.toUpperCase()
                ),
              };
              questions = [
                ...questions,
                ...topicQuestions.easy,
                ...topicQuestions.medium,
                ...topicQuestions.hard,
              ];
            }
          });
        } else if (section === "skill_wise") {
          const skillWiseData = assessmentQuestionsData.skills || [];

          skillWiseData.forEach((skillData) => {
            if (topics === "all") {
              questions = [
                ...questions,
                ...(skillData.mcqQuestions.easyQuestions || []),
                ...(skillData.mcqQuestions.mediumQuestions || []),
                ...(skillData.mcqQuestions.hardQuestions || []),
                ...(skillData.programmingQuestions.easyQuestions || []),
                ...(skillData.programmingQuestions.mediumQuestions || []),
                ...(skillData.programmingQuestions.hardQuestions || []),
              ];
            } else {
              if (skillData.skill.name.toLowerCase() === topics.toLowerCase()) {
                const topicQuestions = {
                  easy: skillData.mcqQuestions.easyQuestions || [],
                  medium: skillData.mcqQuestions.mediumQuestions || [],
                  hard: skillData.mcqQuestions.hardQuestions || [],
                };
                questions = [
                  ...questions,
                  ...topicQuestions.easy,
                  ...topicQuestions.medium,
                  ...topicQuestions.hard,
                ];
              }
            }
          });
        }

        setSelectedQuestions(questions);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    filterQuestions();
  }, [section, topics, assessmentQuestionsData]);

  const getTopicOptions = () => {
    let options = [];

    if (section === "section_wise") {
      options = assessmentQuestionsStateData.sectionWise.map((section) => ({
        value: section.section.toLowerCase(),
        label: section.section,
      }));
    } else if (section === "skill_wise") {
      options = assessmentQuestionsStateData.skillWise.map((skill) => ({
        value: skill.skill.name.toLowerCase(),
        label: capitalize(skill.skill.name),
      }));
    }
    return [{ value: "all", label: "All" }, ...options];
  };

  const fetchAssessmentDetails = async () => {
    try {
      const res = await APICall(`/assessment/${assessmentId}`);
      setAssessmentDetails(res.data);
    } catch (error) {
      console.error("Failed to fetch assessment details:", error);
    }
  };

  const tabNames = {
    testoverview: "Test Overview",
    questions: "Questions",
    candidates: "Candidates",
    analytics: "Analytics",
  };

  const allTabItems = [
    { label: "Test Overview", value: "testoverview" },
    {
      label: `Questions (${assessmentDetails?.totalNoOfQuestions || 0})`,
      value: "questions",
    },
    {
      label: `Candidates (${assessmentDetails?.candidateStats?.appeared || 0})`,
      value: "candidates",
    },
    { label: "Analytics", value: "analytics" },
  ];
  const tabItems =
    authUser?.role === "recruiter" ? allTabItems.slice(0, 2) : allTabItems;

  const [currentTab, setCurrentTab] = React.useState(tabItems[0].value);

  useEffect(() => {
    if (location.state && location.state.tabValue) {
      setCurrentTab(location.state.tabValue);
      location.state.tabValue = null;
    }
  }, [location.state]);

  useEffect(() => {
    setBreadcrumbsData([
      { name: "Assessments", href: PATH_DASHBOARD.assessments },
      { name: "Template Library", href: PATH_DASHBOARD.template_library },
      { name: assessmentDetails?.name },
      { name: tabNames[currentTab] },
    ]);
  }, [currentTab, assessmentDetails]);

  useEffect(() => {
    if (assessmentId) {
      fetchAssessmentDetails();
      fetchAssessmentQuationsDetails();
    }
  }, [assessmentId]);

  const fetchAssessmentQuationsDetails = async () => {
    try {
      const res = await APICall(
        `/assessmentQuestion/getQuestionByAssessment/?assessmentId=${assessmentId}`
      );
      setAssessmentQuestionsStateData(res.data.questionStats);
      setAssessmentQuestionsData(res.data.assessmentQuestions);
    } catch (error) {
      console.error("Failed to fetch assessment details:", error);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: "#F2F4F8",
        display: "flex",
      }}
    >
      <Box sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            height: "100%",
            background: "#F2F4F8",
            width: "100%",
          }}
        >
          <Box sx={{ my: "10px" }}>
            <AnalyticsHeader
              assessmentStats={assessmentDetails?.candidateStats}
              assessment={assessmentDetails}
              hideAndShow={template}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              margin: "16px 0",
              background: "#fff",
              padding: "0.5rem 1rem",
              border: "1px solid #E8E9EE",
              borderRadius: "0.25rem",
              marginTop: "6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                gap: "16px",
                // borderBottom:"1px solid #E8E9EE"
              }}
            >
              <TabsList
                value={currentTab}
                tabs={tabItems}
                handleTabChange={setCurrentTab}
              />
              {currentTab === "questions" && authUser.role === "recruiter" && (
                <>
                  <Stack
                    direction={"row"}
                    gap={"16px"}
                    sx={{ borderBottom: "1px solid #E8E9EE" }}
                  >
                    <SelectComponent
                      value={section}
                      onChange={handleTopicsChange}
                      options={[
                        { value: "section_wise", label: "Section Wise" },
                        { value: "skill_wise", label: "Skills Wise" },
                      ]}
                      name="section"
                    />
                    <SelectComponent
                      value={topics}
                      onChange={handleTopicsChange}
                      options={getTopicOptions()}
                      name="topics"
                    />
                  </Stack>

                  {loading && <LoadingScreen />}
                </>
              )}
            </Box>
            <Box>
              {currentTab === "testoverview" && (
                <TestOverview
                  assessmentDetails={assessmentDetails}
                  fetchAssessmentDetails={fetchAssessmentDetails}
                  hideAndShow={template}
                  useTemplateEdit={useTemplateEdit}
                />
              )}
              {currentTab === "questions" && (
                <Questions
                  assessmentId={assessmentId}
                  isTemplate={true}
                  section={section}
                  loading={loading}
                  selectedQuestionsforshowInTmeplates={selectedQuestions}
                  topic={topics}
                />
              )}
              {currentTab === "candidates" && (
                <Candidates
                  assessmentId={assessmentId}
                  candidateStats={assessmentDetails?.candidateStats}
                  fetchAssessment={fetchAssessmentDetails}
                />
              )}
              {currentTab === "analytics" && (
                <Analytics assessmentId={assessmentId} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TemplateOverview;

const SelectComponent = ({ value, onChange, options, name }) => {
  const theme = useTheme();
  return (
    <FormControl sx={{ minWidth: "154px", height: "48px" }}>
      <Select
        value={value}
        onChange={onChange}
        size="small"
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          // height: "30px",
          display: "flex",
          alignItems: "center", // Vertically center
          justifyContent: "center", // Horizontally center (optional)
          height: "70%",
          marginTop: "5px",
        }}
        name={name}
        color="secondary"
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              "&.Mui-selected": {
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.secondary.main,
              },
              "&:hover": {
                backgroundColor: theme.palette.secondary.light,
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
