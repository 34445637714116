import React, { useState } from "react";
import CustomTable from "../../common/customTable/CustomTable";
import { Box, Tooltip, Typography } from "@mui/material";
import { question_analytics_table_rows } from "../../../utils/data";
import DOMPurify from "dompurify";

const tableHeaderCellStylePropsQA = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
};
const tableContentCellTextStylePropsQA = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
};
const tableBodyRowStylePropsQA = {
  height: "48px",
};

const tableHeaderTitlesQA = [
  {
    title: "Sr. no",
    cellStyleProps: {
      borderRight: "1px solid #E8E9EE",
      width: "60px",
    },
  },
  {
    title: "Question",
    cellStyleProps: {
      borderRight: "1px solid #E8E9EE",
      width: "170px",
    },
  },
  {
    title: "Description",
    cellStyleProps: {
      width: "90px",
    },
  },
  {
    title: "Candidates Attempts",
    cellStyleProps: {
      width: "170px",
    },
  },
  {
    title: "Correct Attempts",
    cellStyleProps: {
      width: "140px",
    },
  },
  {
    title: "Incorrect Attempts",
    cellStyleProps: {
      width: "124px",
    },
  },
  {
    title: "Responses",
    cellStyleProps: {
      width: "80px",
    },
  },
];

const tableHeaderCellsQA = () => {
  return tableHeaderTitlesQA.map((item) => {
    return {
      element: item.title,
      cellStyleProps: item.cellStyleProps,
      isSortable: item?.isSortable ? item.isSortable : false,
      cellId: item?.cellId ? item.cellId : "",
    };
  });
};

export default function QuestionAnalyticsDataTable({
  questionAnalyticsData,
  totalCandidatesAttempted,
}) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const tableBodyRowCellQA = (row, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          borderRight: "1px solid #E8E9EE",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography sx={{ ...tableContentCellTextStylePropsQA }}>
            {row.rowId}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          width: "180px",
          maxWidth: "180px",
          ...cellStyleProps,
        },
        element: (
          <Tooltip title={row.question} placement="top" arrow>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                ...tableContentCellTextStylePropsQA,
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(row?.question || ""),
                }}
              />
            </Typography>
          </Tooltip>
        ),
      },
      {
        cellStyleProps: {
          width: "225px",
          maxWidth: "225px",
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Tooltip title={row.description} placement="top" arrow>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                ...tableContentCellTextStylePropsQA,
              }}
            >
              {row.description}
            </Typography>
          </Tooltip>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          width: "130px",
          maxWidth: "130px",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...tableContentCellTextStylePropsQA,
            }}
          >
            {row.totalAttempts} / {row.questionPresentationCount}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: "130px",
          maxWidth: "130px",
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...tableContentCellTextStylePropsQA,
            }}
          >
            {row.correctAttempts}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          width: "130px",
          maxWidth: "130px",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...tableContentCellTextStylePropsQA,
            }}
          >
            {row.incorrectAttempts}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          width: "225px",
          maxWidth: "225px",
          ...cellStyleProps,
        },
        element: (
          <Box sx={{ width: "225px", display: "flex", gap: "8px" }}>
            {row.options &&
              row.options.map((elem, index) => (
                <Tooltip title={elem.text} placement="top" arrow>
                  <Box
                    key={index}
                    sx={{
                      width: "fit-content",
                      padding: "2px 8px",
                      borderRadius: "4px",
                      backgroundColor: elem.correct ? "#D5F9F1" : "#FADCDA",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {String.fromCharCode(65 + index)} - {elem.selectedCount}
                  </Box>
                </Tooltip>
              ))}
          </Box>
        ),
      },
    ];
  };

  const tableBodyContentQA = (data) => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };
    return (data || []).map((row, idx) => {
      return {
        rowId: idx + 1 + (page - 1) * rowsPerPage,
        rowElement: tableBodyRowCellQA(
          { ...row, rowId: idx + 1 + (page - 1) * rowsPerPage },
          cellStyleProps
        ),
      };
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleRowsChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const paginatedData = questionAnalyticsData.questionAnalytics.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const getRowsPerPageOptions = (totalRows) => {
    const options = [10];
    if (totalRows > 10) options.push(25);
    if (totalRows > 25) options.push(50);
    if (totalRows > 50) options.push(100);
    return options;
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      <CustomTable
        tableHeaderCells={tableHeaderCellsQA()}
        tableBodyContent={tableBodyContentQA(paginatedData)}
        tableData={paginatedData}
        sortRows={() => {}}
        tableBodyRowStyleProps={tableBodyRowStylePropsQA}
        tableHeaderCellStyleProps={tableHeaderCellStylePropsQA}
        totalDocuments={questionAnalyticsData.questionAnalytics.length}
        limit={rowsPerPage}
        totalPages={Math.ceil(
          questionAnalyticsData.questionAnalytics.length / rowsPerPage
        )}
        page={page}
        handleChangePage={handleChangePage}
        handleRowsChange={handleRowsChange}
        rowsPerPageOptions={getRowsPerPageOptions(
          questionAnalyticsData.questionAnalytics.length
        )}
      />
    </Box>
  );
}
