import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Typography,
  Checkbox,
  Button,
  Stack,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import SearchBar from "../common/SearchBar";
import Iconify from "../iconify/Iconify";
import { AuthContext } from "../../context/authContext";
import ToggleSquareIcons from "../common/ToggleSquareIcons";
import CommonModal from "../modal/CommonModal";
import DropdownField from "../common/DropdownField";
import { useMessage } from "../../components/snackbar/snackbar";
import FileUpload from "./FileUpload";
import APICall from "../../utils/api";
import { isPending } from "@reduxjs/toolkit";

const SearchHeader = ({
  setGridView,
  gridView,
  currentTab,
  tableParams,
  setTableParams,
  totalDocuments,
  assessmentId,
  fetchAssessment,
  selectedCheckboxes,
  setSelectedCheckboxes,
  updateManyStatus,
  setTotalPending,
  totalPending,
  fetchAssessmentDetails,
  setTestScore,
  testScore,
  setLoading,
  loading,
  setCandidateAssessmentDetails,
  setTotalDocuments,
  setTotalPages,
  setSelectAll,
  selectAll,
  data,
}) => {
  const { authUser } = useContext(AuthContext);
  const [status, setStatus] = useState("All");
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openBulkInviteModal, setOpenBulkInviteModal] = useState(false);
  const [importedInviteData, setImportedInviteData] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const message = useMessage();
  const [invitesData, setInvitesData] = useState({
    name: "",
    email: "",
  });

  const handleChange = (event) => {
    const selectedValue = event.target.value;

    if (event.target.name === "status") {
      setStatus(selectedValue);
      setTableParams({
        ...tableParams,
        filters: { ...tableParams.filters, currentStatus: event.target.value },
        pagination: { ...tableParams.pagination, page: 1 },
      });
    } else if (event.target.name === "filterDropdown") {
      setTestScore(selectedValue);
    }
  };

  const handleFilterOpen = () => {
    console.log("Filter Opened");
  };

  const handleInvites = (e) => {
    setInvitesData({ ...invitesData, [e.target.name]: e.target.value });
  };

  const handleInviteSent = () => {
    console.log("User Data", invitesData);
    setOpenUserModal(false);
    message("Invite sent successfully", "success");
  };

  const showBulkInvite = () => {
    console.log("");
    setOpenUserModal(false);
    setOpenBulkInviteModal(true);
  };
  const fetchPassedPending = async () => {
    try {
      setLoading(true);
      setTableParams({
        ...tableParams,
        filters: { ...tableParams.filters, isPending: "True", query: "" },
        pagination: { ...tableParams.pagination, page: 1 },
      });
      // let url = `/candidateAssessment?assessmentId=${assessmentId}&page=${tableParams.pagination.page}&limit=${tableParams.pagination.limit}&currentStatus=Passed&isPending=true`;

      // const res = await APICall(url);
      // if (testScore !== "All") {
      //   url += `&scoreFilter=${testScore}`;
      // }
      // const filteredPending = res.data.documents.filter(
      //   (item) => item.currentStatus === "Appeared"
      // );
      // setTotalPending(res.data.total);
      // setCandidateAssessmentDetails(res.data.documents);
      // setTotalDocuments(res.data.total);
      // setTotalPages(Math.ceil(res.data.total / tableParams.pagination.limit));
    } catch (error) {
      console.error("Failed to fetch assessment details:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSelectAll = () => {
    // Determine the new state of selectAll
    const newSelectAllState = !selectAll;
    setSelectAll(newSelectAllState);

    if (currentTab === "passed") {
      if (newSelectAllState) {
        // If select all is being checked, fetch passed pending and select all candidates with status "Appeared"
        fetchPassedPending();

        const allIds = data
          ?.filter((row) => row.currentStatus === "Appeared")
          .map((row) => row.candidateAssessmentId);
        setSelectedCheckboxes(allIds);
      } else {
        console.log("uncheck");
        // If select all is being unchecked
        const { isPending, ...restFilters } = tableParams.filters;
        setTableParams({
          ...tableParams,
          filters: {
            ...restFilters,
            query: "",
          },
          pagination: {
            ...tableParams.pagination,
            page: 1,
          },
        });
        fetchAssessmentDetails(); // Call fetchAssessmentDetails
        setSelectedCheckboxes([]); // Clear selections
      }
    } else {
      // For tabs other than "passed", select or unselect all checkboxes
      if (newSelectAllState) {
        const allIds = data.map((row) => row.candidateAssessmentId);
        setSelectedCheckboxes(allIds);
      } else {
        setSelectedCheckboxes([]); // Clear selections
      }
    }
  };

  return (
    <Box>
      {/* for desktop */}
      <Grid
        container
        spacing={1}
        sx={{ mt: "0.1rem", display: { base: "none", md: "flex" } }}
      >
        <Grid item base={6} lg={7} xl={8}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#010101",
                fontFamily: "Nunito",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {authUser.role === "recruiter" &&
                  currentTab !== "invited" &&
                  currentTab !== "shortlisted" && (
                    <>
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                      <Typography>
                        {currentTab === "passed"
                          ? `Review Pending Candidates (${totalPending || 0})`
                          : `Select All (${selectedCheckboxes.length || 0})`}
                      </Typography>
                    </>
                  )}
                {(authUser?.role === "super admin" ||
                  authUser?.role === "hr") && (
                  <Typography>{`Candidates (${
                    totalDocuments || 0
                  })`}</Typography>
                )}
              </Box>
            </Typography>
            <SearchBar
              placeholder="Search Candidate"
              width="296px"
              value={tableParams.filters.query}
              onChange={(e) => {
                setTableParams({
                  ...tableParams,
                  filters: { ...tableParams.filters, query: e.target.value },
                  pagination: { ...tableParams.pagination, page: 1 },
                });
              }}
            />
            {(currentTab === "reviewed" || currentTab === "passed") &&
              selectedCheckboxes.length !== 0 && (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "4px",
                    color: "white",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    textTransform: "none",
                    padding: "6px 12px",
                  }}
                  onClick={() =>
                    updateManyStatus(selectedCheckboxes, "Shortlisted")
                  }
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "white",
                      textWrap: "nowrap",
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <img
                      src="/assets/icons/userPlusWhite.svg"
                      alt="check"
                      style={{ marginRight: "4px" }}
                    />
                    {`Shortlist (${selectedCheckboxes.length})`}
                  </Typography>
                </Button>
              )}
            {currentTab === "reviewed" && selectedCheckboxes.length !== 0 && (
              <Button
                variant={currentTab === "shortlisted" ? "contained" : ""}
                sx={{
                  borderRadius: "4px",
                  color: "white",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "16px",
                  textTransform: "none",
                  padding: "6px 12px",
                  border:
                    currentTab === "reviewed" ? "1px solid #FF6812" : "none",
                }}
                onClick={() => updateManyStatus(selectedCheckboxes, "Appeared")}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: currentTab === "reviewed" ? "#FF6812" : "white",
                    textWrap: "nowrap",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  <img
                    src={
                      currentTab === "reviewed"
                        ? "/assets/icons/userPlusorange.svg"
                        : "/assets/icons/userPlusWhite.svg"
                    }
                    alt="check"
                    style={{ marginRight: "4px" }}
                  />
                  {`Pass (${selectedCheckboxes.length})`}
                </Typography>
              </Button>
            )}

            {currentTab === "passed" && selectedCheckboxes.length !== 0 && (
              <Button
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "4px 6px",
                  border: "1px solid #FF6812",
                  borderRadius: "4px",
                  height: "30px",
                  minWidth: "113px",
                  boxSizing: "border-box",
                }}
                onClick={() => updateManyStatus(selectedCheckboxes, "Reviewed")}
              >
                <Box
                  component={"img"}
                  src="/assets/icons/userPlusorange.svg"
                  alt="copy icon"
                  sx={{ width: "20px", height: "18px", marginRight: "4px" }}
                ></Box>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#FF6812",
                    whiteSpace: "nowrap",
                  }}
                >
                  {`Review (${selectedCheckboxes.length})`}
                </Typography>
              </Button>
            )}
          </Box>
        </Grid>
        {currentTab === "invited" && (
          <Grid
            item
            base={6}
            lg={5}
            xl={4}
            container
            justifyContent="flex-end"
            alignItems="center"
            sx={{ gap: "8px" }} // Added gap for spacing between the dropdown and the ToggleButton
          >
            <FormControl
              sx={{
                backgroundColor: "#fff",
                height: "40px",
                width: "120px",
              }}
            >
              <InputLabel
                sx={{
                  letterSpacing: "0.5px",
                }}
              >
                Status
              </InputLabel>

              <Select
                name="status"
                sx={{
                  "& .select-padding": {
                    padding: "7px 8px", // Adjust padding as needed
                  },
                  height: "40px",
                }}
                value={tableParams.filters.currentStatus || "All"}
                label="Status"
                onChange={handleChange}
                inputProps={{ className: "select-padding" }}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>

                <MenuItem value="Invited">Invited</MenuItem>

                <MenuItem value="Invite Expired">Invite Expired</MenuItem>
                <MenuItem value="Appeared">Appeared</MenuItem>
              </Select>
            </FormControl>

            {authUser?.role === "recruiter" && currentTab === "invited" && (
              <ToggleSquareIcons
                setShortCandidates={setGridView}
                shortCandidates={gridView}
              />
            )}
          </Grid>
        )}

        {authUser.role === "recruiter" && (
          <Grid
            item
            base={6}
            lg={5}
            xl={4}
            container
            alignItems="center"
            justifyContent="flex-end"
            sx={{ gap: "8px" }}
          >
            <FormControl
              sx={{
                backgroundColor: "#fff",
                // height: "36px",
                width: "42%",
              }}
            >
              {currentTab !== "invited" && (
                <>
                  <InputLabel
                    sx={{
                      letterSpacing: "0.5px",
                    }}
                  >
                    Test Score
                  </InputLabel>
                  <Select
                    name="filterDropdown"
                    sx={{
                      "& .select-padding": {
                        padding: "7px ", // Adjust padding as needed
                      },
                      height: "36px",
                    }}
                    value={testScore}
                    label="Test Score"
                    onChange={handleChange}
                    inputProps={{ className: "select-padding" }}
                  >
                    <MenuItem value="All">All</MenuItem>

                    <MenuItem value="topToLow">
                      Recommended (High to Low)
                    </MenuItem>
                    <MenuItem value="greaterThan75">Greater than 75%</MenuItem>
                    <MenuItem value="greaterThan50">Greater than 50%</MenuItem>
                    <MenuItem value="lessThan50">Less Than 50%</MenuItem>
                  </Select>
                </>
              )}
            </FormControl>

            {currentTab !== "invited" && (
              <ToggleSquareIcons
                setShortCandidates={setGridView}
                shortCandidates={gridView}
              />
            )}
          </Grid>
        )}
      </Grid>
      <CommonModal
        icon={"/assets/sendgreen.svg"}
        handleClose={() => setOpenUserModal(false)}
        open={openUserModal}
        title={"Invite Candidates"}
        UserProfile
        sendButtonName={"Send Invites"}
        hideCancel={true}
        bulkInvite={true}
        showBulkInvite={showBulkInvite}
        onSubmit={handleInviteSent}
      >
        <Stack spacing={3}>
          <Grid>
            <Grid item xs={12}>
              <DropdownField
                id="name"
                name="name"
                label="Name"
                defaultValue={invitesData.name}
                onChange={handleInvites}
                sx={{ height: "55px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <DropdownField
                id="email"
                name="email"
                label="Email"
                defaultValue={invitesData.email}
                onChange={handleInvites}
                sx={{ height: "55px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <DropdownField
                select
                data={[
                  { value: "active", label: "Active" },
                  { value: "inactive", label: "Inactive" },
                ]}
                id="validity"
                name="validity"
                label="Validity"
                defaultValue={invitesData.validity}
                onChange={handleInvites}
              />
            </Grid>
          </Grid>
        </Stack>
      </CommonModal>
      <CommonModal
        icon={"/assets/sendgreen.svg"}
        handleClose={() => setOpenBulkInviteModal(false)}
        open={openBulkInviteModal}
        title={"Bulk Invite Candidates"}
        sendButtonName={"Send Bulk Invites"}
        cancelButtonName={"Cancel"}
        onSubmit={handleInviteSent}
      >
        <FileUpload onFileChange={setImportedInviteData} />
      </CommonModal>
      {/* for tablet/mobile */}
      <Box
        sx={{
          my: "1rem",
          ml: "0.25rem",
          display: { base: "block", md: "none" },
        }}
      >
        <Typography
          sx={{
            whiteSpace: "nowrap",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#010101",
            fontFamily: "Nunito",
          }}
        >
          Candidates
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{ display: { base: "flex", md: "none" } }}
      >
        <Grid item base={12} sm={9}>
          <SearchBar placeholder="Search" width="100%" />
        </Grid>
        <Grid item base={12} sm={3}>
          <FormControl
            fullWidth
            sx={{
              backgroundColor: "#fff",
              height: "40px",
            }}
          >
            <InputLabel
              sx={{
                letterSpacing: "0.5px",
              }}
            >
              Status
            </InputLabel>
            <Select
              sx={{
                "& .select-padding": {
                  padding: "10px 8px", // Adjust padding as needed
                },
                height: "40px",
              }}
              value={status}
              label="Status"
              onChange={handleChange}
              inputProps={{ className: "select-padding" }}
            >
              <MenuItem value="All">All</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchHeader;
