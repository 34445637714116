import { createTheme } from "@mui/material/styles";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  breakpoints: {
    values: {
      base: 320,
      xs: 425,
      xss: 568,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: "#00c49a",
      black: "#363E45",
      gray: "#E8E9EE",
    },
    secondary: {
      main: "#FF6812",
      light: "#FFF0E7",
    },
    tertiary: {
      blue: "#384455",
    },
    green: {
      main: "#004E3E",
      light: "#D5F9F1",
      secondaryGreen: "#00C49A",
    },
    red: {
      main: "#733430",
      error: "#d32f2f",
      light: "#FADCDA",
      red300: "#F94948",
    },
    gray: {
      main: "#71777B",
      dark: "#384455",
    },
    text: {
      gray300: "#8C9194",
      gray100: "#E9EAEB",
    },
    blackButton: createColor("#363E45"),
    orangeButton: createColor("#FF6812"),
    textButtonColor: createColor("#E9EAEB"),
    greenButton: createColor("#00C49A"),
    whiteButton: createColor("#ffffff"),
    background: {
      default: "#fff",
      gray: "#F2F4F8",
      darkGray: "#69778A",
      textGray: "#8C9194",
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    color: "#363E45",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "20px",
          padding: "12px 16px 12px",
          boxShadow: "none",
        },
      },
    },
  },
});

export default theme;
