import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme,
} from "@mui/material";

const CheatingCheckbox = ({ data, selectedRadio, setSelectedRadio }) => {
  const theme = useTheme();

  const handleChange = (event) => {
    console.log(event.target.value, "event.target.value");
    setSelectedRadio(event.target.value);
  };

  // Build array with counts
  const items = [
    "All",
    ...(data?.assessmentResult?.detectedCheatings || []),
  ].map((elem) => {
    let count = 0;
    switch (elem) {
      case "All":
        count = data?.assessmentResult?.webCamSnapShots?.length || 0;
        break;
      case "Found missing candidate":
        count =
          data?.assessmentResult?.missingCandidateSnapShotIds?.length || 0;
        break;
      case "Found duplicate candidate":
        count =
          data?.assessmentResult?.duplicateCandidateSnapShotIds?.length || 0;
        break;
      case "Found multiple candidates":
        count =
          data?.assessmentResult?.multipleCandidateSnapShotIds?.length || 0;
        console.log(
          data?.assessmentResult?.multipleCandidateSnapShotIds?.length,
          "count"
        );
        break;
      default:
        break;
    }
    return { elem, count };
  });

  const shouldIncludeAll = items.some(
    ({ elem, count }) => elem !== "All" && count > 1
  );

  const filteredItems = items.filter(({ elem, count }) =>
    elem === "All" ? shouldIncludeAll : count >= 1
  );

  return (
    <FormGroup row sx={{ gap: "8px" }}>
      <RadioGroup row value={selectedRadio} onChange={handleChange}>
        {filteredItems.map(({ elem, count }, i) => (
          <FormControlLabel
            key={i}
            value={elem}
            control={<Radio sx={{ "&.Mui-checked": { color: "#FF6812" } }} />}
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#363E45",
                }}
              >
                {elem} ({count})
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </FormGroup>
  );
};

export default CheatingCheckbox;
