import PropTypes from "prop-types";
// @mui
import { Box, Link, Stack, Typography, Breadcrumbs } from "@mui/material";
//
import LinkItem from "./LinkItem";
import { StyledSection } from "./styles";

// ----------------------------------------------------------------------

CustomBreadcrumbs.propTypes = {
  sx: PropTypes.object,
  action: PropTypes.node,
  links: PropTypes.array,
  heading: PropTypes.string,
  moreLink: PropTypes.array,
  activeLast: PropTypes.bool,
};

export default function CustomBreadcrumbs({
  links,
  action,
  heading,
  moreLink,
  activeLast,
  sx,
  ...other
}) {
  console.log(links, "links");

  const lastLink = links.length > 1 ? links[links.length - 1].name : "";

  return (
    <Box sx={{ ...sx }}>
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          {/* HEADING */}
          {heading && (
            <Typography variant="h4" gutterBottom>
              {heading}
            </Typography>
          )}

          {/* BREADCRUMBS */}
          {!!links.length && (
            <StyledSection>
              <Breadcrumbs separator={<Separator />} {...other}>
                {links.map((link) => (
                  <LinkItem
                    key={link.name}
                    link={link}
                    activeLast={activeLast}
                    disabled={link.name === lastLink}
                  />
                ))}
              </Breadcrumbs>
            </StyledSection>
          )}
        </Box>
        {action ? <Box sx={{ flexShrink: 0 }}> {action} </Box> : ""}
      </Stack>

      {/* MORE LINK */}
      {!!moreLink && (
        <Box sx={{ mt: 2 }}>
          {moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noOpener"
              sx={{ display: "table" }}
            >
              {href}
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

function Separator() {
  return <Box component="img" src="/assets/icons/chevronRight.svg" />;
}
