import React from "react";
import Chart from "react-apexcharts";
import "./skills.css";
const SkillsChart = () => {
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false, // Disable the toolbar to remove all menu options
      },
    },
    xaxis: {
      categories: ["HTML", "CSS", "Javascript", "React", "Angular"],
      tickPlacement: "off",
      axisTicks: {
        show: false, // Hide the border line on the X-axis if needed
      },
      axisBorder: {
        show: true, // Hide the border line on the X-axis if needed
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      //   title: {
      //     text: "Points",
      //   },
      min: 0, // Set Y-axis minimum value
      max: 200,
    },
    grid: {
      show: true,
      strokeDashArray: 5, // Add dotted lines
      borderColor: "#e0e0e0", // Optional: change color if needed
    },
    colors: ["#FF9595", "#EFB02E", "#00C49A"],
    // tooltip: {
    //   enabled: false, // Disable tooltips on hover
    // },
  };

  const series = [
    {
      name: "Training Required",
      data: [50, 120, 150, 30, 90],
    },
    {
      name: "Intermediate",
      data: [90, 140, 80, 60, 75],
    },
    {
      name: "Passed",
      data: [150, 100, 130, 20, 50],
    },
  ];

  return (
    <div className="skills-chart">
      <Chart options={options} series={series} type="bar" height="600" />
    </div>
  );
};

export default SkillsChart;
