import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Alert,
  Snackbar,
  Drawer,
  TextField,
} from "@mui/material";
import { candidates_passed_table_rows } from "../../utils/data";
import CustomTable from "../common/customTable/CustomTable";
import { Checkbox, Tooltip } from "@mui/material";
import SortingIcon from "../icons/SortingIcon";
import { useNavigate } from "react-router-dom";
import CheatingDetected from "../cheatingDetected/CheatingDetected";
import { AuthContext } from "../../context/authContext";
import { useFormik } from "formik";
import * as yup from "yup";
import CommonModal from "../../components/modal/CommonModal";
import { useMessage } from "../../components/snackbar/snackbar";
import CustomSelect from "../customSelect";
import { getPaginationOptions, handleChangePage } from "../../utils/pagination";
import { getColorByStatus } from "../../utils";

const columnsWidth = [
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};
const tableBodyRowStyleProps = {
  height: "48px",
};

export default function Passed({
  setLoading,
  loading,
  setTableParams,
  data,
  totalDocuments,
  totalPages,
  selectedCheckboxes,
  tableParams,
  handleCheckboxChange,
  handleStatusClick,
  setSelectedRow,
  selectedRow,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerHeading, setDrawerHeading] = useState("");
  const { authUser } = React.useContext(AuthContext);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [allAssessments, setAllAssessments] = useState([]);
  const message = useMessage();

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleViewDetails = () => {
    if (selectedRow) {
      console.log(selectedRow);
      navigate(`/assessments/candidateDetails`, {
        state: {
          candidateAssessmentId: selectedRow?.candidateAssessmentId,
          assessmentId: selectedRow?.assessmentId?._id,
          assessmentDetails: selectedRow?.assessmentId,
        },
      });
    }
    handleMenuClose();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCheatingClick = (row) => {
    // setDrawerHeading("View Snapshot");
    setDrawerOpen(true);
    setSelectedRow(row);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };
  const handleSubmit = (values) => {
    console.log(values);
    handleCloseInviteModal(false);
    message("Invite Resent Successfully", "success");
  };

  const validationSchema = yup.object({
    cutOff: yup.string().required("CutOff is required"),
  });

  const formik = useFormik({
    initialValues: {
      cutOff: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleCloseInviteModal = () => {
    setOpenInviteModal(false);
  };

  const handleGetLink = () => {
    const link =
      "https://staging.dev.theeliteqa.com/candidateresult/66694b7d69dfc4fef59e9df6";
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setAlertMessage("Result Link copied to clipboard!");
        setOpen(true);
      })
      .catch(() => {
        setAlertMessage("Failed to copy the link.");
        setOpen(true);
      });
    handleMenuClose();
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")} mins`;
  };

  const tableHeaderTitles = [
    {
      title:
        authUser?.role === "super admin" || authUser?.role === "hr"
          ? "Sr. no"
          : "recruiter"
          ? ""
          : "",
      cellStyleProps: {
        width: columnsWidth[0],
      },
    },
    {
      isSortCol: true,
      colId: "candidate",
      title: (
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography
            sx={{ fontSize: "12px", fontWeight: "500", fontFamily: "Roboto" }}
          >
            Candidate
          </Typography>
          <SortingIcon />
        </Box>
      ),
      cellStyleProps: {
        width: columnsWidth[1],
      },
    },
    {
      title: "Attempt %",
      cellStyleProps: {
        width: columnsWidth[2],
      },
    },
    {
      title: "Submitted on",
      cellStyleProps: {
        width: columnsWidth[3],
      },
    },
    {
      isSortCol: true,
      colId: "score",
      title: (
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography
            sx={{ fontSize: "12px", fontWeight: "500", fontFamily: "Roboto" }}
          >
            Score
          </Typography>
          <SortingIcon />
        </Box>
      ),
      cellStyleProps: {
        width: columnsWidth[4],
      },
    },
    {
      title: "Percentile",
      cellStyleProps: {
        width: columnsWidth[3],
      },
    },
    {
      title: "Test Duration",
      cellStyleProps: {
        width: columnsWidth[5],
      },
    },
    {
      title: "Status",
      cellStyleProps: {
        width: columnsWidth[6],
      },
    },
    {
      title: "",
      cellStyleProps: {
        width: columnsWidth[7],
      },
    },
  ];

  const tableHeaderCells = () => {
    return tableHeaderTitles.map((item) => {
      return {
        isSortCol: item?.isSortCol ? item.isSortCol : false,
        colId: item?.colId ? item.colId : "",
        element: item.title,
        cellStyleProps: item.cellStyleProps,
        isSortable: item?.isSortable ? item.isSortable : false,
        cellId: item?.cellId ? item.cellId : "",
      };
    });
  };

  const tableBodyRowCell = (row, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element:
          authUser.role === "recruiter" ? (
            <Checkbox
              sx={{
                ...tableContentCellTextStyleProps,
              }}
              disabled={row?.currentStatus !== "Appeared"}
              checked={selectedCheckboxes?.includes(row?.candidateAssessmentId)}
              onChange={() => handleCheckboxChange(row?.candidateAssessmentId)}
            />
          ) : (
            <Typography
              sx={{
                ...tableContentCellTextStyleProps,
              }}
            >
              {row?.srNo}
            </Typography>
          ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img
              src={
                row?.candidateProfilePicture
                  ? row?.candidateProfilePicture
                  : "/assets/user.png"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/user.png";
              }}
              alt="user"
              style={{ height: "30px", width: "30px", borderRadius: "50%" }}
            />
            <Typography
              sx={{
                ...tableContentCellTextStyleProps,
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(`/assessments/candidateDetails`, {
                  state: {
                    candidateAssessmentId: row?.candidateAssessmentId,
                    assessmentId: row?.assessmentId?._id,
                    assessmentDetails: row?.assessmentId,
                  },
                })
              }
            >
              {row?.candidateId?.name}
              {row?.assessmentResult?.totalObtainedScore <
                row?.assessmentId?.cutOff &&
                row?.currentStatus !== "invited" &&
                row?.currentStatus !== "invite expired" && (
                  <Tooltip title="Failed" arrow>
                    <img
                      src="/assets/exclamation-circle.svg"
                      alt="Alert Icon"
                      style={{
                        marginRight: "4px",
                        marginLeft: "4px",
                        height: "18px",
                      }}
                    />
                  </Tooltip>
                )}
            </Typography>
          </Box>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.attemptedQuestions && row?.totalQuestions
              ? `${(
                  (row.attemptedQuestions / row.totalQuestions) *
                  100
                ).toFixed(2)}%`
              : "0"}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {formatDate(row?.testSubmittedOn)}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {`${row?.assessmentResult?.totalObtainedScore || "0"} / ${
              row?.assessmentId?.totalScore
            }`}{" "}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.assessmentId?.totalScore && row?.totalObtainedScore
              ? `${(
                  (row.totalObtainedScore / row.assessmentId.totalScore) *
                  100
                ).toFixed(0)}%`
              : "0"}
          </Typography>
        ),
      },
      {
        cellStyleProps,
        element: (
          <Typography
            variant="p"
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.assessmentResult?.totalTimeSpent
              ? formatTime(row?.assessmentResult?.totalTimeSpent)
              : "00:00 mins"}
          </Typography>
        ),
      },
      {
        cellStyleProps,
        element: (
          <Box display="flex" alignItems="center" gap={1}>
            {row?.assessmentResult?.isCheatingDetected && (
              <Tooltip
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      display: "flex",
                      alignItems: "center",
                      bgcolor: "#313E4F",
                      height: "40px",
                      padding: "8px 12px",
                      borderRadius: "0.25rem",
                    },
                    "& .MuiTooltip-arrow": {
                      // background: "#313E4F",
                    },
                  },
                }}
                placement="bottom-start"
                arrow
                sx={{ background: "#fff" }}
                title={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#E9EAEB",
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCheatingClick(row);
                      }}
                    >
                      Cheating detected
                    </Typography>
                    <img
                      src="/assets/redirect_icon_2.svg"
                      alt="redirect icon"
                    />
                  </Box>
                }
              >
                <img src="/assets/alert_icon.svg" alt="AlertIcon" />
              </Tooltip>
            )}
            {getStatus({
              id:
                row?.currentStatus === "Appeared"
                  ? row?.assessmentResult?.totalObtainedScore >=
                    row?.assessmentId?.cutOff
                    ? "Passed"
                    : "Failed"
                  : row?.currentStatus,

              tag:
                row?.currentStatus === "Appeared"
                  ? row?.assessmentResult?.totalObtainedScore >=
                    row?.assessmentId?.cutOff
                    ? "Passed"
                    : "Failed"
                  : row?.currentStatus,
              cheated: row?.assessmentResults?.isCheated,
            })}
          </Box>
        ),
      },
      {
        cellStyleProps,
        element: (
          <IconButton onClick={(event) => handleMenuClick(event, row)}>
            <img src="/assets/three_dots_icon.svg" alt="three dots" />
          </IconButton>
        ),
      },
    ];
  };

  const tableBodyContent = () => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };

    return data?.map((row, idx) => {
      return {
        sortable: { score: parseInt(row?.score), candidate: row?.candidate },
        rowId: row?.id,
        rowElement: tableBodyRowCell({ ...row, srNo: idx + 1 }, cellStyleProps),
      };
    });
  };

  const getStatus = (status) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {status?.cheated && (
          <Tooltip
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "40px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="bottom-start"
            arrow
            sx={{ background: "#fff" }}
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    color: "#E9EAEB",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                  onClick={handleCheatingClick}
                >
                  {status?.cheated
                    ? "Cheating Detected"
                    : "No Cheating Detected"}
                </Typography>
                <img src="/assets/redirect_icon_2.svg" alt="redirect icon" />
              </Box>
            }
          >
            <Box
              component="img"
              src="/assets/alert_icon.svg"
              alt="AlertIcon"
              sx={{ cursor: "pointer" }}
            />
          </Tooltip>
        )}
        <Box
          sx={{
            display: "inline-block",
            background: getColorByStatus(status.id).bg,
            padding: "4px 12px",
            color: getColorByStatus(status.id).color,
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
            fontFamily: "Roboto",
            borderRadius: "0.25rem",
            whiteSpace: "nowrap",
          }}
        >
          {status.tag}
        </Box>
      </Box>
    );
  };

  let timeoutId = null;

  const handleChangePage = (newPage) => {
    setLoading(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          page: newPage,
        },
      });
    }, 500);
  };

  const handleRowsChange = (event) => {
    setLoading(true);
    const value = event?.target?.value;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          limit: value,
        },
      });
    }, 500);
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      <CustomTable
        loading={loading}
        tableHeaderCells={tableHeaderCells()}
        tableHeaderCellStyleProps={tableHeaderCellStyleProps}
        tableBodyContent={tableBodyContent()}
        tableData={data}
        tableBodyRowStyleProps={tableBodyRowStyleProps}
        sortRows={() => {}}
        limit={tableParams.pagination.limit}
        page={tableParams.pagination.page}
        totalPages={totalPages}
        totalDocuments={totalDocuments}
        handleChangePage={handleChangePage}
        handleRowsChange={handleRowsChange}
        rowsPerPageOptions={getPaginationOptions(
          totalDocuments,
          tableParams.pagination.page,
          tableParams.pagination.limit
        )}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleViewDetails}>
          <img
            style={{
              height: "22px",
              width: "22px",
              marginRight: "8px",
              color: "#000",
            }}
            src="/assets/eye copy.svg"
          />
          View Details
        </MenuItem>
        <MenuItem onClick={handleGetLink}>
          <img style={{ marginRight: "8px" }} src="/assets/arrow-up.svg" />
          Get Result Link
        </MenuItem>
        {authUser.role === "recruiter" &&
          selectedRow?.currentStatus === "Appeared" && (
            <MenuItem
              onClick={() => {
                handleStatusClick("Shortlisted");
                setAnchorEl(null);
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/icons/userCheck.svg"
                alt="shortlist"
              />
              Move to Shortlist
            </MenuItem>
          )}
        {selectedRow?.currentStatus === "Appeared" &&
          authUser?.role === "recruiter" && (
            <MenuItem
              onClick={() => {
                handleStatusClick("Reviewed");
                setAnchorEl(null);
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/reportAnalytics (1).svg"
                alt="failed"
              />
              Move to Reviewed
            </MenuItem>
          )}
      </Menu>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{
            width: "100%",
            bgcolor: "#00c49a",
            color: "white",
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(!drawerOpen)}
        PaperProps={{
          sx: { width: "400px" },
        }}
      >
        <Box sx={{ padding: "16px" }}>
          <Typography variant="h6">{drawerHeading}</Typography>

          <CheatingDetected
            open={drawerOpen}
            setOpen={setDrawerOpen}
            drawerHeading="View Snapshots"
            data={selectedRow}
          />
        </Box>
      </Drawer>
      <CommonModal
        icon={"/assets/icons/briefcaseBlack.svg"}
        handleClose={handleCloseInviteModal}
        open={openInviteModal}
        title="Resend Invite"
        sendButtonName="Resend"
        onSubmit={handleSubmit}
      >
        <TextField
          sx={{ marginBottom: "16px" }}
          fullWidth
          size="small"
          id="name"
          label="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          required
        />
        <TextField
          sx={{ marginBottom: "16px" }}
          fullWidth
          size="small"
          id="email"
          label="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          required
        />
        <CustomSelect
          title={"Assessment"}
          options={allAssessments.map((assessment) => ({
            label: assessment.name,
            value: assessment._id,
          }))}
          value={formik.values.assessmentId}
          name="assessmentId"
          onChange={(value) => {
            formik.setFieldValue("assessmentId", value);
          }}
          error={formik.touched.assessmentId && formik.errors.assessmentId}
          helperText={formik.touched.assessmentId && formik.errors.assessmentId}
          required
        />
      </CommonModal>
    </Box>
  );
}
