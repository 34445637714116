import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const TabsSwitched = ({ data }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = data.length;
  const [page, setPage] = React.useState(1);
  const count = data?.assessmentResult?.screenSnapShots?.length;
  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
    setActiveStep((prevActiveStep) =>
      prevActiveStep - 1 < 0 ? maxSteps - 1 : prevActiveStep - 1
    );
  };

  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, count));
    setActiveStep((prevActiveStep) =>
      prevActiveStep + 1 >= maxSteps ? 0 : prevActiveStep + 1
    );
  };
  return (
    <Stack direction={"column"} alignItems={"center"} gap={"16px"}>
      <Stack direction={"column"} alignItems={"center"} gap={"8px"}>
        {data?.assessmentResult?.screenSnapShots?.map((step, index) =>
          index === activeStep ? (
            <Box
              key={step.label}
              component="img"
              sx={{
                height: "auto",
                borderRadius: "4px",
                width: "100%",
                maxHeight: "350px",
                objectFit: "contain",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
              }}
              src={step ? step : "/assets/person/stack.png"}
            />
          ) : null
        )}

        {count > 0 && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              disabled={activeStep + 1 === 1}
              onClick={handlePrevPage}
              sx={{
                padding: "6px",
                borderRadius: "4px",
                border: "1px solid #D3D9E2",
                minWidth: "28px",
                boxSizing: "border-box",
                fontSize: "14px",
              }}
            >
              <KeyboardArrowLeft color="secondary" fontSize="1rem" />
            </Button>
            <Typography
              variant="body1"
              sx={{
                padding: "4px",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#71777B",
              }}
            >{`${activeStep + 1} of ${count}`}</Typography>
            <Button
              disabled={activeStep + 1 === count}
              variant="outlined"
              onClick={handleNextPage}
              color="inherit"
              sx={{
                padding: "6px",
                borderRadius: "4px",
                border: "1px solid #D3D9E2",
                minWidth: "28px",
                boxSizing: "border-box",
                fontSize: "14px",
              }}
            >
              <KeyboardArrowRight color="secondary" fontSize="1rem" />
            </Button>
          </Stack>
        )}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "16px",
          }}
        >
          {data?.assessmentResult?.screenSnapShots?.map((url, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                sx={{
                  boxSizing: "border-box",
                  border: "1px solid #E8E9EE",
                  borderRadius: "4px",
                  height: { base: "64px", xs: "64px", md: "120px" },
                  width: { base: "60px", xs: "60px", md: "160px" },
                  boxShadow:
                    index === activeStep ? "0px 0px 0px 2px #0077ff" : "none", // Highlight active step
                }}
                src={url}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => setActiveStep(index)} // Set activeStep on thumbnail click
              />
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
              >
                {`Snapshot ${index + 1}`}
              </Typography>
            </Box>
          ))}
        </Box>
      </Stack>
    </Stack>
  );
};

export default TabsSwitched;
