import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";

const data = [
  {
    id: 1,
    title: "HTML",
    updated: "2020-09-01 • 10:56",
    score: 100,
    color: "#009D7B",
  },
  {
    id: 2,
    title: "CSS",
    updated: "2020-09-01 • 10:56",
    score: 80,
    color: "#00C49A",
  },
  {
    id: 3,
    title: "JavaScript",
    updated: "2020-09-01 • 10:56",
    score: 60,
    color: "#00C49A",
  },
  {
    id: 4,
    title: "React",
    updated: "2020-09-01 • 10:56",
    score: 40,
    color: "#7EE5CF",
  },
  {
    id: 5,
    title: "Angular",
    updated: "2020-09-01 • 10:56",
    score: 20,
    color: "#7EE5CF",
  },
];
const TestAttempted = ({ skillQuestionData }) => {
  return (
    <Box sx={{ width: "100%" }}>
      {skillQuestionData.map((item, idx) => {
        return (
          <Box
            key={item.skillName}
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: { base: "wrap", sm: "nowrap" },
              // gap: "16px",
              padding: "1rem",
              borderBottom: `${
                idx + 1 !== skillQuestionData.length
                  ? "1px dashed #EBEBEB"
                  : "none"
              }`,
              //   strokeDasharray: 5,
              //   stroke: "#EBEBEB",
              //   strokeLinecap: "butt",
            }}
          >
            <Box sx={{ width: "80px" }}>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#686868",
                  marginBottom: "8px",
                }}
              >
                {item.skillName &&
                  item.skillName.charAt(0).toUpperCase() +
                    item.skillName.slice(1)}
              </Typography>
              {/* <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "10px",
                  lineHeight: "14px",
                  color: "#686868",
                }}
              >
                {item.updated}
              </Typography> */}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <LinearProgress
                variant="determinate"
                value={
                  (item.totalSkillAttempts / item.totalQuestionAttempts) * 100
                }
                sx={{
                  width: "90%",
                  height: "12px",
                  bgcolor: "E0E0E0", // Background color
                  "& .MuiLinearProgress-bar": {
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                    bgcolor: item.color, // Progress color
                  },
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#333333",
                  paddingLeft: "8px",
                }}
              >
                {(
                  (item.totalSkillAttempts / item.totalQuestionAttempts) *
                  100
                ).toFixed(2)}
                %
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default TestAttempted;
