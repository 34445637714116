import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  TextField,
  Snackbar,
  Alert,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import QuestionPreview from "../../components/libraryPage/QuestionPreview";
import theme from "../../theme/theme";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import CommonModal from "../modal/CommonModal";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMessage } from "../../components/snackbar/snackbar";
import APICall from "../../utils/api";
import DOMPurify from "dompurify";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    background: "#fff",
    padding: "16px",
    border: "1px solid #E8E9EE",
    borderRadius: "12px",
  },
  desktop: {
    display: "flex",
    background: "#fff",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "column", // Default value for base
    gap: "20px", // Default value for base
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      gap: "0",
      alignItems: "center ",
    },
  },
  iconBox: {
    padding: "4px 6px",
    border: "1px solid #D3D9E2",
    borderRadius: 4,
    width: 36,
    height: 32,
  },
  avatarBox: {
    // display: "flex",
    alignItems: "center",
    gap: 6,
  },
  infoBox: {
    alignItems: "center",
    gap: "8px",
  },
  statusBox: {
    background: "#FFF0E7",
    padding: "4px 8px",
    color: "#FF6812",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    borderRadius: "0.25rem",
  },
  subtitle: {
    color: "#8591A2",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
  },
  textField: {
    border: "2px solid #E8E9EE",
    borderRadius: "4px",
  },
  placeholder: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#363E45",
  },
}));

const MyLibraryCard = ({
  isSelected,
  onCountChange,
  question,
  difficultySettings,
  isRecuriter = false,
  fetchLibraryStats,
  fetchQuestions,
  EditQuestion,
  showHideQuestionButtons,
  clientDetails,
  fetchFilterSkills,
}) => {
  const { authUser } = React.useContext(AuthContext);
  const [questionOpen, setQuestionOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [opencloneQuestionModal, setOpencloneQuestionModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedcloneQuestion, setSelectedcloneQuestion] = useState(null);
  const [cloneQuestion, setcloneQuestion] = useState("");
  const validationSchema = yup.object({
    cloneQuestion: yup.string().required("cloneQuestion is required"),
  });
  const classes = useStyles();
  const [openMemberModal, setOpenMemberModal] = useState(false);
  const [openCloneQuestionModal, setOpenCloneQuestionModal] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const message = useMessage();

  let cleanQuestion = DOMPurify.sanitize(
    question?.question || "No statement provided",
    {
      ALLOWED_TAGS: ["p"],
    }
  );

  const MAX_LENGTH = 150;
  if (cleanQuestion.length > MAX_LENGTH) {
    cleanQuestion = cleanQuestion.substring(0, MAX_LENGTH) + "...";
  }
  const calculateHealthScore = () => {
    const reductionPerUse = 0.2;
    const maxHealthScore = question?.healthScore?.healthScore || 100;
    const minHealthScore = 0;
    const normalizedMax = 10;

    const healthScore = Math.max(
      maxHealthScore - question?.noOfTimesUsed * reductionPerUse,
      minHealthScore
    );

    const normalizedScore = (healthScore / maxHealthScore) * normalizedMax;

    const scoreInRange = Math.max(1, normalizedScore);

    return parseFloat(scoreInRange.toFixed(0));
  };

  const displayScore = calculateHealthScore();

  const handleCloneSubmit = async (values) => {
    setConfirmOpen(true);

    const data = {
      createdBy: authUser._id, // question?.createdBy[0]._id,

      questionId: question?._id,
      clonedQuestionTitle: formik.values.cloneQuestion,
    };
    try {
      // const res = await APICall(`/question/${question?._id}`, data, "patch");
      const res = await APICall(`/question/clone`, data, "post");

      message("Question cloned successfully !!", "success");
      setConfirmOpen(false);
      fetchLibraryStats();
      fetchQuestions();
      fetchFilterSkills();
      const getClonedQuestion = await APICall(
        `/question/${res.data._id}`,
        data,
        "get"
      );

      EditQuestion(getClonedQuestion.data);
    } catch (error) {
      message("Error Cloning this question", "error");
      setConfirmOpen(false);
    }
    handleCloseAddOrEdit();
  };

  const handleQuestionOpen = () => {
    setQuestionOpen(true);
  };

  const handleQuestionsClose = () => {
    setQuestionOpen(false);
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleEdit = () => {
    setQuestionOpen(true);
    EditQuestion();
  };

  const handlePingClick = () => {
    handleCloseAddOrEdit();
    setOpenSnackbar(true);
    message("Message sent successfully !!", "success");
  };

  const handleCloseAddOrEdit = () => {
    setIsEditing(false);
    setOpencloneQuestionModal(false);
    setcloneQuestion("");
    setSelectedcloneQuestion(null);
    setcloneQuestion("");
    setOpencloneQuestionModal(false);
    setSelectedcloneQuestion(null);
    formik.resetForm();
    setOpenCloneQuestionModal(false);
    setOpenMemberModal(false);
  };

  const formik = useFormik({
    initialValues: {
      cloneQuestion: "Undefined(Copy)",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleCloneSubmit(values);
    },
  });

  const handleTryQuestionClick = (event) => {
    event.stopPropagation();
    window.open("https://assessment.hire360.ai", "_blank");
  };

  const handleConfirmDelete = async () => {
    try {
      const res = await APICall(
        `/question/${question?._id}`,
        {},

        "delete"
      );
      if (res.status === 204) {
        message("Question deleted successfully!", "success");
        setDeleteConfirmOpen(false);
        // setSelectedQuestions([]);
        fetchLibraryStats();
        fetchQuestions();
        fetchFilterSkills();
      }
    } catch (error) {
      console.error("An error occurred while deleting the question:", error);
      message("Failed to delete question");
    }
  };

  const handleDelete = () => {
    setDeleteConfirmOpen(true);
    handleMenuClose();
  };

  return (
    <>
      <Box
        key={question?._id}
        sx={{
          width: "100%",
          alignItems: "center",
          marginTop: "10px",
          display: "flex",
          cursor: "pointer",
        }}
      >
        <Box
          onClick={handleQuestionOpen}
          sx={{
            cursor: "pointer",
            height: "auto",
            padding: "12px 12px 0px 12px",
            border: "1px solid #d5d6db",
            borderRadius: "4px",
            width: isSelected ? "98%" : "100%",
            "&:hover": {
              borderColor: { md: "#FFD2B8", base: "#FF6812" },
              borderWidth: { md: "2px", base: "1px" },
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                paddingRight: "8px",
                borderRight: "1px solid #E8E9EE",
                width: "fit-content",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: difficultySettings?.bgcolor,
                  alignItems: "center",
                  gap: "4px",
                  padding: "4px 8px",
                  width: "fit-content",
                  borderRadius: "4px",
                }}
              >
                <img src={difficultySettings?.path} alt="difficulty" />
                <Typography
                  sx={{ color: difficultySettings?.color, fontSize: "12px" }}
                >
                  {difficultySettings?.difficulty}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                paddingLeft: "8px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#313131",
                  display: { sm: "flex", base: "none" },
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                {question?.questionTitle}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#313131",
                  display: { base: "flex", sm: "none" },
                }}
              ></Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                {authUser.role === "super admin" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0",
                    }}
                  >
                    {!question?.isHire360Question && (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#FF6812",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          borderRight: "1px solid #E8E9EE",
                          paddingRight: "8px",
                          borderRadius: "4px", // Add border radius
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenMemberModal(!openMemberModal);
                        }}
                      >
                        <Tooltip title="Ping Recruiter" arrow>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <img src="/assets/Vector-5.svg" alt="Publisher" />
                            <Typography variant="body2">
                              {question?.createdBy[0]?.name}
                            </Typography>
                          </Box>
                        </Tooltip>
                      </Typography>
                    )}
                  </Box>
                )}
                <Box
                  sx={{
                    display: { sm: "flex", base: "none" },
                    alignItems: "center",
                    gap: "4px",
                    cursor: "pointer",
                  }}
                  onClick={handleTryQuestionClick}
                >
                  <img src="/assets/Visit.svg" alt="check" />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#71777B",
                    }}
                  >
                    Try Question
                  </Typography>
                </Box>

                {authUser.role === "recruiter" && !showHideQuestionButtons && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      padding: "0",
                      marginLeft: "4px",
                    }}
                    onClick={handleMenuOpen}
                  >
                    <Tooltip title="More" arrow>
                      <Box
                        sx={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover": {
                            backgroundColor: "#E0E0E0",
                          },
                        }}
                      >
                        <img src="/assets/three_dots_icon.svg" alt="Menu" />
                      </Box>
                    </Tooltip>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: { base: "block", sm: "none" },
              marginTop: "8px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#313131",
                fontFamily: "Nunito, sans-serif",
              }}
            >
              {question?.questionTitle}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: "8px" }}>
            {" "}
            <div
              style={{
                color: "#7C7C7C",
                fontSize: "14px",
                fontWeight: "400",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              dangerouslySetInnerHTML={{ __html: cleanQuestion }}
            />
          </Box>
          <Box
            sx={{
              marginTop: "8px",
              borderTop: "1px solid #E8E9EE",
              display: { md: "flex", sm: "block" },
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  paddingRight: "8px",
                  display: "flex",
                  alignItems: "center",
                  height: "24px",
                  gap: "8px",
                  borderRight: "1px solid #E8E9EE",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "0",
                  }}
                >
                  <img src="/assets/health.svg" alt="health" />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#535A5F",
                    }}
                  >
                    Health : {`${displayScore}/10`}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  padding: "0 8px",
                  display: "flex",
                  alignItems: "center",
                  height: "24px",
                  gap: "8px",
                  borderRight: "1px solid #E8E9EE",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "0",
                  }}
                >
                  <img src="/assets/remark.svg" alt="remark" />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#71777B",
                    }}
                  >
                    Score : {question?.score}
                  </Typography>
                </Box>
              </Box>
              {isRecuriter && (
                <Box
                  sx={{
                    padding: "0 8px",
                    display: "flex",
                    alignItems: "center",
                    height: "24px",
                    gap: "8px",
                    borderRight: "1px solid #E8E9EE",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      padding: "0",
                    }}
                  >
                    <img src="/assets/stackSmall.svg" alt="stacksmall" />
                    {/* repalce data from reponse accordingly  */}
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#71777B",
                        maxWidth: "155px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      React , MongoDb, NodeJs, AngularJs
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  paddingLeft: { base: "0", md: "8px" },
                  display: "flex",
                  alignItems: "center",
                  height: "24px",
                  gap: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "0",
                  }}
                >
                  <Tooltip title="Skills" placement="bottom-start" arrow>
                    <img src="/assets/topic.svg" alt="topic" />
                  </Tooltip>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#71777B",
                    }}
                  >
                    {question?.skills?.length > 4 ? (
                      <>
                        {question?.skills?.slice(0, 4).map((skill, index) => (
                          <span key={index}>
                            {skill.name}
                            {index < 3 ? ", " : ""}{" "}
                          </span>
                        ))}
                        <Tooltip
                          title={question?.skills
                            .slice(4)
                            .map((skill) => skill.name)
                            .join(", ")}
                        >
                          <span> + {question?.skills?.length - 4} More</span>
                        </Tooltip>
                      </>
                    ) : (
                      question?.skills?.map((skill, index) => (
                        <span key={index}>
                          {skill.name}
                          {index < question?.skills?.length - 1 ? ", " : ""}
                        </span>
                      ))
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: { base: "8px", md: "0" },
                width: { base: "100%", md: "fit-content" },
                borderTop: { base: "1px solid #E8E9EE", md: "none" },
              }}
            >
              <Box
                sx={{
                  paddingRight: "8px",
                  display: "flex",
                  alignItems: "center",
                  height: { base: "auto", md: "40px" },
                  gap: "8px",
                  borderRight: "1px solid #E8E9EE",
                  height: "24px",
                  marginTop: "7px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#D3D9E2",
                    padding: "4px 8px",
                    borderRadius: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "rgba(56, 68, 85, 1)",
                    }}
                  >
                    {question?.isHire360Question
                      ? "Hire360 Library"
                      : authUser?.role === "super admin" && clientDetails
                      ? `${clientDetails.companyName} Library`
                      : "My Library"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingLeft: "8px",
                  display: "flex",
                  alignItems: { base: "end", md: "center" },
                  height: "40px",
                  gap: "8px",
                }}
              >
                <Box sx={{ padding: { base: "4px 0", md: "0" } }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#71777B",
                    }}
                  >
                    {question?.type}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: { base: "flex", sm: "none" },
              alignItems: "center",
              gap: "8px",
              justifyContent: "center",
              marginTop: "8px",
              paddingTop: "8px",
              borderTop: "1px solid #E8E9EE",
            }}
          >
            <img src="/assets/Visit.svg" alt="check" />
            <Typography
              onClick={handleTryQuestionClick}
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#71777B",
                cursor: "pointer",
              }}
            >
              Try Question
            </Typography>
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            EditQuestion(question);
            handleMenuClose();
          }}
        >
          <img
            src="/assets/pencil_icon_3.svg"
            style={{ marginRight: "11px" }}
            alt="Edit"
          />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpencloneQuestionModal(true);
            // Open clone modal
            formik.setValues({
              cloneQuestion: `${question?.questionTitle} (Copy)`,
            });
            handleMenuClose();
          }}
        >
          <img
            src="/assets/clone.svg"
            style={{ marginRight: "8px" }}
            alt="Clone"
          />
          Clone
        </MenuItem>

        {question?.isHire360Question === false && (
          <MenuItem
            onClick={() => {
              handleDelete();
              handleMenuClose();
            }}
          >
            <img
              src="/assets/delete_icon_2 copy.svg"
              style={{ marginRight: "8px" }}
              alt="Delete"
            />
            Delete
          </MenuItem>
        )}
      </Menu>

      <CommonModal
        icon={"/assets/clone.svg"}
        handleClose={() => setOpencloneQuestionModal(false)} // Close clone modal
        open={opencloneQuestionModal}
        title={`Clone Question`}
        sendButtonName={"Clone"}
        onSubmit={formik.handleSubmit}
      >
        <TextField
          sx={{ marginBottom: "16px" }}
          fullWidth
          size="small"
          id="cloneQuestion"
          label="Clone Question Name"
          name="cloneQuestion"
          value={formik.values.cloneQuestion}
          onChange={formik.handleChange}
          error={
            formik.touched.cloneQuestion && Boolean(formik.errors.cloneQuestion)
          }
          helperText={
            formik.touched.cloneQuestion && formik.errors.cloneQuestion
          }
          required
        />
      </CommonModal>

      <CommonModal
        icon={"/assets/clone.svg"}
        handleClose={() => setConfirmOpen(false)} // Close clone confirmation modal
        open={confirmOpen}
        title={"Clone Question"}
        showFooter={false}
      >
        <Typography
          style={{
            backgroundColor: "#edf7ed",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <img
            src="/assets/success.svg"
            alt="Alert"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          />
          <strong>Cloning Your Question</strong>
          <br />
          <div style={{ verticalAlign: "middle" }}>
            As we proceed with the cloning process, <br /> you will see Cloned
            Question in your library after successfully cloned.
          </div>
        </Typography>
      </CommonModal>

      <CommonModal
        icon={"/assets/delete_icon.svg"}
        handleClose={() => setDeleteConfirmOpen(false)}
        open={deleteConfirmOpen}
        title={"Confirm Delete"}
        onSubmit={handleConfirmDelete}
        sendButtonName={"Yes"}
        cancelButtonName={"No"}
        onCancel={() => setDeleteConfirmOpen(false)}
        isDeleting={true}
      >
        <Typography
          style={{
            backgroundColor: "#fff0e7",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <img
            src="/assets/alert-1.svg"
            alt="Alert"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          />
          <strong>Are you certain you wish to delete this question?</strong>
          <br />
          <div style={{ verticalAlign: "middle" }}>
            This action will permanently delete the question and all related
            data.
          </div>
        </Typography>
      </CommonModal>

      <CommonModal
        icon={"/assets/Vector-5.svg"}
        handleClose={handleCloseAddOrEdit}
        open={openMemberModal}
        title={"Ping Recruiter"}
        sendButtonName={"Ping"}
        onSubmit={handlePingClick}
      >
        <Stack gap={"4px"}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "14px",
              lineheight: "20px",
              color: "#71777B",
            }}
          >
            Enter Message
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={6}
            placeholder="Enter your message"
            className={classes.textField}
            InputProps={{
              className: classes.placeholder, // Apply placeholder styles
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "8px 12px 8px 12px",
                "& fieldset": {
                  borderColor: "#E8E9EE",
                  borderWidth: "2px",
                  borderRadius: "4px",
                },
              },
              "& .MuiInputBase-input": {
                "&::placeholder": {
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "#363E45",
                  opacity: 1,
                },
              },
            }}
          />
        </Stack>
      </CommonModal>

      {questionOpen && (
        <QuestionPreview
          open={questionOpen}
          onClose={handleQuestionsClose}
          question={question}
          difficultySettings={difficultySettings}
        />
      )}
    </>
  );
};

export default MyLibraryCard;
